import React, { useEffect, useState } from "react";
import { Button, Table, Form, Row, Col } from "react-bootstrap";
import "./AdminPage.scss";
import NewProduct from "../../components/AdminComponents/NewProductForm";
import AllProducts from "../../components/AdminComponents/AllProducts";
import NewValueAndId from "../../components/AdminComponents/NewValueAndId";
import NewSlide from "../../components/AdminComponents/NewSlide";
import NewImage from "../../components/AdminComponents/NewImage";
import NewUser from "../../components/AdminComponents/NewUser";
import NewSeller from "../../components/AdminComponents/NewSeller";
import AllInCommon from "../../components/AdminComponents/AllInCommon";
import AllSellers from "../../components/AdminComponents/AllSellers";
import AllSlides from "../../components/AdminComponents/AllSlides";
import AllUsers from "../../components/AdminComponents/AllUsers";
import AllAdmins from "../../components/AdminComponents/AllAdmins";
import AllSubcategories from "../../components/AdminComponents/AllSubcategories";
import AllCharacteristic from "../../components/AdminComponents/AllCharacteristic";
import NewCharacteristic from "../../components/AdminComponents/NewCharacteristic";
import NewSubcategory from "../../components/AdminComponents/NewSubcategory";
import NewSize from "../../components/AdminComponents/NewSize";
import AllColors from "../../components/AdminComponents/AllColors";
import NewColor from "../../components/AdminComponents/NewColor";
import NewAdmin from "../../components/AdminComponents/NewAdmin";

const AdminPage = () => {
  const [add, setAdd] = useState("view");
  const [showModal, setShowModal] = useState("all-products");

  const modalSet = (e) => {
    e.preventDefault();
    setShowModal(`${e.target.value}`);
  };

  const showSet = (e) => {
    e.preventDefault();
    setAdd(`${e.target.value}`);
    const view = document.querySelector("#view-btn");
    const add = document.querySelector("#add-btn");

    if (e.target.value === "view") {
      view.classList.add("active-admin-btn");
      add.classList.remove("active-admin-btn");
    } else {
      add.classList.add("active-admin-btn");
      view.classList.remove("active-admin-btn");
    }
  };

  useEffect(() => {
    const target = document.querySelector(".wrapper-admin-table");
    if (target !== null) target.scrollIntoView({ behavior: "smooth" });
    const targetAdd = document.querySelector(".add-new");
    if (targetAdd !== null) targetAdd.scrollIntoView({ behavior: "smooth" });
  }, [showModal]);

  return (
    <div className="admin-page-container container-padding">
      <h2>Admin page</h2>
      <div className="wrap-add-new">
        <div className="side-bar">
          <button
            onClick={showSet}
            value="view"
            id="view-btn"
            className="admin-big-button active-admin-btn"
          >
            View
          </button>

          <button
            onClick={showSet}
            value="add"
            id="add-btn"
            className="admin-big-button"
          >
            Add
          </button>
          {add === "view" && (
            <>
              <button
                onClick={modalSet}
                value="all-products"
                className="admin-all-btn"
              >
                View products
              </button>
              <button
                onClick={modalSet}
                value="all-brands"
                className="admin-all-btn"
              >
                View brands
              </button>
              <button
                onClick={modalSet}
                value="all-categories"
                className="admin-all-btn"
              >
                View categories
              </button>
              <button
                onClick={modalSet}
                value="all-subcategories"
                className="admin-all-btn"
              >
                View subcategories
              </button>
              <button
                onClick={modalSet}
                value="all-characteristics"
                className="admin-all-btn"
              >
                View characteristics
              </button>
              <button
                onClick={modalSet}
                value="all-colors"
                className="admin-all-btn"
              >
                View colors
              </button>
              <button
                onClick={modalSet}
                value="all-sellers"
                className="admin-all-btn"
              >
                View sellers
              </button>
              <button
                onClick={modalSet}
                value="all-sizes"
                className="admin-all-btn"
              >
                View sizes
              </button>
              <button
                onClick={modalSet}
                value="all-slides"
                className="admin-all-btn"
              >
                View slides
              </button>
              <button
                onClick={modalSet}
                value="all-users"
                className="admin-all-btn"
              >
                View users
              </button>
              <button
                onClick={modalSet}
                value="all-admins"
                className="admin-all-btn"
              >
                View admins
              </button>
            </>
          )}
          {add === "add" && (
            <>
              <button
                onClick={modalSet}
                className="admin-all-btn"
                value="product"
              >
                Add product
              </button>
              <button
                onClick={modalSet}
                className="admin-all-btn"
                value="brand"
              >
                Add brand
              </button>
              <button
                onClick={modalSet}
                className="admin-all-btn"
                value="category"
              >
                Add category
              </button>
              <button
                onClick={modalSet}
                className="admin-all-btn"
                value="subcategory"
              >
                Add subcategory
              </button>
              <button
                onClick={modalSet}
                className="admin-all-btn"
                value="color"
              >
                Add color
              </button>
              <button
                onClick={modalSet}
                className="admin-all-btn"
                value="characteristic"
              >
                Add characteristic
              </button>
              <button
                onClick={modalSet}
                className="admin-all-btn"
                value="image"
              >
                Add image
              </button>
              <button
                onClick={modalSet}
                className="admin-all-btn"
                value="seller"
              >
                Add seller
              </button>
              <button onClick={modalSet} className="admin-all-btn" value="size">
                Add size
              </button>
              <button
                onClick={modalSet}
                className="admin-all-btn"
                value="slide"
              >
                Add slide
              </button>
              <button onClick={modalSet} className="admin-all-btn" value="user">
                Add user
              </button>
              <button
                onClick={modalSet}
                className="admin-all-btn"
                value="admin"
              >
                Add admin
              </button>
            </>
          )}
        </div>
        <div className="main-content">
          {showModal === "all-products" && (
            <>
              <AllProducts itemsPerPage={8} />
            </>
          )}
          {showModal === "product" && <NewProduct title="Add new" />}
          {showModal === "brand" && <NewValueAndId title="brand" />}
          {showModal === "category" && <NewValueAndId title="category" />}
          {showModal === "subcategory" && (
            <NewSubcategory title="subcategory" />
          )}
          {showModal === "characteristic" && (
            <NewCharacteristic title="characteristic" />
          )}
          {showModal === "color" && <NewColor title="color" />}

          {showModal === "size" && <NewSize title="size" />}
          {showModal === "slide" && <NewSlide />}
          {showModal === "image" && <NewImage />}
          {showModal === "user" && <NewUser role="user" />}
          {showModal === "seller" && <NewSeller />}
          {showModal === "admin" && <NewAdmin role="admin" />}
          {showModal === "all-brands" && (
            <>
              <AllInCommon
                type="brands"
                itemsPerPage={8}
                link="http://127.0.0.1:80/shop/controller/brand?brandid="
              />
            </>
          )}
          {showModal === "all-categories" && (
            <>
              <AllInCommon
                itemsPerPage={8}
                link="http://127.0.0.1:80/shop/controller/category?categoryid="
                type="category"
              />
            </>
          )}
          {showModal === "all-subcategories" && (
            <>
              <AllSubcategories itemsPerPage={8} />
            </>
          )}
          {showModal === "all-colors" && (
            <>
              <AllColors itemsPerPage={8} />
            </>
          )}
          {showModal === "all-sizes" && (
            <>
              <AllInCommon
                itemsPerPage={8}
                link="http://127.0.0.1:80/shop/controller/size?sizeid="
                type="size"
              />
            </>
          )}
          {showModal === "all-characteristics" && (
            <>
              <AllCharacteristic itemsPerPage={8} />
            </>
          )}
          {showModal === "all-sellers" && (
            <>
              <AllSellers itemsPerPage={8} />
            </>
          )}
          {showModal === "all-slides" && (
            <>
              <AllSlides itemsPerPage={8} />
            </>
          )}
          {showModal === "all-users" && (
            <>
              <AllUsers itemsPerPage={8} />
            </>
          )}
          {showModal === "all-admins" && (
            <>
              <AllAdmins itemsPerPage={8} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
