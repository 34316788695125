import React from "react";
/**body{sellerName:VALUE,sellerDescription:VALUE,country:VALUE, city:VALUE,street:VALUE,streetNumber:VALUE,postal: VALUE,password:VALUE,email:VALUE,phone:VALUE} */

import { Form, Button } from "react-bootstrap";
import useValidate from "../../hooks/useValidate";
import { createNewSeller } from "../../services/seller-service";
const NewSeller = () => {
  const isNotEmpty = (value) => value.trim().length > 0;

  const {
    value: sellerName,
    isValid: sellerNameValid,
    hasError: sellerNameError,
    valueBlurHandler: sellerNameBlurHandler,
    valueChangedHandler: sellerNameChangeHandler,
    reset: sellerNameReset,
  } = useValidate(isNotEmpty);
  const {
    value: sellerDescription,
    isValid: sellerDescriptionValid,
    hasError: sellerDescriptionError,
    valueBlurHandler: sellerDescriptionBlurHandler,
    valueChangedHandler: sellerDescriptionChangeHandler,
    reset: sellerDescriptionReset,
  } = useValidate(isNotEmpty);
  const {
    value: country,
    isValid: countryValid,
    hasError: countryError,
    valueBlurHandler: countryBlurHandler,
    valueChangedHandler: countryChangeHandler,
    reset: countryReset,
  } = useValidate(isNotEmpty);
  const {
    value: city,
    isValid: cityValid,
    hasError: cityError,
    valueBlurHandler: cityBlurHandler,
    valueChangedHandler: cityChangeHandler,
    reset: cityReset,
  } = useValidate(isNotEmpty);
  const {
    value: street,
    isValid: streetValid,
    hasError: streetError,
    valueBlurHandler: streetBlurHandler,
    valueChangedHandler: streetChangeHandler,
    reset: streetReset,
  } = useValidate(isNotEmpty);
  const {
    value: streetNumber,
    isValid: streetNumberValid,
    hasError: streetNumberError,
    valueBlurHandler: streetNumberBlurHandler,
    valueChangedHandler: streetNumberChangeHandler,
    reset: streetNumberReset,
  } = useValidate(isNotEmpty);
  const {
    value: postal,
    isValid: postalValid,
    hasError: postalError,
    valueBlurHandler: postalBlurHandler,
    valueChangedHandler: postalChangeHandler,
    reset: postalReset,
  } = useValidate(isNotEmpty);
  const {
    value: password,
    isValid: passwordValid,
    hasError: passwordError,
    valueBlurHandler: passwordBlurHandler,
    valueChangedHandler: passwordChangeHandler,
    reset: passwordReset,
  } = useValidate(isNotEmpty);
  const {
    value: email,
    isValid: emailValid,
    hasError: emailError,
    valueBlurHandler: emailBlurHandler,
    valueChangedHandler: emailChangeHandler,
    reset: emailReset,
  } = useValidate(isNotEmpty);
  const {
    value: phone,
    isValid: phoneValid,
    hasError: phoneError,
    valueBlurHandler: phoneBlurHandler,
    valueChangedHandler: phoneChangeHandler,
    reset: phoneReset,
  } = useValidate(isNotEmpty);

  let formValid = false;

  if (
    sellerNameValid &&
    sellerDescriptionValid &&
    countryValid &&
    cityValid &&
    streetValid &&
    streetNumberValid &&
    postalValid &&
    passwordValid &&
    emailValid &&
    phoneValid
  ) {
    formValid = true;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formValid) {
      alert("Please provide correct information");
      return;
    }

    createNewSeller({
      sellerName: sellerName,
      sellerDescription: sellerDescription,
      country: country,
      city: city,
      street: street,
      streetNumber: streetNumber,
      postal: postal,
      password: password,
      email: email,
      phone: phone,
    }).then((data) => {
      console.log(data);
    });

    sellerNameReset();
    sellerDescriptionReset();
    countryReset();
    cityReset();
    streetReset();
    streetNumberReset();
    postalReset();
    passwordReset();
    emailReset();
    phoneReset();
  };
  return (
    <div className="add-new">
      <h5>Add new Seller</h5>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-seller-label-name">Seller Name</Form.Label>
          <Form.Control
            id="add-seller-label-name"
            type="text"
            placeholder="Seller Name"
            value={sellerName}
            onChange={sellerNameChangeHandler}
            onBlur={sellerNameBlurHandler}
          />
          {sellerNameError && (
            <p className="red-text">Seller Name can't be empty</p>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-seller-label-description">
            Seller Description
          </Form.Label>
          <Form.Control
            id="add-seller-label-description"
            type="text"
            placeholder="Seller Description"
            value={sellerDescription}
            onChange={sellerDescriptionChangeHandler}
            onBlur={sellerDescriptionBlurHandler}
          />
          {sellerDescriptionError && (
            <p className="red-text">Seller Description can't be empty</p>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-seller-label-country">Country</Form.Label>
          <Form.Control
            id="add-seller-label-country"
            type="text"
            placeholder="Country"
            value={country}
            onChange={countryChangeHandler}
            onBlur={countryBlurHandler}
          />
          {countryError && <p className="red-text">Country can't be empty</p>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-seller-label-city">City</Form.Label>
          <Form.Control
            id="add-seller-label-city"
            type="text"
            placeholder="City"
            value={city}
            onChange={cityChangeHandler}
            onBlur={cityBlurHandler}
          />
          {cityError && <p className="red-text">City can't be empty</p>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-seller-label-street">Street</Form.Label>
          <Form.Control
            id="add-seller-label-street"
            type="text"
            placeholder="Street"
            value={street}
            onChange={streetChangeHandler}
            onBlur={streetBlurHandler}
          />
          {streetError && <p className="red-text">Street can't be empty</p>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-seller-label-streetNum">
            Street Number
          </Form.Label>
          <Form.Control
            id="add-seller-label-streetNum"
            type="text"
            placeholder="Street Number"
            value={streetNumber}
            onChange={streetNumberChangeHandler}
            onBlur={streetNumberBlurHandler}
          />
          {streetNumberError && (
            <p className="red-text">Street Number can't be empty</p>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-seller-label-postal">Postal</Form.Label>
          <Form.Control
            id="add-seller-label-postal"
            type="text"
            placeholder="Postal"
            value={postal}
            onChange={postalChangeHandler}
            onBlur={postalBlurHandler}
          />
          {postalError && <p className="red-text">Postal can't be empty</p>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-seller-label-password">Password</Form.Label>
          <Form.Control
            id="add-seller-label-password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={passwordChangeHandler}
            onBlur={passwordBlurHandler}
          />
          {passwordError && <p className="red-text">Password can't be empty</p>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-seller-label-email">Email</Form.Label>
          <Form.Control
            id="add-seller-label-email"
            type="text"
            placeholder="Email"
            value={email}
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
          />
          {emailError && <p className="red-text">Email can't be empty</p>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-seller-label-phone">Phone</Form.Label>
          <Form.Control
            id="add-seller-label-phone"
            type="text"
            placeholder="Phone"
            value={phone}
            onChange={phoneChangeHandler}
            onBlur={phoneBlurHandler}
          />
          {phoneError && <p className="red-text">Phone can't be empty</p>}
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default NewSeller;
