import React, { useState, useEffect } from "react";
import "./AdminPage.scss";
import NewProduct from "../../components/AdminComponents/NewProductForm";
import AllProducts from "../../components/AdminComponents/AllProducts";
import NewValueAndId from "../../components/AdminComponents/NewValueAndId";
import AllInCommon from "../../components/AdminComponents/AllInCommon";
import AllOrders from "../../components/AdminComponents/AllOrders";
import SellerDetails from "../../components/AdminComponents/SellerDetails";

const SellerPage = () => {
  const [add, setAdd] = useState("view");
  const [showModal, setShowModal] = useState("all-products");
  const modalSet = (e) => {
    e.preventDefault();
    setShowModal(`${e.target.value}`);
  };

  const showSet = (e) => {
    e.preventDefault();
    setAdd(`${e.target.value}`);
    const view = document.querySelector("#view-btn");
    const add = document.querySelector("#add-btn");
    if (e.target.value === "view") {
      view.classList.add("active-admin-btn");
      add.classList.remove("active-admin-btn");
    } else {
      add.classList.add("active-admin-btn");
      view.classList.remove("active-admin-btn");
    }
  };
  useEffect(() => {
    const target = document.querySelector(".wrapper-admin-table");
    if (target !== null) target.scrollIntoView({ behavior: "smooth" });
    const targetAdd = document.querySelector(".add-new");
    if (targetAdd !== null) targetAdd.scrollIntoView({ behavior: "smooth" });
  }, [showModal]);

  return (
    <div className="admin-page-container container-padding">
      <h2>Seller page</h2>
      <div className="wrap-add-new">
        <div className="side-bar">
          <button
            onClick={showSet}
            value="view"
            id="view-btn"
            className="admin-big-button active-admin-btn"
          >
            View ...
          </button>

          <button
            onClick={showSet}
            value="add"
            id="add-btn"
            className="admin-big-button"
          >
            Add ...
          </button>

          {add === "view" && (
            <section className="admin-links">
              <button
                onClick={modalSet}
                value="all-products"
                className="admin-all-btn"
              >
                View my products
              </button>
              <button
                onClick={modalSet}
                value="all-brands"
                className="admin-all-btn"
              >
                View my brands
              </button>
              <button
                onClick={modalSet}
                value="all-orders"
                className="admin-all-btn"
              >
                View my orders
              </button>
              <button
                onClick={modalSet}
                value="my-details"
                className="admin-all-btn"
              >
                View my details
              </button>
            </section>
          )}
          {add === "add" && (
            <section className="admin-links">
              <button
                onClick={modalSet}
                className="admin-all-btn"
                value="product"
              >
                Add new product
              </button>
              <button
                onClick={modalSet}
                className="admin-all-btn"
                value="brand"
              >
                Add new brand
              </button>
            </section>
          )}
        </div>
        <div className="main-content">
          {showModal === "all-products" && (
            <>
              <AllProducts itemsPerPage={8} />
            </>
          )}
          {showModal === "all-brands" && (
            <>
              <AllInCommon type="brands" itemsPerPage={8} />
            </>
          )}
          {showModal === "all-orders" && (
            <>
              <AllOrders itemsPerPage={8} />
            </>
          )}
          {showModal === "my-details" && <SellerDetails />}
          {showModal === "product" && <NewProduct title="Add" />}
          {showModal === "brand" && <NewValueAndId title="brand" />}
        </div>
      </div>
    </div>
  );
};

export default SellerPage;
