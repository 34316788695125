import React, { useState } from "react";
import "./LoginForm.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { loginUser } from "../../services/user-service";
import { authActions } from "../../slices/auth";
import { userActions } from "../../slices/user";
import { useEffect } from "react";
import jwtDecode from "jwt-decode";
import { getUserById } from "../../services/user-service";
import { loginSeller, getSellerById } from "../../services/seller-service";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailSeller, setEmailSeller] = useState("");
  const [passwordSeller, setPasswordSeller] = useState("");
  const [valid, setValid] = useState(true);
  const [validSeller, setValidSeller] = useState(true);

  const handleSubmit = (e) => {
    var form = new FormData(document.getElementById("login-form"));
    var emailValue = form.get("login-form-email");
    var passwordValue = form.get("login-form-password");
    e.preventDefault();

    loginUser({ email: emailValue, password: passwordValue }).then((data) => {
      const tokens = {
        accessToken: data.data.data.accessToken,
        refreshToken: data.data.data.refreshToken,
      };
      dispatch(authActions.setToken(tokens));
      localStorage.setItem("accessToken", tokens.accessToken);
      localStorage.setItem("refreshToken", tokens.refreshToken);
      const decoded = jwtDecode(tokens.accessToken);
      dispatch(
        authActions.setRole({ role: decoded.role, roleId: decoded.roleId })
      );
      dispatch(authActions.setId(decoded.sub));
      //get user by id then setuserdata
      getUserById(decoded.sub).then((data) => {
        dispatch(userActions.setUserData(data.data.data.users[0]));
      });
      navigate("/");
    });
  };

  const handleSubmitSeller = (e) => {
    var form = new FormData(document.getElementById("login-seller"));
    var emailValue = form.get("login-seller-email");
    var passwordValue = form.get("login-seller-password");
    e.preventDefault();

    loginSeller({ email: emailValue, password: passwordValue }).then((data) => {
      const tokens = {
        accessToken: data.data.data.accessToken,
        refreshToken: data.data.data.refreshToken,
      };
      dispatch(authActions.setToken(tokens));
      localStorage.setItem("accessToken", tokens.accessToken);
      localStorage.setItem("refreshToken", tokens.refreshToken);
      const decoded = jwtDecode(tokens.accessToken);

      dispatch(
        authActions.setRole({ role: decoded.role, roleId: decoded.roleId })
      );

      dispatch(authActions.setId(decoded.sub));

      // //get user by id then setuserdata
      // getSellerById(decoded.sub).then((data) => {
      //   console.log(data);
      //   // dispatch(userActions.setUserData(data.data.data.users[0]));
      // });
      navigate("/");
    });
  };

  useEffect(() => {}, [auth]);

  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value);
  };
  const sellerEmailHandler = (e) => {
    setEmailSeller(e.target.value);
  };

  const sellerPasswordHandler = (e) => {
    setPasswordSeller(e.target.value);
  };

  return (
    <>
      <div className="login-form-container container-padding">
        <form className="login-form" id="login-form" onSubmit={handleSubmit}>
          <h3 className="form-title">Sign in</h3>
          <input
            type="text"
            name="login-form-email"
            className="form-input"
            placeholder="E-mail"
            onChange={emailHandler}
            value={email}
          />

          <input
            type="password"
            name="login-form-password"
            className="form-input"
            placeholder="Password"
            onChange={passwordHandler}
            value={password}
          />
          {!valid && (
            <span className="error-txt">
              Please provide correct email and password
            </span>
          )}
          <button className="shop-btn" onClick={handleSubmit}>
            Sign in
          </button>
        </form>
      </div>
      <div className="login-form-container container-padding">
        <form className="login-form" id="login-seller" onSubmit={handleSubmit}>
          <h3 className="form-title">Seller sign in</h3>
          <input
            type="text"
            name="login-seller-email"
            className="form-input"
            placeholder="E-mail"
            onChange={sellerEmailHandler}
            value={emailSeller}
          />

          <input
            type="password"
            name="login-seller-password"
            className="form-input"
            placeholder="Password"
            onChange={sellerPasswordHandler}
            value={passwordSeller}
          />
          {!valid && (
            <span className="error-txt">
              Please provide correct email and password
            </span>
          )}
          <button className="shop-btn" onClick={handleSubmitSeller}>
            Sign in
          </button>
        </form>
      </div>
    </>
  );
};

export default LoginForm;
