import * as api from "../api/user";
import { userActions } from "../slices/user";

export const getAllUsers = async () => {
  try {
    const data = await api.getAllUsers();
    return data;
  } catch (error) {
    console.log(error, "user-service getall error");
    return error;
  }
};

export const getByRoleId = async (roleId) => {
  try {
    const data = await api.getByRoleId(roleId);
    return data;
  } catch (error) {
    console.log(error, "user-service role id error");
    return error;
  }
};

export const getUserById = async (userId) => {
  try {
    const data = await api.getUserById(userId);
    return data;
  } catch (error) {
    console.log(error, "user-service getbyid error");
    return error;
  }
};

export const registerUser = async (newUser) => {
  try {
    const data = await api.registerUser(newUser);
    return data;
  } catch (error) {
    console.log(error, "user-service register error");
    return error;
  }
};

export const registerWithRole = async (newUser) => {
  try {
    const data = await api.registerWithRole(newUser);
    return data;
  } catch (error) {
    console.log(error, "user-service register role error");
    return error;
  }
};

export const updateUser = async (userId, updatedUser) => {
  try {
    const data = await api.updateUser(userId, updatedUser);
    return data;
  } catch (error) {
    console.log(error, "user-service update error");
    return error;
  }
};

export const deleteUser = async (userId, deletedUser) => {
  try {
    const data = await api.deleteUser(userId, deletedUser);
    return data;
  } catch (error) {
    console.log(error, "user-service delete error");
    return error;
  }
};

//THESE FUNCTIONS ARE TRANsFERED TO AUTH-SERVICE
// export const registerUser = (newUser) => async (dispatch) => {
//   try {
//     const data = await api.registerUser(newUser);
//     dispatch(userActions.setUserData(newUser));
//     return data;
//   } catch (error) {
//     console.log(error, "user-service register error");
//     return error;
//   }
// };

export const loginUser = async (user) => {
  try {
    const data = await api.loginUser(user);
    return data;
  } catch (error) {
    console.log(error, "user-service login error");
    return error;
  }
};

export const logoutUser = async (token) => {
  try {
    const data = await api.logoutUser(token);
    return data;
  } catch (error) {
    console.log(error, "user-service logut error");
    return error;
  }
};
