import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./ShopDetailsPreview.scss";
import { Link } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCcVisa,
  faCcMastercard,
  faCcPaypal,
  faCcAmex,
  faBtc,
} from "@fortawesome/free-brands-svg-icons";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { MdCompareArrows } from "react-icons/md";
import { getSingleProduct } from "../../services/product-service";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { cartActions } from "../../slices/cart";
import Loader from "../Loader/Loader";

import thumb1 from "../../../assets/shop-details/thumb-1.png";
import thumb2 from "../../../assets/shop-details/thumb-2.png";
import thumb3 from "../../../assets/shop-details/thumb-3.png";
import thumb4 from "../../../assets/shop-details/thumb-4.png";
import image1 from "../../../assets/shop-details/product-big.png";
import image2 from "../../../assets/shop-details/product-big-2.png";
import image3 from "../../../assets/shop-details/product-big-3.png";
import image4 from "../../../assets/shop-details/product-big-4.png";

import product1 from "../../../assets/product-1.jpg";
import product2 from "../../../assets/product-2.jpg";
import product3 from "../../../assets/product-3.jpg";
import product4 from "../../../assets/product-4.jpg";
import CartAddedMessage from "../CartModalContent/CartAddedMessage";
import CartQuantity from "../CartModalContent/CartQuantity";

const dummyData = {
  thumbnails: [
    { src: `${thumb1}` },
    { src: `${thumb2}` },
    { src: `${thumb3}` },
    { src: `${thumb4}` },
    { src: `${thumb3}` },
    { src: `${thumb4}` },
  ],
  images: [
    { src: `${image1}` },
    { src: `${image2}` },
    { src: `${image3}` },
    { src: `${image4}` },
    { src: `${image3}` },
    { src: `${image4}` },
  ],
  colors: ["red", "blue", "black", "green"],
  sizes: ["s", "m", "xl"],
  price: "$270.00",
  name: "Hooded thermal anorak",
  rate: 4,
  description:
    "Coat with quilted lining and an adjustable hood. Featuring long sleeves with adjustable cuff tabs, adjustable asymmetric hem with elastic side tabs and a front zip fastening with placket.",
  sale: "$70.00",
  materials:
    "We work with monitoring programmes to ensure compliance with safety, health and quality standards for our products.The Green to Wear 2.0 standard aims to minimise the environmental impact of textile manufacturing. To that end, we have developed Inditex’s The List programme, which helps guarantee both that production processes are clean and that our garments are safe and healthy.OUTER SHELL 100% cotton",
  materialUsed:
    "Polyester is deemed lower quality due to its none natural quality’s. Made from synthetic materials, not natural like wool. Polyester suits become creased easily and are known for not being breathable. Polyester suits tend to have a shine to them compared to wool and cotton suits, this can make the suit look cheap. The texture of velvet is luxurious and breathable. Velvet is a great choice for dinner party jacket and can be worn all year round.",
  care: "A Pocket PC is a handheld computer, which features many of the same capabilities as a modern PC. These handy little devices allow individuals to retrieve and store e-mail messages, create a contact file, coordinate appointments, surf the internet, exchange text messages and more. Every product that is labeled as a Pocket PC must be accompanied with specific software to operate the unit and must feature a touchscreen and touchpad.As is the case with any new technology product, the cost of a Pocket PC was substantial during it’s early release. For approximately $700.00, consumers could purchase one of top-of-the-line Pocket PCs in 2003. These days, customers are finding that prices have become much more reasonable now that the newness is wearing off. For approximately $350.00, a new Pocket PC can now be purchased.",
  origin:
    "A Pocket PC is a handheld computer, which features many of the same capabilities as a modern PC. These handy little devices allow individuals to retrieve and store e-mail messages, create a contact file, coordinate appointments, surf the internet, exchange text messages and more. Every product that is labeled as a Pocket PC must be accompanied with specific software to operate the unit and must feature a touchscreen and touchpad.As is the case with any new technology product, the cost of a Pocket PC was substantial during it’s early release. For approximately $700.00, consumers could purchase one of top-of-the-line Pocket PCs in 2003. These days, customers are finding that prices have become much more reasonable now that the newness is wearing off. For approximately $350.00, a new Pocket PC can now be purchased.",
  code: "3812912",
  categories: "clothes",
  tag: ["clothes", "skin", "body"],
  reviews: 5,
};

const dummyData2 = {
  items: [
    {
      id: 1,
      name: "PIQUÉ BIKER JACKET",
      price: "$67.24",
      tag: "NEW",
      rate: 4,
      img: `${product1}`,
    },
    {
      id: 2,
      name: "MULTI-POCKET CHEST BAG",
      price: "$67.24",
      tag: "",
      rate: 3,
      img: `${product2}`,
    },
    {
      id: 3,
      name: "DIAGONAL TEXTURED CAP",
      price: "$67.24",
      tag: "",
      rate: 4,
      img: `${product3}`,
    },
    {
      id: 4,
      name: "LETHER BACKPACK",
      price: "$67.24",
      tag: "NEW",
      rate: 5,
      img: `${product4}`,
    },
  ],
};
const ShopDetailsPreview = () => {
  const dispatch = useDispatch();
  const search = useSelector((state) => state.search);
  const [imageSrc, setImageSrc] = useState(image1);
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);
  const [quantity, setQuantity] = useState(1);

  const productId = useParams();
  const redirectDetails = (id) => {
    navigate(`/shop-details/${id}`);
  };

  useEffect(() => {}, [productId]);

  useEffect(() => {
    getSingleProduct(productId.productId).then((data) => {
      setProduct(data.data.data.products[0]);
      if (quantity === data.data.data.products[0].availableQuantity) {
        handleQuantity();
        document.querySelector("#cart-details-add-btn").disabled = true;
      }
    });
  }, [search.headerDropdown, productId.productId]);

  useEffect(() => {
    if (quantity === product.availableQuantity) {
      handleQuantity();
      document.querySelector("#cart-details-add-btn").disabled = true;
    }
    if (quantity < product.availableQuantity) {
      document.querySelector("#cart-details-add-btn").disabled = false;
    }
  }, [quantity]);

  //we have product id from params and all products from state so now we display its properties
  //we also need to fetch related products and display max 4 of them on the end of the page

  const handleImage = (e) => {
    setImageSrc(e.target.id);
  };

  const [selectDiv, setSelectDiv] = useState("materials");

  const handleMaterials = () => {
    setSelectDiv("materials");
    document.querySelector(".origin-btn").classList.remove("active-btn-shop");
    document.querySelector(".care-btn").classList.remove("active-btn-shop");
    document.querySelector(".materials-btn").classList.add("active-btn-shop");
  };

  const handleCare = () => {
    setSelectDiv("care");
    document.querySelector(".origin-btn").classList.remove("active-btn-shop");
    document
      .querySelector(".materials-btn")
      .classList.remove("active-btn-shop");
    document.querySelector(".care-btn").classList.add("active-btn-shop");
  };

  const handleOrigin = () => {
    setSelectDiv("origin");
    document
      .querySelector(".materials-btn")
      .classList.remove("active-btn-shop");
    document.querySelector(".care-btn").classList.remove("active-btn-shop");
    document.querySelector(".origin-btn").classList.add("active-btn-shop");
  };

  const handleMessage = () => {
    document.querySelector(".cart-added-message").classList.add("show-message");

    setTimeout(() => {
      document
        .querySelector(".cart-added-message")
        .classList.remove("show-message");
    }, "3000");
  };

  const handleQuantity = () => {
    document
      .querySelector(".cart-added-quantity")
      .classList.add("show-message");

    setTimeout(() => {
      document
        .querySelector(".cart-added-quantity")
        .classList.remove("show-message");
    }, "3000");
  };

  useEffect(() => {}, [imageSrc, selectDiv]);
  const handleAddToCart = () => {
    const sizeId = document.querySelector("#shop-details-size").value;

    dispatch(
      cartActions.addCartItem({
        src: `https://dev.api.shopio.me/controller/image?imageid=${
          product.images.length !== 0 ? product.images[0].imageId : ""
        }&productid=${product.productId}`,
        name: product.productName,
        tag: "tag",
        rate: "rate",
        price:
          product.productDiscount === 0
            ? product.productPrice
            : (
                product.productPrice -
                (product.productPrice * product.productDiscount) / 100
              ).toFixed(2),
        id: product.productId,
        quantity: quantity,
        sizeId: sizeId,
        available: product.availableQuantity,
      })
    );
    handleMessage();
  };

  return (
    <div className="shop-details-preview-container container-padding">
      <CartAddedMessage />
      <CartQuantity />
      {product.length === 0 && <Loader />}
      {product.length !== 0 && (
        <>
          <Row>
            <Col xl={7}>
              <Row className="shop-details-preview-display-flex">
                <Col lg={3} className="shop-detail-preview-thumbnails">
                  {dummyData.thumbnails.map((thumb, key) => {
                    return (
                      <img
                        key={key}
                        id={dummyData.images[key].src}
                        src={thumb.src}
                        alt="thumb"
                        onClick={handleImage}
                      />
                    );
                  })}
                </Col>
                <Col lg={9} className="shop-detail-preview-big-image">
                  <img src={imageSrc} alt="productImg" />
                </Col>
              </Row>
            </Col>
            <Col xl={5} className="shop-details-preview-text-details">
              <h4>{product.productName}</h4>
              <section className="stars">
                {createStars(dummyData.rate)} - {dummyData.reviews} Reviews
              </section>
              <h3>
                $
                {product.productDiscount === 0
                  ? product.productPrice
                  : (
                      product.productPrice -
                      (product.productPrice * product.productDiscount) / 100
                    ).toFixed(2)}
                &nbsp;
                {product.productDiscount !== 0 && (
                  <span
                    style={{
                      textDecoration: "line-through",
                      fontSize: "1.1rem",
                      color: "#72767d",
                    }}
                  >
                    ${product.productPrice}
                  </span>
                )}
              </h3>

              <section>
                Size:
                <Form.Select id="shop-details-size">
                  {product.productSize.map((item) => {
                    return (
                      <option value={item.sizeId} key={item.sizeId}>
                        {item.sizeName}
                      </option>
                    );
                  })}
                </Form.Select>
              </section>
              <section className="shop-detail-color">
                Color:
                {product.productColor.map((item, key) => {
                  return (
                    <React.Fragment key={key}>
                      &nbsp;&nbsp;
                      <input
                        className="input-hidden"
                        type="radio"
                        name="color"
                        id={item.colorId}
                      />
                      <label
                        className="shop-detail-color-label"
                        htmlFor={item.colorId}
                        style={{ backgroundColor: `${item.colorValue}` }}
                      >
                        {item.colorName}
                      </label>
                      &nbsp;
                    </React.Fragment>
                  );
                })}
              </section>
              <p>Available quantity: {product.availableQuantity}</p>
              <section className="shop-details-quantity-add-cart">
                <section className="quantity-section">
                  <button
                    onClick={() =>
                      setQuantity((prevState) =>
                        prevState !== 1 ? prevState - 1 : prevState
                      )
                    }
                  >
                    -
                  </button>
                  &nbsp;{quantity}&nbsp;
                  <button
                    id="cart-details-add-btn"
                    onClick={() =>
                      setQuantity((prevState) =>
                        quantity === product.availableQuantity
                          ? prevState
                          : prevState + 1
                      )
                    }
                  >
                    +
                  </button>
                </section>
                <button className="shop-btn" onClick={handleAddToCart}>
                  Add to cart
                </button>
              </section>
              <section className="shop-details-add-wishlist">
                <button className="wishlist"> &hearts; Add to wishlist</button>
                <button className="wishlist">
                  <MdCompareArrows /> Add to compare
                </button>
              </section>
              <section className="footer-credit-cards">
                <Link to="/">
                  <FontAwesomeIcon
                    icon={faBtc}
                    color="#111"
                    className="fa-xl"
                  />
                </Link>
                <Link to="/">
                  <FontAwesomeIcon
                    icon={faCcAmex}
                    color="#111"
                    className="fa-xl"
                  />
                </Link>
                <Link to="/">
                  <FontAwesomeIcon
                    icon={faCcPaypal}
                    color="#111"
                    className="fa-xl"
                  />
                </Link>
                <Link to="/">
                  <FontAwesomeIcon
                    icon={faCcMastercard}
                    color="#111"
                    className="fa-xl"
                  />
                </Link>
                <Link to="/">
                  <FontAwesomeIcon
                    icon={faCcVisa}
                    color="#111"
                    className="fa-xl"
                  />
                </Link>
              </section>
              <p className="shop-details-p">
                SKU: <span className="darker">{product.productCode}</span>
              </p>
              <p className="shop-details-p">
                Categories:{" "}
                <span className="darker">{dummyData.categories}</span>
              </p>
              <p className="shop-details-p">
                Tags:
                <span className="darker">
                  {dummyData.tag.map((tag) => {
                    return ` ${tag} `;
                  })}
                </span>
              </p>
            </Col>
          </Row>
          <Row className="shop-details-changing-divs">
            <Col lg={4}>
              <button
                onClick={handleMaterials}
                className="materials-btn active-btn-shop"
              >
                Materials
              </button>
            </Col>

            <Col lg={4}>
              <button onClick={handleCare} className="care-btn">
                Care
              </button>
            </Col>
            <Col lg={4}>
              <button onClick={handleOrigin} className="origin-btn">
                Origin
              </button>
            </Col>
          </Row>
          <section className="shop-details-change-content">
            {selectDiv === "materials" && (
              <div className="materials">
                <h4>Materials</h4>
                <p>{product.productMaterials}</p>
              </div>
            )}
            {selectDiv === "care" && (
              <div className="care">
                <h4>Products Information</h4>
                <p>{product.productCare}</p>
              </div>
            )}
            {selectDiv === "origin" && (
              <div className="origin">
                <h4>Origin</h4>
                <p>{dummyData.origin}</p>
                <h4>Materials Used</h4>
                <p>{dummyData.materialUsed}</p>
              </div>
            )}
          </section>
          <section className="shop-detail-related-products">
            <Row>
              <h4>Related Products</h4>
              {dummyData2.items.map((item) => {
                return (
                  <CardInner
                    img={item.img}
                    tag={item.tag}
                    name={item.name}
                    rate={item.rate}
                    price={item.price}
                    redirect={redirectDetails}
                    id={item.id}
                    key={item.id}
                  />
                );
              })}
            </Row>
          </section>
        </>
      )}
    </div>
  );
};

export default ShopDetailsPreview;

const CardInner = (props) => {
  return (
    <Col lg={3} md={6}>
      <img
        src={props.img}
        alt="item"
        onClick={() => props.redirect(props.id)}
        style={{ cursor: "pointer" }}
      />
      {props.tag !== "" && <p className="tag">{props.tag}</p>}
      <h5>{props.name}</h5>
      <section className="stars">{createStars(props.rate)}</section>
      <h4>{props.price}</h4>
    </Col>
  );
};

function createStars(num) {
  if (num === 5)
    return (
      <>
        <AiFillStar fill="gold" size={16} />
        <AiFillStar fill="gold" size={16} />
        <AiFillStar fill="gold" size={16} />
        <AiFillStar fill="gold" size={16} />
        <AiFillStar fill="gold" size={16} />
      </>
    );

  if (num === 0) {
    return (
      <>
        <AiFillStar fill="grey" size={16} />
        <AiFillStar fill="grey" size={16} />
        <AiFillStar fill="grey" size={16} />
        <AiFillStar fill="grey" size={16} />
        <AiFillStar fill="grey" size={16} />
      </>
    );
  }

  let arr = [];
  for (let i = 0; i < num; i++) {
    arr.push(<AiFillStar fill="gold" size={16} />);
  }
  for (let j = 0; j < 5 - num; j++) {
    arr.push(<AiFillStar fill="grey" size={16} />);
  }

  return arr.map((item, key) => {
    return <React.Fragment key={key}>{item}</React.Fragment>;
  });
}
