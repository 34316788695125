import React from "react";
import "./PagesNavList.scss";
import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";

const PagesNavList = (props) => {
  const numOfLinks = props.num;
  let arr = [];
  for (let i = 0; i < numOfLinks - 1; i++) {
    arr.push(props.items[i]);
  }
  return (
    <div className="pages-navlist-container container-padding">
      <h3>{props.title}</h3>
      <section>
        {arr.map((item) => {
          return (
            <React.Fragment key={item}>
              <Link to={item === "home" ? "/" : `/${item}`}>{item}</Link>
              <MdKeyboardArrowRight />
            </React.Fragment>
          );
        })}
        <p className="pages-navlist-text">{props.items[numOfLinks - 1]}</p>
      </section>
    </div>
  );
};

export default PagesNavList;
