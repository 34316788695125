import * as api from "../api/slide";

export const getAllSlides = async () => {
  try {
    const data = await api.getAllSlides();
    return data;
  } catch (error) {
    console.log(error, "slide-service getall error");
    return error;
  }
};

export const getSlideById = async (slideId) => {
  try {
    const data = await api.getSlideById(slideId);
    return data;
  } catch (error) {
    console.log(error, "slide-service getbyid error");
    return error;
  }
};

export const createNewSlide = async (newSlide) => {
  try {
    const data = await api.createNewSlide(newSlide);
    return data;
  } catch (error) {
    console.log(error, "slide-service getall error");
    return error;
  }
};

export const updatedSlide = async (slideId, updatedSlide) => {
  try {
    const data = await api.updateSlide(slideId, updatedSlide);
    return data;
  } catch (error) {
    console.log(error, "slide-service getall error");
    return error;
  }
};

export const deletedSlide = async (slideId, deletedSlide) => {
  try {
    const data = await api.deleteSlide(slideId, deletedSlide);
    return data;
  } catch (error) {
    console.log(error, "slide-service getall error");
    return error;
  }
};
