import Carousel from "../components/Carousel/Carousel";
import HomeCards from "../components/HomeCards/HomeCards";
import HomeChangingDivs from "../components/HomeChangingDivs/HomeChangingDivs";
import ProductMonth from "../components/ProductMonth/ProductMonth";
import productSale from "../../assets/product-sale.png";

const Home = () => {
  return (
    <div className="home-container" style={{ overflowY: "hidden" }}>
      <Carousel />
      <HomeCards />
      <HomeChangingDivs />
      <ProductMonth
        text="Sale of $29.99"
        name="Multi-pocket Chest Bag Black"
        link="/shop"
        img={productSale}
      />
    </div>
  );
};

export default Home;
