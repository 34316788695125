import React from "react";
import "./CartModalContent.scss";
import { getSingleProduct } from "../../services/product-service";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { cartActions } from "../../slices/cart";
import { Form, Button, Modal, Col, Row } from "react-bootstrap";
import CartQuantity from "./CartQuantity";

const CartModalContent = (props) => {
  const [product, setProduct] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getSingleProduct(props.productId).then((data) => {
      setProduct(data.data.data.products[0]);
      if (quantity === product.availableQuantity) {
        handleMessage();
        document.querySelector("#cart-modal-add-btn").disabled = true;
      }
    });
  }, [props.productId]);

  useEffect(() => {
    if (quantity === product.availableQuantity) {
      handleMessage();
      document.querySelector("#cart-modal-add-btn").disabled = true;
    }
    if (quantity < product.availableQuantity) {
      document.querySelector("#cart-modal-add-btn").disabled = false;
    }
  }, [quantity, product.availableQuantity]);

  const handleMessage = () => {
    document
      .querySelector(".cart-added-quantity")
      .classList.add("show-message");

    setTimeout(() => {
      document
        .querySelector(".cart-added-quantity")
        .classList.remove("show-message");
    }, "3000");
  };

  const handleAddToCart = () => {
    const sizeId = document.querySelector("#size-id-for-cart").value;

    dispatch(
      cartActions.addCartItem({
        src: `https://dev.api.shopio.me/controller/image?imageid=${
          product.images.length !== 0 ? product.images[0].imageId : ""
        }&productid=${product.productId}`,
        name: product.productName,
        tag: "tag",
        rate: "rate",
        price:
          product.productDiscount === 0
            ? product.productPrice
            : (
                product.productPrice -
                (product.productPrice * product.productDiscount) / 100
              ).toFixed(2),
        id: product.productId,
        quantity: quantity,
        sizeId: sizeId,
        available: product.availableQuantity,
      })
    );
    props.closeModal();
    props.showMessage();
  };

  if (product.length !== 0)
    return (
      <Row className="cart-modal">
        <CartQuantity />
        <Col md={6}>
          <img
            src={`https://dev.api.shopio.me/controller/image?imageid=${
              product.images.length !== 0 ? product.images[0].imageId : ""
            }&productid=${product.productId}`}
            alt="productimage"
          />
        </Col>
        <Col md={6} className="cart-modal-content">
          <h2>{product.productName}</h2>
          <h4>
            $
            {product.productDiscount === 0
              ? product.productPrice
              : (
                  product.productPrice -
                  (product.productPrice * product.productDiscount) / 100
                ).toFixed(2)}
            &nbsp;
            {product.productDiscount !== 0 && (
              <span
                style={{
                  textDecoration: "line-through",
                  fontSize: "1.1rem",
                  color: "#72767d",
                }}
              >
                ${product.productPrice}
              </span>
            )}
          </h4>
          <p>Available quantity: {product.availableQuantity}</p>
          <p>{product.productMaterials}</p>
          <Link to={`/shop-details/${product.productId}`}>
            View details about this product
          </Link>

          <Form.Select id="size-id-for-cart">
            {product.productSize.map((item) => {
              return (
                <option value={item.sizeId} key={item.sizeId}>
                  {item.sizeName}
                </option>
              );
            })}
          </Form.Select>

          <section className="quantity-flex">
            <section className="quantity-section">
              <button
                onClick={() =>
                  setQuantity((prevState) =>
                    prevState !== 1 ? prevState - 1 : prevState
                  )
                }
              >
                -
              </button>
              <span>&nbsp;{quantity}&nbsp;</span>
              <button
                id="cart-modal-add-btn"
                onClick={() =>
                  setQuantity((prevState) =>
                    quantity === product.availableQuantity
                      ? prevState
                      : prevState + 1
                  )
                }
              >
                +
              </button>
            </section>
            <button className="shop-btn" onClick={handleAddToCart}>
              Add to cart
            </button>
          </section>
        </Col>
      </Row>
    );
  if (product.length === 0) return <p>Loading</p>;
};

export default CartModalContent;
