import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import ConfirmationDelete from "../ConfirmationDelete/ConfirmationDelete";
import EditAllInCommon from "./EditAllInCommon";
import {
  getAllBrends,
  deleteBrend,
  updateBrend,
} from "../../services/brend-service";
import {
  getAllColors,
  deleteColor,
  updateColor,
} from "../../services/color-service";
import {
  getAllCategories,
  deleteCategory,
  updateCategory,
} from "../../services/category-service";
import {
  getAllSizes,
  deleteSize,
  updateSize,
} from "../../services/size-service";
import Loader from "../Loader/Loader";

const AllInCommon = (props) => {
  const [confirm, setConfirm] = useState(false);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);
  const [data, setData] = useState([]);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  let items = data;
  let itemForEdit;
  let get;
  let editMe;

  useEffect(() => {
    get().then((data) => setData(data));
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + props.itemsPerPage;
    setCurrentItems(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / props.itemsPerPage));
  }, [itemOffset, props.itemsPerPage, data]);
  useEffect(() => {
    if (edit === true) {
      const target = document.querySelector(".edit-product-container");
      if (target !== null) target.scrollIntoView({ behavior: "smooth" });
    } else {
      const target = document.querySelector(".wrapper-admin-table");
      if (target !== null) target.scrollIntoView({ behavior: "smooth" });
    }
  }, [edit, id]);

  const closeModal = () => {
    setConfirm(false);
    setEdit(false);
    setId(null);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setId(e.target.id);
    setConfirm(true);
  };

  const deleteItem = () => {
    if (props.type === "brands") {
      deleteBrend(id, itemForEdit[0]).then(() => {
        get().then((data) => setData(data));
      });
    } else if (props.type === "category") {
      deleteCategory(id, itemForEdit[0]).then(() => {
        get().then((data) => setData(data));
      });
    } else if (props.type === "color") {
      deleteColor(id, itemForEdit[0]).then(() => {
        get().then((data) => setData(data));
      });
    } else if (props.type === "size") {
      deleteSize(id, itemForEdit[0]).then(() => {
        get().then((data) => setData(data));
      });
    }
    setConfirm(false);
    setId(null);
  };

  const handleEdit = (e) => {
    setId(e.target.id);
    setEdit(true);
  };

  const editItem = (updatedItem) => {
    if (props.type === "brands") {
      updateBrend(id, updatedItem).then(() => {
        get().then((data) => setData(data));
      });
    } else if (props.type === "category") {
      updateCategory(id, updatedItem).then(() => {
        get().then((data) => setData(data));
      });
    } else if (props.type === "color") {
      updateColor(id, updatedItem).then(() => {
        get().then((data) => setData(data));
      });
    } else if (props.type === "size") {
      updateSize(id, updatedItem).then(() => {
        get().then((data) => setData(data));
      });
    }

    setId(null);
    setEdit(false);
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * props.itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  if (props.type === "brands") {
    get = async () => {
      const data = await getAllBrends();
      return data.data.data.brands;
    };
  } else if (props.type === "color") {
    get = async () => {
      const data = await getAllColors();
      return data.data.data.colors;
    };
  } else if (props.type === "category") {
    get = async () => {
      const data = await getAllCategories();
      return data.data.data.categories;
    };
  } else if (props.type === "size") {
    get = async () => {
      const data = await getAllSizes();
      return data.data.data.sizes;
    };
  }

  if (props.type === "brands" && id) {
    itemForEdit = data.filter((x) => `${x.brandId}` === `${id}`);

    editMe = {
      id: itemForEdit[0].brandId,
      name: itemForEdit[0].brandName,
      status: itemForEdit[0].brandStatus,
    };
  } else if (props.type === "color" && id) {
    itemForEdit = data.filter((x) => `${x.colorId}` === `${id}`);

    editMe = {
      id: itemForEdit[0].colorId,
      name: itemForEdit[0].colorName,
      status: itemForEdit[0].status,
    };
  } else if (props.type === "category" && id) {
    itemForEdit = data.filter((x) => `${x.categoryId}` === `${id}`);

    editMe = {
      id: itemForEdit[0].categoryId,
      name: itemForEdit[0].categoryName,
      status: itemForEdit[0].categoryStatus,
    };
  } else if (props.type === "size" && id) {
    itemForEdit = data.filter((x) => `${x.sizeId}` === `${id}`);

    editMe = {
      id: itemForEdit[0].sizeId,
      name: itemForEdit[0].sizeName,
      status: itemForEdit[0].status,
    };
  }

  if (data.length !== 0)
    return (
      <div
        style={{ position: "relative", minHeight: "200px" }}
        className="wrapper-admin-table"
      >
        <h4>All {props.type}</h4>
        <Table responsive="xl" className="table-all-products">
          {confirm && (
            <ConfirmationDelete
              closeModal={closeModal}
              deleteItem={deleteItem}
            />
          )}

          <thead>
            <tr>
              <th>id</th>
              <th>Name</th>
              <th>Status</th>
            </tr>
          </thead>
          {data === [] ? (
            <tbody>
              <tr>Loading...</tr>
            </tbody>
          ) : (
            <Items
              currentItems={currentItems}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              type={props.type}
            />
          )}
        </Table>
        <ReactPaginate
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="< "
          renderOnZeroPageCount={null}
          className="react-paginate"
          activeLinkClassName="active-page"
        />
        {edit && (
          <EditAllInCommon
            title="Edit"
            closeModal={closeModal}
            handleEdit={editItem}
            item={editMe}
            link={props.link}
            type={props.type}
          />
        )}
      </div>
    );
  else
    return (
      <div
        style={{ position: "relative", minHeight: "200px" }}
        className="wrapper-admin-table"
      >
        <Loader />
      </div>
    );
};

export default AllInCommon;

const Items = (props) => {
  const items = props.currentItems;
  let loadItems = [];
  if (props.currentItems && props.type === "brands") {
    items.map((item) => {
      loadItems.push({
        id: item.brandId,
        name: item.brandName,
        status: item.brandStatus,
      });
    });
  } else if (props.currentItems && props.type === "color") {
    items.map((item) => {
      loadItems.push({
        id: item.colorId,
        name: item.colorName,
        status: item.status,
      });
    });
  } else if (props.currentItems && props.type === "category") {
    items.map((item) => {
      loadItems.push({
        id: item.categoryId,
        name: item.categoryName,
        status: item.categoryStatus,
      });
    });
  } else if (props.currentItems && props.type === "subcategory") {
    items.map((item) => {
      loadItems.push({
        id: item.subcategoryId,
        name: item.subcategoryName,
        status: item.subcategoryStatus,
      });
    });
  } else if (props.currentItems && props.type === "size") {
    items.map((item) => {
      loadItems.push({
        id: item.sizeId,
        name: item.sizeName,
        status: item.status,
      });
    });
  } else if (props.currentItems && props.type === "characteristic") {
    items.map((item) => {
      loadItems.push({
        id: item.characteristicId,
        name: item.characteristicName,
        status: item.characteristicStatus,
      });
    });
  }
  return (
    <tbody>
      {loadItems &&
        loadItems.map((item) => {
          return (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>{item.status}</td>
              <td>
                <Button onClick={props.handleEdit} id={item.id}>
                  Edit
                </Button>
              </td>
              {item.status === "Y" && (
                <td>
                  <Button
                    variant="danger"
                    onClick={props.handleDelete}
                    id={item.id}
                  >
                    Delete
                  </Button>
                </td>
              )}
            </tr>
          );
        })}
    </tbody>
  );
};
