import React from "react";
import { Form, Button } from "react-bootstrap";

const EditSubcategories = (props) => {
  const item = props.item[0];
  let formValid = false;

  const handleSubmit = (e) => {
    var form = new FormData(document.getElementById("edit-subcategory-form"));
    var nameValue = form.get("edit-subcategory-Name");
    var statusValue = form.get("edit-subcategory-status");
    e.preventDefault();

    if (nameValue !== "" && statusValue !== "") formValid = true;

    if (!formValid) {
      alert("Please provide correct information");
      return;
    }
    props.handleEdit({
      subcategoryName: nameValue,
      subcategoryStatus: statusValue,
    });
  };
  return (
    <div className="edit-product-container">
      <h5>
        {props.title}
        {item.subcategoryId}
      </h5>
      <Form onSubmit={handleSubmit} id="edit-subcategory-form">
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-subcategory-label-Name">Name</Form.Label>
          <Form.Control
            id="edit-subcategory-label-Name"
            type="text"
            defaultValue={item.subcategoryName}
            placeholder={item.subcategoryName}
            name="edit-subcategory-Name"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-subcategory-label-status">
            Status
          </Form.Label>
          <Form.Select
            id="edit-subcategory-label-status"
            name="edit-subcategory-status"
          >
            <option value="Y">Y</option>
            <option value="N">N</option>
          </Form.Select>
        </Form.Group>
      </Form>
      <Button variant="secondary" onClick={props.closeModal}>
        Cancel
      </Button>
      <Button
        form="edit-subcategory-form"
        variant="primary"
        onClick={handleSubmit}
      >
        Edit
      </Button>
    </div>
  );
};

export default EditSubcategories;
