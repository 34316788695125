import instance from "./instance";

const url = "https://dev.api.shopio.me/controller/seller";

export const getAllSellers = () => instance.get(url);

export const getSellerById = (sellerId) =>
  instance.get(`${url}?sellerid=${sellerId}`);

export const createNewSeller = (newSeller) =>
  instance.post(`${url}?register=Y`, {
    sellerName: newSeller.sellerName,
    sellerDescription: newSeller.sellerDescription,
    country: newSeller.country,
    city: newSeller.city,
    street: newSeller.street,
    streetNumber: newSeller.streetNumber,
    postal: newSeller.postal,
    password: newSeller.password,
    email: newSeller.email,
    phone: newSeller.phone,
  });

export const loginSeller = (existingSeller) =>
  instance.post(`${url}?login=Y`, {
    password: existingSeller.password,
    email: existingSeller.email,
  });

export const updateSeller = (sellerId, updatedSeller) =>
  instance.patch(`${url}?sellerid=${sellerId}`, {
    sellerName: updatedSeller.sellerName,
    sellerDescription: updatedSeller.sellerDescription,
    country: updatedSeller.country,
    sellerStatus: updatedSeller.sellerStatus,
    city: updatedSeller.city,
    street: updatedSeller.street,
    streetNumber: updatedSeller.streetNumber,
    postal: updatedSeller.postal,
    password: updatedSeller.password,
    email: updatedSeller.email,
    phone: updatedSeller.phone,
  });
export const deleteSeller = (sellerId, deletedSeller) =>
  instance.patch(`${url}?sellerid=${sellerId}`, {
    sellerName: deletedSeller.sellerName,
    sellerDescription: deletedSeller.sellerDescription,
    country: deletedSeller.country,
    sellerStatus: "N",
    city: deletedSeller.city,
    street: deletedSeller.street,
    streetNumber: deletedSeller.streetNumber,
    postal: deletedSeller.postal,
    password: deletedSeller.password,
    email: deletedSeller.email,
    phone: deletedSeller.phone,
  });
