import axios from "axios";

const url = "https://dev.api.shopio.me/controller";
// const refreshToken = () => {
//   return axios.post("https://dev.api.shopio.me/controller/auth?refresh=Y", {
//     token: getLocalRefreshToken(),
//   });
// };

function getLocalAccessToken() {
  const accessToken = window.localStorage.getItem("accessToken");
  return accessToken;
}
function getLocalRefreshToken() {
  const refreshToken = window.localStorage.getItem("refreshToken");
  return refreshToken;
}

const instance = axios.create({
  baseUrl: url,
  headers: { "Content-Type": "application/json" },
});

instance.interceptors.request.use(
  (config) => {
    const token = getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await instance.post(
            "https://dev.api.shopio.me/controller/auth?refresh=Y",
            {
              token: getLocalRefreshToken(),
            }
          );
          console.log(rs, "rs from instance");
          const { accessToken } = rs.data.data;
          const { refreshToken } = rs.data.data;
          window.localStorage.setItem("accessToken", accessToken);
          window.localStorage.setItem("refreshToken", refreshToken);
          instance.defaults.headers.common = {
            Authorization: `Bearer ${accessToken}`,
          };
          return instance(originalConfig);
        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }
          return Promise.reject(_error);
        }
      }
      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }
    return Promise.reject(err);
  }
);

export default instance;
