import instance from "./instance";

const url = "https://dev.api.shopio.me/controller/user";

export const getAllUsers = () => instance.get(url);

export const getUserById = (userId) => instance.get(`${url}?userid=${userId}`);

export const getByRoleId = (roleId) => instance.get(`${url}?roleid=${roleId}`);

export const updateUser = (userId, updatedUser) =>
  instance.patch(`${url}?userid=${userId}`, {
    firstname: updatedUser.firstname,
    lastname: updatedUser.lastname,
    country: updatedUser.country,
    userStatus: updatedUser.userStatus,
    city: updatedUser.city,
    street: updatedUser.street,
    streetNumber: updatedUser.streetNumber,
    postal: updatedUser.postal,
    password: updatedUser.password,
    email: updatedUser.email,
    phone: updatedUser.phone,
  });

export const deleteUser = (userId, deletedUser) =>
  instance.patch(`${url}?userid=${userId}`, {
    firstname: deletedUser.firstname,
    lastname: deletedUser.lastname,
    country: deletedUser.country,
    userStatus: "N",
    city: deletedUser.city,
    street: deletedUser.street,
    streetNumber: deletedUser.streetNumber,
    postal: deletedUser.postal,
    password: deletedUser.password,
    email: deletedUser.email,
    phone: deletedUser.phone,
  });

export const registerUser = (user) =>
  instance.post(`${url}?register=Y`, {
    firstname: user.firstname,
    lastname: user.lastname,
    country: user.country,
    city: user.city,
    street: user.street,
    streetNumber: user.streetNumber,
    postal: user.postal,
    password: user.password,
    email: user.email,
    phone: user.phone,
  });

export const registerWithRole = (user) =>
  instance.post(`${url}?register=Y`, {
    firstname: user.firstname,
    lastname: user.lastname,
    country: user.country,
    city: user.city,
    street: user.street,
    streetNumber: user.streetNumber,
    postal: user.postal,
    password: user.password,
    email: user.email,
    phone: user.phone,
    roleId: user.roleId,
  });

export const loginUser = (user) =>
  instance.post(`${url}?login=Y`, {
    password: user.password,
    email: user.email,
  });

export const logoutUser = (token) =>
  instance.post(`${url}?logout=Y`, { token: token });
