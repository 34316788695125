import React from "react";

const MapComponent = () => {
  return (
    <div className="mapouter">
      <div className="gmap_canvas">
        <iframe
          title="contact-map"
          src="https://maps.google.com/maps?q=109%20Avenue%20L%C3%A9on,%2063%20Clermont-Ferrand&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
          id="gmap_canvas"
          frameBorder="0"
          scrolling="no"
          style={{ width: "100%", height: "390px" }}
        ></iframe>
        <a href="https://fnftest.net" style={{ display: "none" }}>
          FNF Character Test
        </a>
        <a
          href="https://www.googlemapsiframegenerator.com"
          style={{ display: "none" }}
        >
          Google Maps Iframe Generator - Free Html Embed Code
        </a>
      </div>
    </div>
  );
};

export default MapComponent;
