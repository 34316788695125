import React from "react";
import { Link } from "react-router-dom";
import "./NotFoundPage.scss";

const NotFoundPage = () => {
  return (
    <div className="not-found-page">
      <section>
        <h1>404</h1>
        <h3>PAGE NOT FOUND</h3>
        <p>
          The page requested couldn't be found. <br /> This could be a spelling
          error in the URL or a removed page.
        </p>
        <Link to="/" className="shop-btn">
          HOMEPAGE
        </Link>
      </section>
    </div>
  );
};

export default NotFoundPage;
