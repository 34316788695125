import React from "react";
import useValidate from "../../hooks/useValidate";
import { Form, Button } from "react-bootstrap";
import { getSubcategoryByStatus } from "../../services/subcategory-service";
import { useState } from "react";
import { useEffect } from "react";
import { createNewCharacteristic } from "../../services/characteristics-service";

const NewCharacteristic = (props) => {
  const isNotEmpty = (value) => value.trim().length > 0;
  const {
    value: name,
    isValid: nameValid,
    hasError: nameError,
    valueBlurHandler: nameBlurHandler,
    valueChangedHandler: nameChangeHandler,
    reset: nameReset,
  } = useValidate(isNotEmpty);

  let formValid = false;

  if (nameValid) {
    formValid = true;
  }

  const [subcategory, setSubcategory] = useState([]);

  useEffect(() => {
    getSubcategoryByStatus("Y").then((data) => {
      setSubcategory(data.data.data.subcategories);
    });
  }, []);

  const handleSubmit = (e) => {
    var form = new FormData(
      document.getElementById("add-new-characteristic-form")
    );
    var subcategoryValue = form.get("add-new-characteristic-subcategoryId");
    var statusValue = form.get("add-new-characteristic-status");
    e.preventDefault();

    if (!formValid) {
      alert("Please provide correct information");
      return;
    }

    createNewCharacteristic({
      characteristicName: name,
      characteristicStatus: statusValue,
      subcategoryId: subcategoryValue,
    }).then((data) => {});

    nameReset();
  };

  return (
    <div className="add-new">
      <h5>Add new {props.title}</h5>
      <Form onSubmit={handleSubmit} id="add-new-characteristic-form">
        <Form.Group className="mb-3">
          <Form.Label htmlFor={`add-new-characteristic-label-name`}>
            Name
          </Form.Label>
          <Form.Control
            id={`add-new-characteristic-label-name`}
            type="text"
            placeholder="Name"
            value={name}
            onChange={nameChangeHandler}
            onBlur={nameBlurHandler}
            name="add-new-characteristic-name"
          />
          {nameError && <p className="red-text">Name can't be empty</p>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor={`add-new-characteristic-label-subcategoryId`}>
            Subcategory
          </Form.Label>
          <Form.Select
            id="add-new-characteristic-label-subcategoryId"
            name="add-new-characteristic-subcategoryId"
          >
            {subcategory &&
              subcategory.map((item) => {
                return (
                  <option key={item.subcategoryId} value={item.subcategoryId}>
                    {item.subcategoryName}
                  </option>
                );
              })}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor={`add-new-characteristic-label-status`}>
            Status
          </Form.Label>
          <Form.Select
            id="add-new-characteristic-label-status"
            name="add-new-characteristic-status"
          >
            <option value="Y">Y</option>
            <option value="N">N</option>
          </Form.Select>
        </Form.Group>

        <Button variant="primary" type="submit" className="mt-3">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default NewCharacteristic;
