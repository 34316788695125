import React, { useState } from "react";
import "./HomeChangingDivs.scss";
import { Col, Row } from "react-bootstrap";
import { AiFillStar } from "react-icons/ai";
import { useNavigate } from "react-router";

import product1 from "../../../assets/product-1.jpg";
import product2 from "../../../assets/product-2.jpg";
import product3 from "../../../assets/product-3.jpg";
import product4 from "../../../assets/product-4.jpg";
import product5 from "../../../assets/product-5.jpg";
import { useEffect } from "react";
import {
  getNewProducts,
  getSaleProducts,
  getBestsellerProducts,
} from "../../services/product-service";

const HomeChangingDivs = () => {
  const [selectDiv, setSelectDiv] = useState("best-sellers");
  const [bestSellers, setBestSellers] = useState([]);
  const [newSellers, setNewSellers] = useState([]);
  const [saleSellers, setSaleSellers] = useState([]);

  useEffect(() => {
    getBestsellerProducts().then((data) => {
      setBestSellers(data.data.data.products);
    });
    getSaleProducts().then((data) => {
      setSaleSellers(data.data.data.products);
    });
    getNewProducts().then((data) => {
      setNewSellers(data.data.data.products);
    });
  }, []);

  const handleBestSellers = () => {
    setSelectDiv("best-sellers");
    document.querySelector(".new-arrivals-btn").classList.remove("active-btn");
    document.querySelector(".hot-sales-btn").classList.remove("active-btn");
    document.querySelector(".best-sellers-btn").classList.add("active-btn");
  };

  const handleHotSales = () => {
    setSelectDiv("hot-sales");
    document.querySelector(".new-arrivals-btn").classList.remove("active-btn");
    document.querySelector(".best-sellers-btn").classList.remove("active-btn");
    document.querySelector(".hot-sales-btn").classList.add("active-btn");
    const showing = document.querySelector(".best-sellers");
    console.log(showing);
  };

  const handleNewArrivals = () => {
    setSelectDiv("new-arrivals");
    document.querySelector(".best-sellers-btn").classList.remove("active-btn");
    document.querySelector(".hot-sales-btn").classList.remove("active-btn");
    document.querySelector(".new-arrivals-btn").classList.add("active-btn");
  };

  const navigate = useNavigate();
  const redirectDetails = (id) => {
    navigate(`/shop-details/${id}`);
  };

  return (
    <div className="home-changing-divs container-padding">
      <section className="home-changing-divs-btns">
        <button
          onClick={handleBestSellers}
          className="best-sellers-btn active-btn"
        >
          Best Sellers
        </button>
        <button onClick={handleNewArrivals} className="new-arrivals-btn">
          New Arrivals
        </button>
        <button onClick={handleHotSales} className="hot-sales-btn">
          Hot Sales
        </button>
      </section>
      <section className="home-changing-divs-cards">
        {selectDiv === "best-sellers" && (
          <Row className="best-sellers">
            {dummyData.items.map((item) => {
              return (
                <CardInner
                  key={item.id}
                  img={item.img}
                  tag={item.tag}
                  name={item.name}
                  rate={item.rate}
                  price={item.price}
                  id={item.id}
                  redirect={redirectDetails}
                />
              );
            })}
          </Row>
        )}

        {selectDiv === "new-arrivals" && (
          <Row className="new-arrivals">
            {dummyData.items.map((item) => {
              if (item.tag === "NEW")
                return (
                  <CardInner
                    key={item.id}
                    img={item.img}
                    tag={item.tag}
                    name={item.name}
                    rate={item.rate}
                    price={item.price}
                    id={item.id}
                    redirect={redirectDetails}
                  />
                );
            })}
          </Row>
        )}

        {selectDiv === "hot-sales" && (
          <Row className="hot-sales">
            {dummyData.items.map((item) => {
              if (item.tag === "SALE")
                return (
                  <CardInner
                    key={item.id}
                    img={item.img}
                    tag={item.tag}
                    name={item.name}
                    rate={item.rate}
                    price={item.price}
                    id={item.id}
                    redirect={redirectDetails}
                  />
                );
            })}
          </Row>
        )}
      </section>
    </div>
  );
};

export default HomeChangingDivs;

const CardInner = (props) => {
  return (
    <Col lg={3} md={6} className="cards-of-changing-divs">
      <img
        src={props.img}
        alt="item"
        onClick={() => props.redirect(props.id)}
      />
      {props.tag !== "" && <p className="tag">{props.tag}</p>}
      <h5>{props.name}</h5>
      <section className="stars">{createStars(props.rate)}</section>
      <h4>{props.price}</h4>
    </Col>
  );
};

function createStars(num) {
  if (num === 5)
    return (
      <>
        <AiFillStar fill="gold" size={16} />
        <AiFillStar fill="gold" size={16} />
        <AiFillStar fill="gold" size={16} />
        <AiFillStar fill="gold" size={16} />
        <AiFillStar fill="gold" size={16} />
      </>
    );

  if (num === 0) {
    return (
      <>
        <AiFillStar fill="grey" size={16} />
        <AiFillStar fill="grey" size={16} />
        <AiFillStar fill="grey" size={16} />
        <AiFillStar fill="grey" size={16} />
        <AiFillStar fill="grey" size={16} />
      </>
    );
  }

  let arr = [];
  for (let i = 0; i < num; i++) {
    arr.push(<AiFillStar fill="gold" size={16} />);
  }
  for (let j = 0; j < 5 - num; j++) {
    arr.push(<AiFillStar fill="grey" size={16} />);
  }

  return arr.map((item, key) => {
    return <React.Fragment key={key}>{item}</React.Fragment>;
  });
}

const dummyData = {
  items: [
    {
      id: 1,
      link: "/shop",
      name: "PIQUÉ BIKER JACKET",
      price: "$67.24",
      tag: "NEW",
      rate: 4,
      img: `${product1}`,
    },
    {
      id: 2,
      link: "/shop",
      name: "MULTI-POCKET CHEST BAG",
      price: "$67.24",
      tag: "",
      rate: 3,
      img: `${product2}`,
    },
    {
      id: 3,
      link: "/shop",
      name: "DIAGONAL TEXTURED CAP",
      price: "$67.24",
      tag: "",
      rate: 4,
      img: `${product3}`,
    },
    {
      id: 4,
      link: "/shop",
      name: "LETHER BACKPACK",
      price: "$67.24",
      tag: "NEW",
      rate: 5,
      img: `${product4}`,
    },
    {
      id: 5,
      link: "/shop",
      name: "PIQUÉ BIKER JACKET",
      price: "$67.24",
      tag: "",
      rate: 4,
      img: `${product5}`,
    },
    {
      id: 6,
      link: "/shop",
      name: "DIAGONAL TEXTURED CAP ",
      price: "$67.24",
      tag: "",
      rate: 5,
      img: `${product1}`,
    },
    {
      id: 7,
      link: "/shop",
      name: "PIQUÉ BIKER JACKET",
      price: "$67.24",
      tag: "SALE",
      rate: 4,
      img: `${product2}`,
    },
    {
      id: 8,
      link: "/shop",
      name: "LETHER BACKPACK",
      price: "$67.24",
      tag: "SALE",
      rate: 0,
      img: `${product3}`,
    },
  ],
};
