import * as api from "../api/size";

export const getAllSizes = async () => {
  try {
    const data = await api.getAllSizes();
    return data;
  } catch (error) {
    console.log(error, "size-service getall error");
    return error;
  }
};

export const getSizeById = async (sizeId) => {
  try {
    const data = await api.getSizeById(sizeId);
    return data;
  } catch (error) {
    console.log(error, "size-service getbyid error");
    return error;
  }
};

export const getSizeBySubcategoryId = async (subcategoryId) => {
  try {
    const data = await api.getSizeBySubcategoryId(subcategoryId);
    return data;
  } catch (error) {
    console.log(error, "size-service getbysubcategory error");
    return error;
  }
};

export const createNewSize = async (newSize) => {
  try {
    const data = await api.createNewSize(newSize);
    return data;
  } catch (error) {
    console.log(error, "size-service create error");
    return error;
  }
};

export const updateSize = async (sizeId, updatedSize) => {
  try {
    const data = await api.updateSize(sizeId, updatedSize);
    return data;
  } catch (error) {
    console.log(error, "size-service update error");
    return error;
  }
};

export const deleteSize = async (sizeId, deletedSize) => {
  try {
    const data = await api.deleteSize(sizeId, deletedSize);
    return data;
  } catch (error) {
    console.log(error, "size-service delete error");
    return error;
  }
};
