import instance from "./instance";
const url = "https://dev.api.shopio.me/controller/slide";

export const getAllSlides = () => instance.get(url);

export const getSlideById = (slideId) =>
  instance.get(`${url}?slideid=${slideId}`);

export const createNewSlide = (newSlide) =>
  instance.post(url, {
    categoryId: newSlide.categoryId,
    slideText: newSlide.slideText,
    slideTitle: newSlide.slideTitle,
  });

export const updateSlide = (slideId, updatedSlide) =>
  instance.patch(`${url}?slideid=${slideId}`, {
    categoryId: updatedSlide.categoryId,
    slideText: updatedSlide.slideText,
    slideTitle: updatedSlide.slideTitle,
    slideStatus: updatedSlide.slideStatus,
  });

export const deleteSlide = (slideId, deletedSlide) =>
  instance.patch(`${url}?slideid=${slideId}`, {
    categoryId: deletedSlide.categoryId,
    slideText: deletedSlide.slideText,
    slideTitle: deletedSlide.slideTitle,
    slideStatus: "N",
  });
