import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { getByRoleId, updateUser } from "../../services/user-service";
import { useSelector } from "react-redux";
import EditAdmin from "./EditAdmin";
import Loader from "../Loader/Loader";

const AllAdmins = (props) => {
  const auth = useSelector((state) => state.auth);
  const [admins, setAdmins] = useState([]);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState();
  const [itemOffset, setItemOffset] = useState(0);
  let items = admins;
  let itemForEdit;

  useEffect(() => {
    getByRoleId(2).then((data) => {
      setAdmins(data.data.data.users);
    });
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + props.itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / props.itemsPerPage));
  }, [itemOffset, props.itemsPerPage, items]);

  const closeModal = () => {
    setEdit(false);
  };

  useEffect(() => {
    if (edit === true) {
      const target = document.querySelector(".edit-product-container");
      if (target !== null) target.scrollIntoView({ behavior: "smooth" });
    } else {
      const target = document.querySelector(".wrapper-admin-table");
      if (target !== null) target.scrollIntoView({ behavior: "smooth" });
    }
  }, [edit, id]);

  const handleEdit = (e) => {
    setId(e.target.id);
    setEdit(true);
  };
  const editItem = (updatedItem) => {
    updateUser(id, updatedItem).then((data) => {
      setId(null);
      setEdit(false);
      getByRoleId(2).then((data) => {
        setAdmins(data.data.data.users);
      });
    });
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * props.itemsPerPage) % items.length;

    setItemOffset(newOffset);
  };

  if (id) itemForEdit = items.filter((x) => `${x.userId}` === `${id}`);

  if (admins.length !== 0)
    return (
      <div
        style={{ position: "relative", minHeight: "200px" }}
        className="wrapper-admin-table"
      >
        <h4>All admins</h4>
        <Table responsive="xl" className="table-all-products">
          {currentItems === null && <Loader />}

          <thead>
            <tr>
              <th>id</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Country</th>
              <th>City</th>
              <th>Street</th>
              <th>Street Number</th>
              <th>Postal</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Status</th>
            </tr>
          </thead>
          <Items
            currentItems={currentItems}
            id={auth.id}
            role={auth.role}
            handleEdit={handleEdit}
          />
        </Table>
        <ReactPaginate
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="< "
          renderOnZeroPageCount={null}
          className="react-paginate"
          activeLinkClassName="active-page"
        />
        {edit && (
          <EditAdmin
            title="Edit"
            closeModal={closeModal}
            handleEdit={editItem}
            item={itemForEdit}
          />
        )}
      </div>
    );
  else
    return (
      <div
        style={{ position: "relative", minHeight: "200px" }}
        className="wrapper-admin-table"
      >
        <Loader />
      </div>
    );
};

export default AllAdmins;

const Items = (props) => {
  return (
    <tbody>
      {props.currentItems &&
        props.currentItems.map((item) => {
          return (
            <tr>
              <td>{item.userId}</td>
              <td>{item.firstname}</td>
              <td>{item.lastname}</td>
              <td>{item.country}</td>
              <td>{item.city}</td>
              <td>{item.street}</td>
              <td>{item.streetNumber}</td>
              <td>{item.postal}</td>
              <td>{item.email}</td>
              <td>{item.phone}</td>
              <td>{item.status}</td>
              {props.role === "admin" && props.id === item.userId && (
                <td>
                  <Button onClick={props.handleEdit} id={item.userId}>
                    Edit
                  </Button>
                </td>
              )}
            </tr>
          );
        })}
    </tbody>
  );
};
