import instance from "./instance";
import axios from "axios";
const url = "https://dev.api.shopio.me/controller/image";

export const getImage = (imageId, byWhatCondition, conditionId) =>
  instance.get(`${url}?imageid=${imageId}&${byWhatCondition}id=${conditionId}`);

export const createImage = (newImage, byWhatCondition, conditionId) =>
  axios.post(
    `${url}?${byWhatCondition}id=${conditionId}`,
    {
      formData: newImage,
    },
    {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    }
  );

//instead of deletion we are just changing image status from Y to N
export const deleteImage = (imageId) =>
  instance.patch(`${url}?imageid=${imageId}`, { imageStatus: "N" });
