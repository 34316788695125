import { createSlice } from "@reduxjs/toolkit";

export const productSlice = createSlice({
  name: "product",
  initialState: {
    products: [],
  },
  reducers: {
    getAllProducts: (state, action) => {
      state.products = action.payload;
    },
    createProduct: (state, action) => {
      state.products = [...state.products, action.payload];
    },
    updateProduct: (state, action) => {
      state.products = state.products.map((product) =>
        product.productId === action.payload.productId
          ? action.payload
          : product
      );
    },
    deleteProduct: (state, action) => {
      state.products = state.products.map((product) =>
        product.productId === action.payload.productId
          ? action.payload
          : product
      );
    },
  },
});

export const productActions = productSlice.actions;
export default productSlice.reducer;
