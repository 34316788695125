import * as api from "../api/product";

export const getAllProducts = async () => {
  try {
    const data = await api.getAllProducts();
    return data;
  } catch (error) {
    console.log(error, "product-service getAllProducts error");
    return error;
  }
};

export const getPageProducts = async (pageNum) => {
  try {
    const data = await api.getPageProducts(pageNum);
    return data;
  } catch (error) {
    console.log(error, "product-service getpage");
    return error;
  }
};

export const createProduct = async (newProduct) => {
  try {
    const data = await api.createProduct(newProduct);
    return data;
  } catch (error) {
    console.log(error, "product-service createProduct error");
    return error;
  }
};

export const updateProduct = async (productId, updatedProduct) => {
  try {
    const data = await api.updateProduct(productId, updatedProduct);
    return data;
  } catch (error) {
    console.log(error, "product-service updateProduct error");
    return error;
  }
};

export const deleteProduct = async (productId, deletedProduct) => {
  try {
    const data = await api.deleteProduct(productId, deletedProduct);
    return data;
  } catch (error) {
    console.log(error, "product-service deleteProduct error");
    return error;
  }
};

export const getSingleProduct = async (productId) => {
  try {
    const data = await api.getSingleProduct(productId);
    return data;
  } catch (error) {
    console.log(error, "product-service getsingle error");
    return error;
  }
};

export const getFilteredProducts = async (
  productFilter,
  productFilterValue
) => {
  try {
    const data = await api.getFilteredProducts(
      productFilter,
      productFilterValue
    );
    return data;
  } catch (error) {
    console.log(error, "product-service getfiltered error");
    return error;
  }
};

export const getFilteredAvailableProducts = async (
  productFilter,
  productFilterValue
) => {
  try {
    const data = await api.getFilteredAvailableProducts(
      productFilter,
      productFilterValue
    );
    return data;
  } catch (error) {
    console.log(error, "product-service getfiltered error");
    return error;
  }
};
export const getMultiFilteredAvailableProducts = async (productFilter) => {
  try {
    const data = await api.getMultiFilteredAvailableProducts(productFilter);
    return data;
  } catch (error) {
    console.log(error, "product-service getfiltered error");
    return error;
  }
};

export const getAvailableProducts = async () => {
  try {
    const data = await api.getAvailableProducts();
    // dispatch(productActions.getAllProducts(data));

    return data;
  } catch (error) {
    console.log(error, "product-service getAvailable error");
    return error;
  }
};

export const getMultipleProductsById = async (idArray) => {
  try {
    const data = await api.getMultipleProductsById(idArray);
    return data;
  } catch (error) {
    console.log(error, "product-service getmultiplebyid error");
    return error;
  }
};

export const getRelatedProducts = async (productId) => {
  try {
    const data = await api.getRelatedProducts(productId);
    return data;
  } catch (error) {
    console.log(error, "product-service getrelated error");
    return error;
  }
};

export const getMonthProduct = async () => {
  try {
    const data = await api.getMonthProduct();
    return data;
  } catch (error) {
    console.log(error, "product-service getmonth error");
    return error;
  }
};

export const getNewProducts = async () => {
  try {
    const data = await api.getNewProducts();
    return data;
  } catch (error) {
    console.log(error, "product-service getnew error");
    return error;
  }
};
export const getSaleProducts = async () => {
  try {
    const data = await api.getSaleProducts();
    return data;
  } catch (error) {
    console.log(error, "product-service getSale error");
    return error;
  }
};
export const getBestsellerProducts = async () => {
  try {
    const data = await api.getBestsellerProducts();
    return data;
  } catch (error) {
    console.log(error, "product-service getBestseller error");
    return error;
  }
};

export const getSearchOfProducts = async (searchValue) => {
  try {
    const data = await api.getSearchOfProducts(searchValue);
    return data;
  } catch (error) {
    console.log(error, "product-service getsearch error");
    return error;
  }
};

export const getProductsBySellerId = async (sellerId) => {
  try {
    const data = await api.getProductsBySellerId(sellerId);
    return data;
  } catch (error) {
    console.log(error, "product-service getbyseller error");
    return error;
  }
};

export const getProductsByCategoryId = async (categoryId) => {
  try {
    const data = await api.getProductsByCategoryId(categoryId);
    return data;
  } catch (error) {
    console.log(error, "product-service getbycategory error");
    return error;
  }
};

export const getProductsBySubCategoryId = async (subCategoryId) => {
  try {
    const data = await api.getProductsBySubCategoryId(subCategoryId);
    return data;
  } catch (error) {
    console.log(error, "product-service getbySubCategory error");
    return error;
  }
};

export const getProductsByBrendId = async (brendId) => {
  try {
    const data = await api.getProductsByBrendId(brendId);
    return data;
  } catch (error) {
    console.log(error, "product-service getbyBrend error");
    return error;
  }
};

//ovo bi trebalo da se pokrece samo na backend-u
export const getProductsBySoldCount = async () => {
  try {
    const data = await api.getProductsBySoldCount();
    return data;
  } catch (error) {
    console.log(error, "product-service getbysoldcount error");
    return error;
  }
};

export const getNumberOfProductsInCart = async () => {
  try {
    const data = await api.getNumberOfProductsInCart();
    return data;
  } catch (error) {
    console.log(error, "product-service getNumofproductsincart error");
    return error;
  }
};
