import * as api from "../api/characteristics";

export const getAllCharacteristic = async () => {
  try {
    const data = await api.getAllCharacteristic();
    return data;
  } catch (error) {
    console.log(error, "characteristic-service getall error");
    return error;
  }
};

export const getCharacteristicById = async (characteristicId) => {
  try {
    const data = await api.getCharacteristicById(characteristicId);
    return data;
  } catch (error) {
    console.log(error, "characteristic-service getbyid error");
    return error;
  }
};

export const getCharacteristicBySubcategory = async (subcategoryId) => {
  try {
    const data = await api.getCharacteristicBySubcategory(subcategoryId);
    return data;
  } catch (error) {
    console.log(error, "characteristic-service getbysubcategory error");
    return error;
  }
};

export const createNewCharacteristic = async (newCharacteristic) => {
  try {
    const data = await api.createCharacteristic(newCharacteristic);
    return data;
  } catch (error) {
    console.log(error, "characteristic-service create error");
    return error;
  }
};

export const updateCharacteristic = async (
  characteristicId,
  updatedCharacteristic
) => {
  try {
    const data = await api.updateCharacteristic(
      characteristicId,
      updatedCharacteristic
    );
    return data;
  } catch (error) {
    console.log(error, "characteristic-service update error");
    return error;
  }
};

export const deleteCharacteristic = async (
  characteristicId,
  deletedCharacteristic
) => {
  try {
    const data = await api.deleteCharacteristic(
      characteristicId,
      deletedCharacteristic
    );
    return data;
  } catch (error) {
    console.log(error, "characteristic-service delete error");
    return error;
  }
};
