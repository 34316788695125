import React, { useEffect, useState } from "react";
import { getAvailableProducts } from "../services/product-service";
import PagesNavList from "../components/PagesNavList/PagesNavList";
import ShopContent from "../components/ShopContent/ShopContent";
import Loader from "../components/Loader/Loader";

const Shop = () => {
  // useEffect(() => {
  //   const products = getAvailableProducts(setLoading);
  //   setAvailableProducts(products);
  //   setLoading(false);
  //   console.log(loading);
  // }, [loading]);
  return (
    <div className="shop-container">
      <PagesNavList title="Shop" num={2} items={["home", "shop"]} />
      <ShopContent />
    </div>
  );
};

export default Shop;
