import React from "react";

import { Form, Button } from "react-bootstrap";

const EditAdminAsSuperAdmin = (props) => {
  const item = props.item[0];

  let formValid = false;

  const handleSubmit = (e) => {
    var form = new FormData(
      document.getElementById("edit-admin-superadmin-form")
    );

    var statusValue = form.get("edit-admin-superadmin-status");

    e.preventDefault();
    if (statusValue !== "") formValid = true;

    if (!formValid) {
      alert("Please fill out all of the fields");
      return;
    }

    // ovdje saljemo editovani objekat
    props.handleEdit({
      firstname: item.firstname,
      lastname: item.lastName,
      country: item.country,
      userStatus: statusValue,
      city: item.city,
      street: item.street,
      streetNumber: item.streetNum,
      password: item.password,
      email: item.email,
      phone: item.phone,
    });
  };

  return (
    <div className={`edit-product-container ${props.className}`}>
      <h5>Edit admin #{item.userId}</h5>
      <Form onSubmit={handleSubmit} id="edit-admin-superadmin-form">
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-admin-superadmin-label-status">
            Status
          </Form.Label>
          <Form.Control
            id="edit-admin-superadmin-label-status"
            type="text"
            name="edit-admin-superadmin-status"
            placeholder={item.status}
            defaultValue={item.status}
          />
        </Form.Group>
      </Form>
      <Button variant="secondary" onClick={props.closeModal}>
        Cancel
      </Button>
      <Button
        form="edit-admin-superadmin-form"
        variant="primary"
        type="submit"
        onClick={handleSubmit}
      >
        Edit
      </Button>
    </div>
  );
};

export default EditAdminAsSuperAdmin;
