import React from "react";

import { Form, Button } from "react-bootstrap";

const EditUser = (props) => {
  const item = props.item[0];

  let formValid = false;

  const handleSubmit = (e) => {
    var form = new FormData(document.getElementById("edit-user-form"));

    var statusValue = form.get("edit-user-status");

    e.preventDefault();
    if (statusValue !== "") formValid = true;

    if (!formValid) {
      alert("Please fill out all of the fields");
      return;
    }

    //ovdje saljemo editovani objekat
    props.handleEdit({
      firstname: item.firstname,
      lastname: item.lastname,
      country: item.country,
      userStatus: statusValue,
      city: item.city,
      street: item.street,
      streetNumber: item.streetNumber,
      password: item.password,
      email: item.email,
      phone: item.phone,
    });
  };

  return (
    <div className="edit-product-container">
      <h5>Edit user #{item.userId}</h5>
      <Form onSubmit={handleSubmit} id="edit-user-form">
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-user-label-status">Status</Form.Label>
          <Form.Select id="edit-user-label-status" name="edit-user-status">
            <option value="Y">Y</option>
            <option value="N">N</option>
          </Form.Select>
        </Form.Group>
      </Form>
      <Button variant="secondary" onClick={props.closeModal}>
        Cancel
      </Button>
      <Button
        form="edit-user-form"
        variant="primary"
        type="submit"
        onClick={handleSubmit}
      >
        Edit
      </Button>
    </div>
  );
};

export default EditUser;
