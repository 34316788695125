import React from "react";
import ShopDetailsPreview from "../components/ShopDetailsPreview/ShopDetailsPreview";
import PagesNavList from "../components/PagesNavList/PagesNavList";

const ShopDetails = () => {
  return (
    <div className="shop-details-container">
      <PagesNavList
        title="Shop details"
        num={3}
        items={["home", "shop", "shop details"]}
      />
      <ShopDetailsPreview />
    </div>
  );
};

export default ShopDetails;
