import React, { useEffect, useState } from "react";
import "./ShoppingCart.scss";
import { Row, Col } from "react-bootstrap";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
} from "react-icons/md";
import { ImCross } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { cartActions } from "../../slices/cart";

const ShoppingCart = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  const totalAmount = cart.totalAmount;

  const addItem = (item) => {
    dispatch(cartActions.addCartItem(item));
  };
  const removeItem = (id) => {
    dispatch(cartActions.removeCartItem(id));
  };
  const deleteItem = (id) => {
    dispatch(cartActions.deleteCartItem(id));
  };

  return (
    <div className="shopping-cart-container container-padding">
      {cart.items.length > 0 && (
        <Row>
          <Col lg={8}>
            <section className="shopping-cart-titles">
              <h4>Product</h4>
              <h4>Quantity</h4>
              <h4>Total</h4>
            </section>
            <section className="shopping-cart-products">
              {cart.items.map((item) => {
                return (
                  <Product
                    src={item.src}
                    name={item.name}
                    price={item.price}
                    id={item.id}
                    quantity={item.quantity}
                    add={addItem}
                    remove={removeItem}
                    delete={deleteItem}
                    available={item.available}
                  />
                );
              })}
            </section>
            <Link to="/shop" className="shop-btn" id="continue-shopping">
              Continue shopping
            </Link>
          </Col>
          <Col lg={4} className="checkout">
            <h4>Discount Codes</h4>
            <section className="shopping-cart-input">
              <input type="text" placeholder="Coupon code" />
              <button className="shop-btn apply-btn">Apply</button>
            </section>
            <section className="shopping-cart-checkout">
              <h4>Card total</h4>
              <p>
                Subtotal <span>${totalAmount}</span>
              </p>
              <p>
                Total <span>${totalAmount}</span>
              </p>
              <Link to="/check-out" className="shop-btn">
                Proceed to checkout
              </Link>
            </section>
          </Col>
        </Row>
      )}
      {cart.items.length === 0 && (
        <div className="empty-cart">
          <p>Your shopping cart is empty!</p>
          <Link to="/shop" className="shop-btn">
            Go shopping
          </Link>
        </div>
      )}
    </div>
  );
};

export default ShoppingCart;

const Product = (props) => {
  const priceNum = props.price;
  const [value, setValue] = useState(1);

  useEffect(() => {
    if (value === props.available) {
      document.querySelector("#cart-cart-add-mobile-btn").disabled = true;
      document.querySelector("#cart-cart-add-btn").disabled = true;
    }
  }, []);

  useEffect(() => {
    if (value === props.available) {
      document.querySelector("#cart-cart-add-mobile-btn").disabled = true;
      document.querySelector("#cart-cart-add-btn").disabled = true;
    }

    if (value < props.available) {
      document.querySelector("#cart-cart-add-mobile-btn").disabled = false;
      document.querySelector("#cart-cart-add-btn").disabled = false;
    }
  }, [value, props.available]);

  const increaseValue = () => {
    if (value === props.available) {
      alert("You are trying to add more products than there are available.");
      return;
    }
    setValue(value + 1);
    props.add({
      src: props.img,
      name: props.name,
      tag: props.tag,
      rate: props.rate,
      price: props.price,
      id: props.id,
      quantity: 1,
      available: props.available,
    });
  };
  const decreaseValue = () => {
    setValue(value - 1);
    props.remove(props.id);
  };

  const deleteItem = (e) => {
    e.preventDefault();
    props.delete(props.id);
  };

  return (
    <>
      <div className="shopping-cart-single-product">
        <img src={props.src} alt="item" />

        <section className="name">
          <p>{props.name}</p>
          <p>{props.price}</p>
        </section>
        <section className="quantity">
          <button onClick={decreaseValue}>
            <MdKeyboardArrowLeft />
          </button>
          <p>{props.quantity}</p>
          <button id="cart-cart-add-btn" onClick={increaseValue}>
            <MdKeyboardArrowRight />
          </button>
        </section>
        <p className="total">${(priceNum * props.quantity).toFixed(2)}</p>
        <button onClick={deleteItem}>
          <ImCross />
        </button>
      </div>
      <div className="shopping-cart-single-product-mobile">
        <section className="name">
          <img src={props.src} alt="item" />
          <p>
            {props.name} &nbsp;{props.price}
          </p>
        </section>
        <section className="shopping-mobile">
          <section className="quantity">
            <button id="cart-cart-add-mobile-btn" onClick={increaseValue}>
              <MdKeyboardArrowUp />
            </button>
            <p>{value}</p>
            <button onClick={decreaseValue}>
              <MdKeyboardArrowDown />
            </button>
          </section>
          <p className="total">${(priceNum * props.quantity).toFixed(2)}</p>
          <button onClick={deleteItem}>
            <ImCross />
          </button>
        </section>
      </div>
    </>
  );
};
