import { configureStore } from "@reduxjs/toolkit";
import CartReducer from "../slices/cart";
import ProductReducer from "../slices/product";
import UserReducer from "../slices/user";
import AuthReducer from "../slices/auth";
import Searchreducer from "../slices/search";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import { getDefaultMiddleware } from "@reduxjs/toolkit";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  cart: CartReducer,
  product: ProductReducer,
  user: UserReducer,
  auth: AuthReducer,
  search: Searchreducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

const store = configureStore({
  reducer: persistedReducer,
  middleware: customizedMiddleware,
});

export default store;
