import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { getCategoryByStatus } from "../../services/category-service";

const EditSlides = (props) => {
  const item = props.item[0];
  let formValid = false;
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategoryByStatus("Y").then((data) => {
      setCategories(data.data.data.categories);
    });
  }, []);

  const handleSubmit = (e) => {
    var form = new FormData(document.getElementById("edit-slide-form"));
    var titleValue = form.get("edit-slide-title");
    var textValue = form.get("edit-slide-text");
    var categoryValue = form.get("edit-slide-category");
    var statusValue = form.get("edit-slide-status");
    // var fileValue = form.get("edit-slide-image").files[0];
    e.preventDefault();

    if (
      titleValue !== "" &&
      textValue !== "" &&
      categoryValue !== "" &&
      statusValue !== ""
    )
      formValid = true;

    if (!formValid) {
      alert("Please provide correct information");
      return;
    }

    props.handleEdit({
      categoryId: categoryValue,
      slideText: textValue,
      slideTitle: titleValue,
      slideStatus: statusValue,
    });
  };
  return (
    <div className="edit-product-container">
      <h5>
        {props.title}
        {item.slideId}
      </h5>
      <Form onSubmit={handleSubmit} id="edit-slide-form">
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-slide-label-title">Title</Form.Label>
          <Form.Control
            id="edit-slide-label-title"
            type="text"
            defaultValue={item.slideTitle}
            placeholder={item.slideTitle}
            name="edit-slide-title"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-slide-label-text">Text</Form.Label>
          <Form.Control
            id="edit-slide-label-text"
            type="text"
            defaultValue={item.slideText}
            placeholder={item.slideText}
            name="edit-slide-text"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-slide-label-category">Category</Form.Label>
          <Form.Select
            id="edit-slide-label-category"
            name="edit-slide-category"
          >
            {categories &&
              categories.map((item) => {
                return (
                  <option key={item.categoryId} value={item.categoryId}>
                    {item.categoryName}
                  </option>
                );
              })}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-slide-label-status">Status</Form.Label>
          <Form.Select id="edit-slide-label-status" name="edit-slide-status">
            <option value="Y">Y</option>
            <option value="N">N</option>
          </Form.Select>
        </Form.Group>
      </Form>
      <Button variant="secondary" onClick={props.closeModal}>
        Cancel
      </Button>
      <Button
        form="edit-slide-form"
        variant="primary"
        type="submit"
        onClick={handleSubmit}
      >
        Edit
      </Button>
    </div>
  );
};

export default EditSlides;
