import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router-dom";
import Home from "./app/pages/Home";
import UpperHeader from "./app/components/Header/UpperHeader";
import Header from "./app/components/Header/Header";
import Footer from "./app/components/Footer/Footer";
import Shop from "./app/pages/Shop";
import Contacts from "./app/pages/Contacts";
import ShopDetails from "./app/pages/ShopDetails";
import ShoppingCart from "./app/pages/ShoppingCart";
import CheckOut from "./app/pages/CheckOut";
import Modal from "./app/components/Modal/Modal";
import LoginForm from "./app/components/LoginForm/LoginForm";
import AdminPage from "./app/pages/Admin/AdminPage";
import RequireAuthAdmin from "./app/components/RequireAuth/RequireAuthAdmin";
import RequireAuthSeller from "./app/components/RequireAuth/RequireAuthSeller";
import RequireAuthSuperadmin from "./app/components/RequireAuth/RequireAuthSuperadmin";
import RequireAuthUser from "./app/components/RequireAuth/RequireAuthUser";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAvailableProducts } from "./app/services/product-service";
import SellerPage from "./app/pages/Admin/SellerPage";
import SuperAdminPage from "./app/pages/Admin/SuperAdminPage";
import NotFoundPage from "./app/components/NotFoundPage/NotFoundPage";
import Profile from "./app/components/Profile/Profile";
import { cartActions } from "./app/slices/cart";
import RequireAuthMyOrders from "./app/components/RequireAuth/RequireAuthMyOrders";
import Order from "./app/components/Profile/Order";
import { searchActions } from "./app/slices/search";

// const loader = document.querySelector(".loader");

// const showLoader = () => loader.classList.remove("loader--hide");

// const hideLoader = () => loader.classList.add("loader--hide");

function App() {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  const [authorized, setAuthorized] = useState(
    localStorage.access_token ? true : false
  );

  useEffect(() => {
    if (cart.items.length === 0) {
      dispatch(cartActions.resetTotalAmount());
    }
    dispatch(searchActions.resetValue());
    dispatch(searchActions.resetDropdown());
    dispatch(searchActions.resetHeaderDropdown());
  }, []);

  // useEffect(() => {
  //   if (authorized) {
  //     const token = localStorage.getItem("access_token");
  //     dispatch(getAccount(token));
  //   }
  // }, []);

  return (
    <div className="App">
      <UpperHeader />
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/shop" element={<Shop />} />
        <Route exact path="/contact" element={<Contacts />} />
        <Route
          exact
          path="/shop-details/:productId"
          element={<ShopDetails />}
        />
        <Route exact path="/shopping-cart" element={<ShoppingCart />} />
        <Route exact path="/check-out" element={<CheckOut />} />
        <Route exact path="/sign-in" element={<LoginForm />} />

        {/* we want to protect these routes */}
        <Route element={<RequireAuthAdmin />}>
          <Route path="/admin" element={<AdminPage />} />
        </Route>
        <Route element={<RequireAuthSuperadmin />}>
          <Route path="/superadmin" element={<SuperAdminPage />} />
        </Route>
        <Route element={<RequireAuthSeller />}>
          <Route path="/seller" element={<SellerPage />} />
        </Route>

        <Route element={<RequireAuthUser />}>
          <Route path="/profile" element={<Profile />} />
        </Route>

        <Route element={<RequireAuthMyOrders />}>
          <Route path="/orders" element={<Order />} />
        </Route>
        {/* catch all route ciji je path * - zvjezdica (404) */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
