import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    firstname: "",
    lastname: "",
    country: "",
    city: "",
    street: "",
    streetNumber: "",
    postal: "",
    password: "",
    email: "",
    phone: "",
    userId: "",
    status: "",
    address: "",
  },
  reducers: {
    setUserData: (state, action) => {
      state.firstname = action.payload.firstname;
      state.lastname = action.payload.lastname;
      state.country = action.payload.country;
      state.city = action.payload.city;
      state.street = action.payload.street;
      state.streetNumber = action.payload.streetNumber;
      state.postal = action.payload.postal;
      state.password = action.payload.password;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.userId = action.payload.userId;
      state.status = action.payload.status;
      state.address = action.payload.address;
    },
    resetUserData: (state, action) => {
      state.firstname = "";
      state.lastname = "";
      state.country = "";
      state.city = "";
      state.street = "";
      state.streetNumber = "";
      state.postal = "";
      state.password = "";
      state.email = "";
      state.phone = "";
      state.userId = "";
      state.status = "";
      state.address = "";
    },
    updateUserData: (state, action) => {
      state.firstname = action.payload.firstname;
      state.lastname = action.payload.lastname;
      state.country = action.payload.country;
      state.city = action.payload.city;
      state.street = action.payload.street;
      state.streetNumber = action.payload.streetNumber;
      state.postal = action.payload.postal;
      state.password = action.payload.password;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.userId = action.payload.userId;
      state.status = action.payload.status;
      state.address = action.payload.address;
    },
  },
  extraReducers: {},
});

export const { setUserData, resetUserData } = userSlice.actions;
export const userActions = userSlice.actions;
export default userSlice.reducer;
