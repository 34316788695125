import * as api from "../api/color";

export const getAllColors = async () => {
  try {
    const data = await api.getAllColors();
    return data;
  } catch (error) {
    console.log(error, "color-service getallcolors error");
    return error;
  }
};

export const createColor = async (newColor) => {
  try {
    const data = await api.createColor(newColor);
    return data;
  } catch (error) {
    console.log(error, "color-service createcolor error");
    return error;
  }
};

export const updateColor = async (colorId, updatedColor) => {
  try {
    const data = await api.updateColor(colorId, updatedColor);
    return data;
  } catch (error) {
    console.log(error, "color-service updatecolor error");
    return error;
  }
};

export const deleteColor = async (colorId, deletedColor) => {
  try {
    const data = await api.deleteColor(colorId, deletedColor);
    return data;
  } catch (error) {
    console.log(error, "color-service deletecolor error");
    return error;
  }
};
