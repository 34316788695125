import React from "react";
import { Form, Button } from "react-bootstrap";
import useValidate from "../../hooks/useValidate";
import { createCategory } from "../../services/category-service";
import { createColor } from "../../services/color-service";
import { createBrend } from "../../services/brend-service";

const NewValueAndId = (props) => {
  const isNotEmpty = (value) => value.trim().length > 0;
  //brend, kategorija, boja
  const {
    value: name,
    isValid: nameValid,
    hasError: nameError,
    valueBlurHandler: nameBlurHandler,
    valueChangedHandler: nameChangeHandler,
    reset: nameReset,
  } = useValidate(isNotEmpty);

  let formValid = false;

  if (nameValid) {
    formValid = true;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formValid) {
      alert("Please provide correct information");
      return;
    }

    if (props.title === "category") {
      createCategory(name);
    } else if (props.title === "brand") {
      createBrend(name);
    } else if (props.title === "color") {
      createColor(name);
    }

    nameReset();
  };

  return (
    <div className="add-new">
      <h5>Add new {props.title}</h5>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label htmlFor={`add-new-${props.title}-name`}>Name</Form.Label>
          <Form.Control
            id={`add-new-${props.title}-name`}
            type="text"
            placeholder="Name"
            value={name}
            onChange={nameChangeHandler}
            onBlur={nameBlurHandler}
          />
          {nameError && <p className="red-text">Name can't be empty</p>}
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default NewValueAndId;
