import React, { useEffect, useState } from "react";
import "./Header.scss";
import logo from "../../../assets/logo.png";
import { Link, NavLink } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import {
  AiOutlineSearch,
  AiOutlineHeart,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import Modal from "../Modal/Modal";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { getSearchOfProducts } from "../../services/product-service";
import { logoutUser } from "../../services/user-service";
import { userActions } from "../../slices/user";
import { searchActions } from "../../slices/search";
import { authActions } from "../../slices/auth";
import { useDispatch } from "react-redux";

const Header = () => {
  const searchRedux = useSelector((store) => store.search);
  const [searchValue, setSearchValue] = useState("");

  const [searchedProducts, setSearchedProducts] = useState([]);
  const cart = useSelector((state) => state.cart);
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value !== "") {
      getSearchOfProducts(e.target.value).then((data) => {
        setSearchedProducts(data.data.data.products);
      });
    } else {
      setSearchedProducts(null);
    }
  };

  const onSearchFocus = () => {
    dispatch(searchActions.setHeaderDropdown(true));
  };

  const onSearchBlur = () => {
    setTimeout(() => {
      dispatch(searchActions.resetHeaderDropdown());
    }, "200");
  };

  const searchHeader = (e) => {
    e.preventDefault();

    dispatch(searchActions.setValue(searchValue));
    dispatch(searchActions.resetHeaderDropdown());
    document.querySelector("#header-search-bar").value = "";
    document.querySelector("#mobile-header-search").value = "";
    setSearchedProducts(null);
    closeMobile("/shop");
    navigate("/shop");
  };

  useEffect(() => {}, [cart.totalAmount]);

  const closeMobile = (url) => {
    const primaryNav = document.querySelector(".mobile-nav");
    const navToggle = document.querySelector(".mobile-nav-toggle");
    primaryNav.style.display = "none";
    primaryNav.setAttribute("data-visible", false);
    navToggle.setAttribute("aria-expanded", false);
    navigate(url);
  };

  const handleClick = () => {
    const primaryNav = document.querySelector(".mobile-nav");
    const navToggle = document.querySelector(".mobile-nav-toggle");
    if (primaryNav.style.display === "flex") {
      primaryNav.style.display = "none";
      primaryNav.setAttribute("data-visible", false);
      navToggle.setAttribute("aria-expanded", false);
    } else {
      primaryNav.style.display = "flex";
      primaryNav.setAttribute("data-visible", true);
      navToggle.setAttribute("aria-expanded", true);
    }
  };

  const handleSignOut = (e) => {
    e.preventDefault();
    logoutUser(auth.accessToken).then(() => {
      dispatch(authActions.resetToken());
      dispatch(userActions.resetUserData());
      dispatch(authActions.resetRole());
      dispatch(authActions.resetId());
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      closeMobile("/");
      navigate("/");
    });
  };

  const handleCart = (e) => {
    e.preventDefault();
    closeMobile("/shopping-cart");
    navigate("/shopping-cart");
  };
  return (
    <>
      <div className="header-container container-padding">
        <section className="header-logo">
          <Link to={"/"}>
            <img src={logo} alt="logo" />
          </Link>
        </section>

        <section className="header-nav">
          <ul>
            <li>
              <NavLink
                className={(navData) => (navData.isActive ? "active-nav" : "")}
                to={"/"}
              >
                Home
              </NavLink>{" "}
            </li>
            <li>
              <NavLink
                className={(navData) => (navData.isActive ? "active-nav" : "")}
                to={"/shop"}
              >
                Shop
              </NavLink>
            </li>
            <li>
              <NavDropdown
                title="Pages"
                // id={`offcanvasNavbarDropdown-expand-${expand}`}
              >
                <NavDropdown.Item>
                  <Link to="/shopping-cart">Shopping Cart</Link>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item>
                  <Link to="/check-out">Check Out</Link>
                </NavDropdown.Item>
              </NavDropdown>
            </li>
            <li>
              <NavLink
                className={(navData) => (navData.isActive ? "active-nav" : "")}
                to={"/contact"}
              >
                Contacts
              </NavLink>
            </li>
          </ul>
        </section>
        <section className="header-icons">
          <section className="search-bar">
            <input
              type={"text"}
              placeholder="Search"
              onChange={onSearchChange}
              onFocus={onSearchFocus}
              onBlur={onSearchBlur}
              id="header-search-bar"
            />
            <button onClick={searchHeader}>
              <AiOutlineSearch size={26} />
            </button>

            {searchRedux.headerDropdown && searchValue && (
              <div className="header-search-response">
                {searchedProducts &&
                  searchedProducts.length !== 0 &&
                  searchedProducts.length < 5 &&
                  searchedProducts.map((item) => {
                    return (
                      <Link
                        to={`/shop-details/${item.productId}`}
                        className="search-suggestions"
                        key={item.productId}
                      >
                        {item.productName}
                      </Link>
                    );
                  })}

                {searchedProducts &&
                  searchedProducts.length > 5 &&
                  searchedProducts.slice(0, 5).map((item) => {
                    return (
                      <Link
                        to={`/shop-details/${item.productId}`}
                        className="search-suggestions"
                        key={item.productId}
                      >
                        {item.productName}
                      </Link>
                    );
                  })}
                {searchedProducts && searchedProducts.length === 0 && (
                  <p
                    className="search-suggestions"
                    style={{ fontSize: "0.9rem" }}
                  >
                    Not found
                  </p>
                )}
              </div>
            )}
          </section>

          <button
            className="header-cart"
            onClick={handleCart}
            style={{ display: "flex", alignItems: "center" }}
          >
            <AiOutlineShoppingCart size={26} />
            <p>${cart.totalAmount}</p>
          </button>
        </section>

        <button
          onClick={handleClick}
          className="mobile-nav-toggle"
          aria-controls="nav-list"
          aria-expanded="false"
        ></button>

        <div id="mobile-nav" className="mobile-nav" data-visible="false">
          <Link to="/" onClick={() => closeMobile(`/`)}>
            <img src={logo} alt="logo" />
          </Link>
          <section className="mobile-nav-buttons">
            {auth.accessToken && auth.role === "user" && (
              <>
                <button
                  onClick={handleSignOut}
                  style={{ minWidth: "max-content" }}
                >
                  Sign out
                </button>{" "}
                <Link to="/profile" onClick={() => closeMobile(`/profile`)}>
                  Profile
                </Link>
              </>
            )}
            {auth.accessToken && auth.role === "admin" && (
              <>
                <button
                  onClick={handleSignOut}
                  style={{ minWidth: "max-content" }}
                >
                  Sign out
                </button>{" "}
                <Link to="/admin" onClick={() => closeMobile(`/admin`)}>
                  Admin
                </Link>
              </>
            )}
            {auth.accessToken && auth.role === "superadmin" && (
              <>
                <button
                  onClick={handleSignOut}
                  style={{ minWidth: "max-content" }}
                >
                  Sign out
                </button>{" "}
                <Link
                  to="/superadmin"
                  onClick={() => closeMobile(`/superadmin`)}
                >
                  Superadmin
                </Link>
              </>
            )}
            {auth.accessToken && auth.role === "seller" && (
              <>
                <button
                  onClick={handleSignOut}
                  style={{ minWidth: "max-content" }}
                >
                  Sign out
                </button>{" "}
                <Link to="/seller" onClick={() => closeMobile(`/seller`)}>
                  Seller
                </Link>
              </>
            )}
            {!auth.accessToken && (
              <Link to={"/sign-in"} onClick={() => closeMobile(`/sign-in`)}>
                Sign in
              </Link>
            )}
            <Link to={"/faqs"} onClick={() => closeMobile(`/faqs`)}>
              Faqs
            </Link>
          </section>
          <section className="mobile-nav-icons">
            <section className="search-bar-mobile">
              <input
                id="mobile-header-search"
                type="text"
                placeholder="Search"
                onChange={onSearchChange}
              />
              <button className="header-search" onClick={searchHeader}>
                <AiOutlineSearch size={26} />
              </button>

              {searchValue && (
                <div className="header-search-response-mobile">
                  {searchedProducts &&
                    searchedProducts.length !== 0 &&
                    searchedProducts.length < 5 &&
                    searchedProducts.map((item) => {
                      return (
                        <Link
                          to={`/shop-details/${item.productId}`}
                          onClick={() =>
                            closeMobile(`/shop-details/${item.productId}`)
                          }
                          className="search-suggestions"
                          key={item.productId}
                        >
                          {item.productName}
                        </Link>
                      );
                    })}

                  {searchedProducts &&
                    searchedProducts.length > 5 &&
                    searchedProducts.slice(0, 5).map((item) => {
                      return (
                        <Link
                          to={`/shop-details/${item.productId}`}
                          onClick={() =>
                            closeMobile(`/shop-details/${item.productId}`)
                          }
                          className="search-suggestions"
                          key={item.productId}
                        >
                          {item.productName}
                        </Link>
                      );
                    })}
                  {searchedProducts && searchedProducts.length === 0 && (
                    <p
                      className="search-suggestions"
                      style={{ fontSize: "0.9rem" }}
                    >
                      Not found
                    </p>
                  )}
                </div>
              )}
            </section>

            <button className="header-cart" onClick={handleCart}>
              <AiOutlineShoppingCart size={26} />
            </button>
          </section>
          <p className="mobile-nav-price">${cart.totalAmount}</p>
          <section className="mobile-nav-items">
            <ul>
              <li>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "active-nav" : ""
                  }
                  to={"/"}
                  onClick={() => closeMobile(`/`)}
                >
                  Home
                </NavLink>{" "}
              </li>
              <li>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "active-nav" : ""
                  }
                  to={"/shop"}
                  onClick={() => closeMobile(`/shop`)}
                >
                  Shop
                </NavLink>
              </li>
              <li>
                <NavDropdown
                  title="Pages"
                  // id={`offcanvasNavbarDropdown-expand-${expand}`}
                >
                  <NavDropdown.Item>
                    <Link
                      to="/shopping-cart"
                      onClick={() => closeMobile(`/shopping-cart`)}
                    >
                      Shopping Cart
                    </Link>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item>
                    <Link
                      to="/check-out"
                      onClick={() => closeMobile(`/check-out`)}
                    >
                      Check Out
                    </Link>
                  </NavDropdown.Item>
                </NavDropdown>
              </li>
              <li>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "active-nav" : ""
                  }
                  to={"/contact"}
                  onClick={() => closeMobile(`/contact`)}
                >
                  Contacts
                </NavLink>
              </li>
            </ul>
          </section>
          <p className="mobile-nav-text">
            Free shipping, 30-day return or refund guarantee.
          </p>
        </div>
      </div>
    </>
  );
};

export default Header;
