import React, { useState, useEffect } from "react";
import "./ProductMonth.scss";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getMonthProduct } from "../../services/product-service";

const ProductMonth = (props) => {
  const [product, setProduct] = useState();

  // useEffect(() => {
  //   getMonthProduct().then((data) => {
  //     console.log(data);
  //   });
  // }, []);

  return (
    <div className="product-month-container container-padding">
      <Row>
        <Col lg={4} md={7} className="product-month-first-col">
          <h1 className="product-month-title">Product of the month</h1>
        </Col>
        <Col lg={4} md={7} className="product-month-second-col">
          <img src={props.img} alt="item" />
          <p>{props.text}</p>
        </Col>
        <Col lg={4} md={7} className="product-month-third-col">
          <p className="red-text">deal of the week</p>
          <h1 className="product-month-title">{props.name}</h1>
          <Link className="shop-btn" to={props.link}>
            Shop now
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default ProductMonth;
