import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import useValidate from "../../hooks/useValidate";
import { getCategoryByStatus } from "../../services/category-service";
import { createNewSlide } from "../../services/slide-service";

const NewSlide = () => {
  const isNotEmpty = (value) => value.trim().length > 0;
  const {
    value: title,
    isValid: titleValid,
    hasError: titleError,
    valueBlurHandler: titleBlurHandler,
    valueChangedHandler: titleChangeHandler,
    reset: titleReset,
  } = useValidate(isNotEmpty);

  const {
    value: text,
    isValid: textValid,
    hasError: textError,
    valueBlurHandler: textBlurHandler,
    valueChangedHandler: textChangeHandler,
    reset: textReset,
  } = useValidate(isNotEmpty);

  const [category, setCategory] = useState([]);

  useEffect(() => {
    getCategoryByStatus("Y").then((data) => {
      setCategory(data.data.data.categories);
    });
  }, []);

  let formValid = false;

  if (titleValid && textValid) {
    formValid = true;
  }

  const handleSubmit = (e) => {
    var form = new FormData(document.getElementById("add-slide-form"));
    var categoryValue = form.get("add-slide-category");
    e.preventDefault();

    if (!formValid) {
      alert("Please provide correct information");
      return;
    }

    createNewSlide({
      categoryId: categoryValue,
      slideText: text,
      slideTitle: title,
    });

    titleReset();
    textReset();
  };

  return (
    <div className="add-new">
      <h5>Add new slide</h5>
      <Form onSubmit={handleSubmit} id="add-slide-form">
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-slide-label-title">Title</Form.Label>
          <Form.Control
            id="add-slide-label-title"
            type="text"
            placeholder="Title"
            value={title}
            onChange={titleChangeHandler}
            onBlur={titleBlurHandler}
          />{" "}
          {titleError && <p className="red-text">Title can't be empty</p>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-slide-label-text">Text</Form.Label>
          <Form.Control
            id="add-slide-label-text"
            type="text"
            placeholder="Text"
            value={text}
            onChange={textChangeHandler}
            onBlur={textBlurHandler}
          />{" "}
          {textError && <p className="red-text">Text can't be empty</p>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-slide-label-category">
            Category Id
          </Form.Label>
          <Form.Select id="add-slide-label-category" name="add-slide-category">
            {category &&
              category.map((item) => {
                return (
                  <option key={item.categoryId} value={item.categoryId}>
                    {item.categoryName}
                  </option>
                );
              })}
          </Form.Select>
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default NewSlide;
