import React from "react";
import { Form, Button } from "react-bootstrap";
import useValidate from "../../hooks/useValidate";

const EditSeller = (props) => {
  const item = props.item;

  let formValid = false;

  const handleSubmit = (e) => {
    var form = new FormData(document.getElementById("edit-seller-form"));

    var statusValue = form.get("edit-seller-status");

    e.preventDefault();
    if (statusValue !== "") formValid = true;

    if (!formValid) {
      alert("Please fill out all of the fields");
      return;
    }

    //ovdje saljemo editovani objekat
    props.handleEdit({
      sellerName: item.sellerName,
      sellerDescription: item.sellerDescription,
      country: item.country,
      sellerStatus: statusValue,
      street: item.street,
      streetNumber: item.streetNumber,
      postal: item.postal,
      password: item.password,
      email: item.email,
      phone: item.phone,
      city: item.city,
    });
  };

  return (
    <div className="edit-product-container">
      <h5>Edit seller #{item[0].sellerId}</h5>
      <Form onSubmit={handleSubmit} id="edit-seller-form">
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-seller-label-status">Status</Form.Label>
          <Form.Select id="edit-seller-label-status" name="edit-seller-status">
            <option value="Y">Y</option>
            <option value="N">N</option>
          </Form.Select>
        </Form.Group>
      </Form>
      <Button variant="secondary" onClick={props.closeModal}>
        Cancel
      </Button>
      <Button
        form="edit-seller-form"
        variant="primary"
        type="submit"
        onClick={handleSubmit}
      >
        Edit
      </Button>
    </div>
  );
};

export default EditSeller;
