import React from "react";
import "./Modal.scss";
import { ImCross, ImSearch } from "react-icons/im";

const Modal = (props) => {
  const closeModal = () => {
    props.closeModal();
  };
  return (
    <>
      <div className="backdrop"></div>
      <div className="modal">
        <button onClick={closeModal}>
          <ImCross />
        </button>
        {!props.children && (
          <>
            <section>
              <input type="text" placeholder="Search here ..." />
              <button style={{ border: "0" }} onClick={props.onClick}>
                <ImSearch />
              </button>
            </section>
            <button className="invisible-button"></button>
          </>
        )}
        {props.children && props.children}
      </div>
    </>
  );
};

export default Modal;
