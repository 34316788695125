import * as api from "../api/brand";

export const getAllBrends = async () => {
  try {
    const data = await api.getAllBrends();
    return data;
  } catch (error) {
    console.log(error, "Brend-service getAllBrends error");
    return error;
  }
};

export const getSingleBrend = async (brendId) => {
  try {
    const data = await api.getSingleBrend(brendId);
    return data;
  } catch (error) {
    console.log(error, "brend-service getSingleBrend error");
    return error;
  }
};

export const createBrend = async (newBrend) => {
  try {
    const data = await api.createBrend(newBrend);
    return data;
  } catch (error) {
    console.log(error, "Brend-service createBrend error");
    return error;
  }
};

export const updateBrend = async (brendId, updatedBrend) => {
  try {
    const data = await api.updateBrend(brendId, updatedBrend);
    return data;
  } catch (error) {
    console.log(error, "Brend-service updateBrend error");
    return error;
  }
};

export const deleteBrend = async (brendId, deletedBrend) => {
  try {
    const data = await api.deleteBrend(brendId, deletedBrend);
    return data;
  } catch (error) {
    console.log(error, "Brend-service deleteBrend error");
    return error;
  }
};
