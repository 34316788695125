import React from "react";
import useValidate from "../../hooks/useValidate";
import { Form, Button } from "react-bootstrap";
import { registerWithRole } from "../../services/user-service";

const NewUser = (props) => {
  const isNotEmpty = (value) => value.trim().length > 0;

  const {
    value: firstName,
    isValid: firstNameValid,
    hasError: firstNameError,
    valueBlurHandler: firstNameBlurHandler,
    valueChangedHandler: firstNameChangeHandler,
    reset: firstNameReset,
  } = useValidate(isNotEmpty);
  const {
    value: lastName,
    isValid: lastNameValid,
    hasError: lastNameError,
    valueBlurHandler: lastNameBlurHandler,
    valueChangedHandler: lastNameChangeHandler,
    reset: lastNameReset,
  } = useValidate(isNotEmpty);
  const {
    value: country,
    isValid: countryValid,
    hasError: countryError,
    valueBlurHandler: countryBlurHandler,
    valueChangedHandler: countryChangeHandler,
    reset: countryReset,
  } = useValidate(isNotEmpty);
  const {
    value: city,
    isValid: cityValid,
    hasError: cityError,
    valueBlurHandler: cityBlurHandler,
    valueChangedHandler: cityChangeHandler,
    reset: cityReset,
  } = useValidate(isNotEmpty);
  const {
    value: street,
    isValid: streetValid,
    hasError: streetError,
    valueBlurHandler: streetBlurHandler,
    valueChangedHandler: streetChangeHandler,
    reset: streetReset,
  } = useValidate(isNotEmpty);
  const {
    value: streetNumber,
    isValid: streetNumberValid,
    hasError: streetNumberError,
    valueBlurHandler: streetNumberBlurHandler,
    valueChangedHandler: streetNumberChangeHandler,
    reset: streetNumberReset,
  } = useValidate(isNotEmpty);
  const {
    value: postal,
    isValid: postalValid,
    hasError: postalError,
    valueBlurHandler: postalBlurHandler,
    valueChangedHandler: postalChangeHandler,
    reset: postalReset,
  } = useValidate(isNotEmpty);
  const {
    value: password,
    isValid: passwordValid,
    hasError: passwordError,
    valueBlurHandler: passwordBlurHandler,
    valueChangedHandler: passwordChangeHandler,
    reset: passwordReset,
  } = useValidate(isNotEmpty);
  const {
    value: email,
    isValid: emailValid,
    hasError: emailError,
    valueBlurHandler: emailBlurHandler,
    valueChangedHandler: emailChangeHandler,
    reset: emailReset,
  } = useValidate(isNotEmpty);
  const {
    value: phone,
    isValid: phoneValid,
    hasError: phoneError,
    valueBlurHandler: phoneBlurHandler,
    valueChangedHandler: phoneChangeHandler,
    reset: phoneReset,
  } = useValidate(isNotEmpty);

  let formValid = false;

  if (
    firstNameValid &&
    lastNameValid &&
    countryValid &&
    cityValid &&
    streetValid &&
    streetNumberValid &&
    postalValid &&
    passwordValid &&
    emailValid &&
    phoneValid
  ) {
    formValid = true;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formValid) {
      alert("Please provide correct information");
      return;
    }

    registerWithRole({
      firstname: firstName,
      lastname: lastName,
      country: country,
      city: city,
      street: street,
      streetNumber: streetNumber,
      postal: postal,
      password: password,
      email: email,
      phone: phone,
      roleId: 1,
    });

    firstNameReset();
    lastNameReset();
    countryReset();
    cityReset();
    streetReset();
    streetNumberReset();
    postalReset();
    passwordReset();
    emailReset();
    phoneReset();
  };
  return (
    <div className="add-new">
      <h5>Add new {props.role}</h5>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-user-label-name">First Name</Form.Label>
          <Form.Control
            id="add-user-label-name"
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={firstNameChangeHandler}
            onBlur={firstNameBlurHandler}
          />
          {firstNameError && (
            <p className="red-text">First Name can't be empty</p>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-user-label-lastName">Last Name</Form.Label>
          <Form.Control
            id="add-user-label-lastName"
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={lastNameChangeHandler}
            onBlur={lastNameBlurHandler}
          />
          {lastNameError && (
            <p className="red-text">Last name can't be empty</p>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-user-label-country">Country</Form.Label>
          <Form.Control
            id="add-user-label-country"
            type="text"
            placeholder="Country"
            value={country}
            onChange={countryChangeHandler}
            onBlur={countryBlurHandler}
          />
          {countryError && <p className="red-text">Country can't be empty</p>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-user-label-city">City</Form.Label>
          <Form.Control
            id="add-user-label-city"
            type="text"
            placeholder="City"
            value={city}
            onChange={cityChangeHandler}
            onBlur={cityBlurHandler}
          />
          {cityError && <p className="red-text">City can't be empty</p>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-user-label-street">Street</Form.Label>
          <Form.Control
            id="add-user-label-street"
            type="text"
            placeholder="Street"
            value={street}
            onChange={streetChangeHandler}
            onBlur={streetBlurHandler}
          />
          {streetError && <p className="red-text">Street can't be empty</p>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-user-label-streetNum">
            Street Number
          </Form.Label>
          <Form.Control
            id="add-user-label-streetNum"
            type="text"
            placeholder="Street Number"
            value={streetNumber}
            onChange={streetNumberChangeHandler}
            onBlur={streetNumberBlurHandler}
          />
          {streetNumberError && (
            <p className="red-text">Street Number can't be empty</p>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-user-label-postal">Postal</Form.Label>
          <Form.Control
            id="add-user-label-postal"
            type="text"
            placeholder="Postal"
            value={postal}
            onChange={postalChangeHandler}
            onBlur={postalBlurHandler}
          />
          {postalError && <p className="red-text">Postal can't be empty</p>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-user-label-password">Password</Form.Label>
          <Form.Control
            id="add-user-label-password"
            type="password"
            placeholder="Password"
            value={password}
            onChange={passwordChangeHandler}
            onBlur={passwordBlurHandler}
          />
          {passwordError && <p className="red-text">Password can't be empty</p>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-user-label-email">Email</Form.Label>
          <Form.Control
            id="add-user-label-email"
            type="text"
            placeholder="Email"
            value={email}
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
          />
          {emailError && <p className="red-text">Email can't be empty</p>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-user-label-phone">Phone</Form.Label>
          <Form.Control
            id="add-user-label-phone"
            type="text"
            placeholder="Phone"
            value={phone}
            onChange={phoneChangeHandler}
            onBlur={phoneBlurHandler}
          />
          {phoneError && <p className="red-text">Phone can't be empty</p>}
        </Form.Group>
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default NewUser;
