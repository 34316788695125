import instance from "./instance";

const url = "https://dev.api.shopio.me/controller/subcategory";

export const getAllSubcategories = () => instance.get(url);

export const getSubcategoryById = (subcategoryId) =>
  instance.get(`${url}?subcategoryid=${subcategoryId}`);

export const getSubcategoryByCategory = (categoryId) =>
  instance.get(`${url}?categoryid=${categoryId}&subcategorystatus=Y`);

export const getSubcategoryByStatus = (subcategoryStatus) =>
  instance.get(`${url}?subcategorystatus=${subcategoryStatus}`);

export const createNewSubcategory = (newSubcategory) =>
  instance.post(url, {
    subcategoryName: newSubcategory.subcategoryName,
    categoryId: newSubcategory.categoryId,
  });

export const updateSubcategory = (subcategoryId, updatedSubcategory) =>
  instance.patch(`${url}?subcategoryid=${subcategoryId}`, {
    subcategoryName: updatedSubcategory.subcategoryName,
    subcategoryStatus: updatedSubcategory.subcategoryStatus,
  });

export const deleteSubcategory = (subcategoryId, deletedSubcategory) =>
  instance.patch(`${url}?subcategoryid=${subcategoryId}`, {
    subcategoryName: deletedSubcategory.name,
    subcategoryStatus: "N",
  });
