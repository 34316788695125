import React from "react";
import "./Profile.scss";
import { useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import { useState } from "react";
import { getOrderByUserId } from "../../services/order-service";
import { useEffect } from "react";

const Order = () => {
  const user = useSelector((state) => state.user);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    getOrderByUserId(user.userId).then((data) => {
      setOrders(data.data.data.orders);
    });
  }, []);
  return (
    <div className="order-container container-padding">
      <h3>All orders</h3>
      <Table responsive="xl">
        <thead>
          <tr>
            <th>id</th>
            <th>Order Status</th>
            <th>Products</th>
          </tr>
        </thead>
        <tbody>
          {orders &&
            orders.map((item, key) => {
              return (
                <tr key={key}>
                  <td>{item.orderId}</td>
                  <td>{item.orderStatus}</td>
                  <td>
                    {item.productList.map((item, key) => {
                      return (
                        <React.Fragment key={key}>
                          <section className="box-profile-order">
                            <h6 id="bold-text">Product {key + 1}</h6>
                          </section>
                          <section className="box-profile-order">
                            <h6 className="me-3">Product name </h6>
                            <p>{item.productName}</p>
                          </section>
                          <section className="box-profile-order">
                            <h6 className="me-3">Product quantity</h6>
                            <p>{item.quantity}</p>
                          </section>
                          <section className="box-profile-order">
                            <h6>Product price</h6>
                            <p>${item.soldPrice}</p>
                          </section>
                        </React.Fragment>
                      );
                    })}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default Order;
