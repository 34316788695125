import * as api from "../api/subcategory";

export const getAllSubcategories = async () => {
  try {
    const data = await api.getAllSubcategories();
    return data;
  } catch (error) {
    console.log(error, "subcategory-service all error");
    return error;
  }
};

export const getSubcategoryById = async (subcategoryId) => {
  try {
    const data = await api.getSubcategoryById(subcategoryId);
    return data;
  } catch (error) {
    console.log(error, "subcategory-service getbyid error");
    return error;
  }
};

export const getSubcategoryByCategory = async (categoryId) => {
  try {
    const data = await api.getSubcategoryByCategory(categoryId);
    return data;
  } catch (error) {
    console.log(error, "subcategory-service getbycategory error");
    return error;
  }
};

export const getSubcategoryByStatus = async (subcategoryStatus) => {
  try {
    const data = await api.getSubcategoryByStatus(subcategoryStatus);
    return data;
  } catch (error) {
    console.log(error, "subcategory-service getbystatus error");
    return error;
  }
};

export const createNewSubcategory = async (newSubcategory) => {
  try {
    const data = await api.createNewSubcategory(newSubcategory);
    return data;
  } catch (error) {
    console.log(error, "subcategory-service create error");
    return error;
  }
};

export const updateSubcategory = async (subcategoryId, updatedSubcategory) => {
  try {
    const data = await api.updateSubcategory(subcategoryId, updatedSubcategory);
    return data;
  } catch (error) {
    console.log(error, "subcategory-service update error");
    return error;
  }
};
export const deleteSubcategory = async (subcategoryId, deletedSubcategory) => {
  try {
    const data = await api.deleteSubcategory(subcategoryId, deletedSubcategory);
    return data;
  } catch (error) {
    console.log(error, "subcategory-service delete error");
    return error;
  }
};
