import instance from "./instance";

const url = "https://dev.api.shopio.me/controller/brand";

export const getAllBrends = () => instance.get(url);

export const createBrend = (newBrend) =>
  instance.post(url, { brandName: newBrend });

export const updateBrend = (brandId, updatedBrand) =>
  instance.patch(`${url}?brandid=${brandId}`, {
    brandName: updatedBrand.name,
    brandStatus: updatedBrand.status,
  });

//deleteBrand function doesn't actually delete a brand. It's purpose is to change the availabilty of that brand from Y to N. That's why it receives deletedbrand argument, that is a brand which has a changed property and needs to replace the existing one :)

export const deleteBrend = (brandId, deletedBrand) =>
  instance.patch(`${url}?brandid=${brandId}`, {
    brandName: deletedBrand.brandName,
    brandStatus: "N",
  });

export const getSingleBrend = (brandId) =>
  instance.get(`${url}?brandid=${brandId}`);

/** GET PATCH
        http://127.0.0.1:80/shop/controller/brand?brandid=VALUE    
        body{brandName: VALUE, brandStatus: Y|N}

        GET POST
        http://127.0.0.1:80/shop/controller/brand
        body{brandName: VALUE} */
