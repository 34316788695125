import React from "react";
import "./HomeCards.scss";
import { Link } from "react-router-dom";
import banner1 from "../../../assets/banner-1.jpg";
import banner2 from "../../../assets/banner-2.jpg";
import banner3 from "../../../assets/banner-3.jpg";
import { Col, Row } from "react-bootstrap";

const dummyData = {
  items: [
    {
      title: "Clothing Collections 2022",
      img: `${banner1}`,
      link: "/shop",
    },
    {
      title: "Accessories",
      img: `${banner2}`,
      link: "/shop",
    },
    {
      title: "Shoes Spring 2022",
      img: `${banner3}`,
      link: "/shop",
    },
  ],
};

const HomeCards = () => {
  return (
    <div className="home-cards-container container-padding">
      <Row>
        {dummyData.items.map((item) => {
          return (
            <CardInner
              key={item.img}
              img={item.img}
              title={item.title}
              link={item.link}
            />
          );
        })}
      </Row>
    </div>
  );
};

const CardInner = (props) => {
  return (
    <Col lg={4} md={7} className="card-inner-container home-banner">
      <img src={props.img} alt="img" />
      <section>
        <h4>{props.title}</h4>
        <Link to={props.link}>SHOP NOW</Link>
      </section>
    </Col>
  );
};

export default HomeCards;
