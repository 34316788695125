import React from "react";
import { Form, Button } from "react-bootstrap";

const EditCharacteristics = (props) => {
  const item = props.item[0];
  let formValid = false;

  const handleSubmit = (e) => {
    var form = new FormData(
      document.getElementById("edit-characteristic-form")
    );
    var nameValue = form.get("edit-characteristic-name");
    var statusValue = form.get("edit-characteristic-status");
    e.preventDefault();

    if (nameValue !== "" && statusValue !== "") formValid = true;

    if (!formValid) {
      alert("Please provide correct information");
      return;
    }
    props.handleEdit({
      characteristicName: nameValue,
      characteristicStatus: statusValue,
    });
  };
  return (
    <div className="edit-product-container">
      <h5>
        {props.title} &nbsp;
        {item.characteristicId}
      </h5>
      <Form onSubmit={handleSubmit} id="edit-characteristic-form">
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-characteristic-label-Name">Name</Form.Label>
          <Form.Control
            id="edit-characteristic-label-Name"
            type="text"
            defaultValue={item.characteristicName}
            placeholder={item.characteristicName}
            name="edit-characteristic-name"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-characteristic-label-status">
            Status
          </Form.Label>
          <Form.Select
            id="edit-characteristic-label-status"
            name="edit-characteristic-status"
          >
            <option value="Y">Y</option>
            <option value="N">N</option>
          </Form.Select>
        </Form.Group>
      </Form>
      <Button variant="secondary" onClick={props.closeModal}>
        Cancel
      </Button>
      <Button
        form="edit-characteristic-form"
        variant="primary"
        onClick={handleSubmit}
      >
        Edit
      </Button>
    </div>
  );
};

export default EditCharacteristics;
