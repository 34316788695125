import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { getAllBrends } from "../../services/brend-service";
import { getAllColors } from "../../services/color-service";

import { getSizeBySubcategoryId } from "../../services/size-service";

const EditProduct = (props) => {
  const item = props.item[0];
  let formValid = false;
  const [brands, setBrands] = useState([]);
  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [allSize, setAllSize] = useState([]);

  useEffect(() => {
    getAllBrends().then((data) => {
      setBrands(data.data.data.brands);
    });

    getAllColors().then((data) => {
      setColors(data.data.data.colors);
    });

    getSizeBySubcategoryId(item.subcategoryId).then((data) => {
      setSizes(data.data.data.sizes);
    });
  }, []);

  const handleSize = (e) => {
    setAllSize((sizes) =>
      sizes.includes(parseInt(e.target.id))
        ? sizes.filter((i) => i !== parseInt(e.target.id))
        : [parseInt(e.target.id), ...sizes]
    );
  };

  useEffect(() => {}, [allSize]);

  const handleSubmit = (e) => {
    var form = new FormData(document.getElementById("edit-product-form"));
    var nameValue = form.get("edit-product-name");
    var priceValue = form.get("edit-product-price");
    var subcategoryValue = form.get("edit-product-subcategory");
    var brandValue = form.get("edit-product-brands");
    var materialsValue = form.get("edit-product-materials");
    var careValue = form.get("edit-product-care");
    var colorValue = form.get("edit-product-color");
    var codeValue = form.get("edit-product-code");
    var quantityValue = form.get("edit-product-quantity");
    var statusValue = form.get("edit-product-status");
    var discountValue = form.get("edit-product-discount");

    e.preventDefault();

    if (
      nameValue !== "" &&
      priceValue !== "" &&
      subcategoryValue !== "" &&
      brandValue !== "" &&
      materialsValue !== "" &&
      careValue !== "" &&
      colorValue !== "" &&
      codeValue !== "" &&
      quantityValue !== "" &&
      statusValue !== "" &&
      discountValue !== "" &&
      allSize.length !== 0
    )
      formValid = true;

    if (!formValid) {
      alert("Please provide correct information");
      return;
    }

    props.editItem({
      productName: nameValue,
      productPrice: parseFloat(priceValue),
      brandId: parseInt(brandValue),
      productMaterials: materialsValue,
      productCare: careValue,
      productColor: parseInt(colorValue),
      productSize: allSize,
      productCode: codeValue,
      productStatus: statusValue,
      productDiscount: parseInt(discountValue),
      availableQuantity: parseInt(quantityValue),
    });
    props.closeModal();
  };
  return (
    <div className="edit-product-container">
      <h5>Edit product #{item.productId}</h5>
      <Form onSubmit={handleSubmit} id="edit-product-form">
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-product-label-name">Name</Form.Label>
          <Form.Control
            id="edit-product-label-name"
            type="text"
            placeholder={item.productName}
            defaultValue={item.productName}
            name="edit-product-name"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-product-label-price">Price</Form.Label>
          <Form.Control
            id="edit-product-label-price"
            type="number"
            min={0.1}
            step={0.1}
            placeholder={item.productPrice}
            defaultValue={item.productPrice}
            name="edit-product-price"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-product-label-brand">Brand</Form.Label>
          <Form.Select id="edit-product-label-brand" name="edit-product-brands">
            {brands &&
              brands.map((item) => {
                if (item.brandStatus === "Y")
                  return (
                    <option key={item.brandId} value={item.brandId}>
                      {item.brandName}
                    </option>
                  );
              })}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-product-label-materials">
            Product Materials
          </Form.Label>
          <Form.Control
            id="edit-product-label-materials"
            type="text"
            placeholder={item.productMaterials}
            defaultValue={item.productMaterials}
            name="edit-product-materials"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-product-label-care">
            Product Care
          </Form.Label>
          <Form.Control
            id="edit-product-label-care"
            type="text"
            placeholder={item.productCare}
            defaultValue={item.productCare}
            name="edit-product-care"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-product-label-color">
            Product Color
          </Form.Label>
          <Form.Select id="edit-product-label-color" name="edit-product-color">
            {colors &&
              colors.map((item) => {
                if (item.status === "Y")
                  return (
                    <option key={item.colorId} value={item.colorId}>
                      {item.colorName}
                    </option>
                  );
              })}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3 product-form-sizes">
          <Form.Label htmlFor="edit-product-label-sizes">
            Product Sizes
          </Form.Label>
          {sizes &&
            sizes.map((item) => {
              return (
                <Form.Check
                  key={item.sizeId}
                  type="checkbox"
                  label={item.sizeName}
                  id={item.sizeId}
                  name="edit-product-size"
                  onChange={handleSize}
                />
              );
            })}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-product-label-code">
            Product Code
          </Form.Label>
          <Form.Control
            id="edit-product-label-code"
            type="text"
            placeholder={item.productCode}
            defaultValue={item.productCode}
            name="edit-product-code"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-product-label-discount">
            Product Discount
          </Form.Label>
          <Form.Control
            id="edit-product-label-discount"
            type="text"
            placeholder={item.productDiscount ? item.productDiscount : 0}
            defaultValue={item.productDiscount ? item.productDiscount : 0}
            name="edit-product-discount"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-product-label-available">
            Available Quantity
          </Form.Label>
          <Form.Control
            id="edit-product-label-available"
            type="text"
            placeholder={item.availableQuantity}
            defaultValue={item.availableQuantity}
            name="edit-product-quantity"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-product-label-status">
            Product Status
          </Form.Label>
          <Form.Select
            id="edit-product-label-status"
            name="edit-product-status"
          >
            <option value="Y">Y</option>
            <option value="N">N</option>
          </Form.Select>
        </Form.Group>
      </Form>
      <Button variant="secondary" onClick={props.closeModal}>
        Cancel
      </Button>
      <Button
        form="edit-product-form"
        variant="primary"
        type="submit"
        onClick={handleSubmit}
      >
        Edit
      </Button>
    </div>
  );
};

export default EditProduct;
