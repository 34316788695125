import instance from "./instance";

const url = "https://dev.api.shopio.me/controller/color";

export const getAllColors = () => instance.get(url);

export const createColor = (newColor) =>
  instance.post(url, {
    colorName: newColor.colorName,
    colorValue: newColor.colorValue,
  });

export const updateColor = (colorId, updatedColor) =>
  instance.patch(`${url}?colorid=${colorId}`, {
    colorName: updatedColor.colorName,
    colorValue: updatedColor.colorValue,
    colorStatus: updatedColor.colorStatus,
  });

export const deleteColor = (colorId, deletedColor) =>
  instance.patch(`${url}?colorid=${colorId}`, {
    colorName: deletedColor.colorName,
    colorValue: deletedColor.colorValue,
    colorStatus: "N",
  });
