import React from "react";
import { Row, Col } from "react-bootstrap";
import "./ContactForm.scss";

const ContactForm = () => {
  return (
    <div className="contact-form-container container-padding">
      <Row>
        <Col md={6} className="contact-form-left-col">
          <h5 className="red-text">Information</h5>
          <h1>Contact Us</h1>
          <p>
            As you might expect of a company that began as a high-end interiors
            contractor, we pay strict attention.
          </p>
          <h4>America</h4>
          <p>195 E Parker Square Dr, Parker, CO 801</p>
          <p>+43 982-314-0958</p>
          <h4>France</h4>
          <p>109 Avenue Léon, 63 Clermont-Ferrand</p>
          <p>+12 345-423-9893</p>
        </Col>
        <Col md={6} className="contact-form-right-col">
          <form action="">
            <section className="contact-form-input-group">
              <input type="text" id="cotact-name" placeholder="Name" />
              <input
                type="text"
                name="email"
                id="contact-email"
                placeholder="Email"
              />
            </section>
            <textarea name="contact-message" id="contact-message"></textarea>
            <button className="shop-btn">Send message</button>
          </form>
        </Col>
      </Row>
    </div>
  );
};

export default ContactForm;
