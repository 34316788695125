import React from "react";
import "./UpperHeader.scss";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../../slices/auth";
import { userActions } from "../../slices/user";
import { logoutUser } from "../../services/user-service";
import { useNavigate } from "react-router";
const UpperHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  const handleSignOut = (e) => {
    e.preventDefault();
    logoutUser(auth.accessToken).then(() => {
      dispatch(authActions.resetToken());
      dispatch(userActions.resetUserData());
      dispatch(authActions.resetRole());
      dispatch(authActions.resetId());
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      navigate("/");
    });
  };

  return (
    <div className="upper-header-container container-padding">
      <p className="upper-header-text">
        Free shipping, 30-day return or refund guarantee.
      </p>
      <section className="upper-header-button-section">
        {auth.accessToken && auth.role === "user" && (
          <>
            <button onClick={handleSignOut} style={{ minWidth: "max-content" }}>
              Sign out
            </button>{" "}
            <Link to="/profile">Profile</Link>
          </>
        )}
        {auth.accessToken && auth.role === "admin" && (
          <>
            <button onClick={handleSignOut} style={{ minWidth: "max-content" }}>
              Sign out
            </button>{" "}
            <Link to="/admin">Admin</Link>
          </>
        )}
        {auth.accessToken && auth.role === "superadmin" && (
          <>
            <button onClick={handleSignOut} style={{ minWidth: "max-content" }}>
              Sign out
            </button>{" "}
            <Link to="/superadmin">Superadmin</Link>
          </>
        )}
        {auth.accessToken && auth.role === "seller" && (
          <>
            <button onClick={handleSignOut} style={{ minWidth: "max-content" }}>
              Sign out
            </button>{" "}
            <Link to="/seller">Seller</Link>
          </>
        )}
        {!auth.accessToken && <Link to={"/sign-in"}>Sign in</Link>}
        <Link to={"/faqs"}>Faqs</Link>
        {/* <select name="currency" id="currency">
          <option value="USD">USD</option>
          <option value="EUR">EUR</option>
        </select> */}
      </section>
    </div>
  );
};

export default UpperHeader;
