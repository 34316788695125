import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    accessToken: "",
    refreshToken: "",
    isLoggedIn: false,
    role: "",
    roleId: "",
    id: "",
  },
  reducers: {
    setToken: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.isLoggedIn = true;
    },
    resetToken: (state, action) => {
      state.accessToken = "";
      state.refreshToken = "";
      state.isLoggedIn = false;
    },
    setRole: (state, action) => {
      state.role = action.payload.role;
      state.roleId = action.payload.roleId;
    },
    resetRole: (state, action) => {
      state.role = "";
      state.roleId = "";
    },
    setId: (state, action) => {
      state.id = action.payload;
    },
    resetId: (state, action) => {
      state.id = "";
    },
  },
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
