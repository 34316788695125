import React from "react";
import ContactForm from "../components/ContactForm/ContactForm";
import MapComponent from "../components/MapComponent/MapComponent";

const Contacts = () => {
  return (
    <div className="contacts-container">
      <MapComponent />
      <ContactForm />
    </div>
  );
};

export default Contacts;
