import React from "react";
import CheckoutComponent from "../components/CheckOut/CheckOut";
import PagesNavList from "../components/PagesNavList/PagesNavList";

const CheckOut = () => {
  return (
    <div className="checkout-container">
      <PagesNavList
        num={3}
        items={["home", "shop", "check out"]}
        title="Check Out"
      />
      <CheckoutComponent />
    </div>
  );
};

export default CheckOut;
