import React from "react";

import { Form, Button } from "react-bootstrap";

const EditAdmin = (props) => {
  const item = props.item[0];

  let formValid = false;

  const handleSubmit = (e) => {
    var form = new FormData(document.getElementById("edit-admin-form"));
    var nameValue = form.get("edit-admin-name");
    var lastName = form.get("edit-admin-lastName");
    var countryValue = form.get("edit-admin-country");
    var cityValue = form.get("edit-admin-city");
    var streetValue = form.get("edit-admin-street");
    var streetNumValue = form.get("edit-admin-streetNum");
    var postalValue = form.get("edit-admin-postal");
    var emailValue = form.get("edit-admin-email");
    var phoneValue = form.get("edit-admin-phone");
    var statusValue = form.get("edit-admin-status");

    e.preventDefault();
    if (
      nameValue !== "" &&
      lastName !== "" &&
      countryValue !== "" &&
      cityValue !== "" &&
      streetValue !== "" &&
      streetNumValue !== "" &&
      postalValue !== "" &&
      emailValue !== "" &&
      phoneValue !== "" &&
      statusValue !== ""
    )
      formValid = true;

    if (!formValid) {
      alert("Please fill out all of the fields");
      return;
    }

    // ovdje saljemo editovani objekat
    props.handleEdit({
      firstname: nameValue,
      lastname: lastName,
      country: countryValue,
      userStatus: statusValue,
      city: cityValue,
      street: streetValue,
      streetNumber: streetNumValue,
      password: item.password,
      email: emailValue,
      phone: phoneValue,
    });
  };

  return (
    <div className={`edit-product-container ${props.className}`}>
      <h5>Edit #{item.userId}</h5>
      <Form onSubmit={handleSubmit} id="edit-admin-form">
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-admin-label-name">First Name</Form.Label>
          <Form.Control
            id="edit-admin-label-name"
            required
            name="edit-admin-name"
            type="text"
            defaultValue={item.firstname}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-admin-label-lastName">Last Name</Form.Label>
          <Form.Control
            id="edit-admin-label-lastName"
            type="text"
            name="edit-admin-lastName"
            placeholder={item.lastname}
            defaultValue={item.lastname}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-admin-label-country">Country</Form.Label>
          <Form.Control
            id="edit-admin-label-country"
            type="text"
            name="edit-admin-country"
            placeholder={item.country}
            defaultValue={item.country}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-admin-label-city">City</Form.Label>
          <Form.Control
            id="edit-admin-label-city"
            type="text"
            name="edit-admin-city"
            placeholder={item.city}
            defaultValue={item.city}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-admin-label-street">Street</Form.Label>
          <Form.Control
            id="edit-admin-label-street"
            type="text"
            name="edit-admin-street"
            placeholder={item.street}
            defaultValue={item.street}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-admin-label-streetNum">
            Street Number
          </Form.Label>
          <Form.Control
            id="edit-admin-label-streetNum"
            type="text"
            name="edit-admin-streetNum"
            placeholder={item.streetNumber}
            defaultValue={item.streetNumber}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-admin-label-postal">Postal</Form.Label>
          <Form.Control
            id="edit-admin-label-postal"
            type="text"
            name="edit-admin-postal"
            placeholder={item.postal}
            defaultValue={item.postal}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-admin-label-email">Email</Form.Label>
          <Form.Control
            id="edit-admin-label-email"
            type="text"
            name="edit-admin-email"
            placeholder={item.email}
            defaultValue={item.email}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-admin-label-phone">Phone</Form.Label>
          <Form.Control
            id="edit-admin-label-phone"
            type="text"
            name="edit-admin-phone"
            placeholder={item.phone}
            defaultValue={item.phone}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-admin-label-status">Status</Form.Label>
          <Form.Select id="edit-admin-label-status" name="edit-admin-status">
            <option value="Y">Y</option>
            <option value="N">N</option>
          </Form.Select>
        </Form.Group>
      </Form>
      <Button variant="secondary" onClick={props.closeModal}>
        Cancel
      </Button>
      <Button
        form="edit-admin-form"
        variant="primary"
        type="submit"
        onClick={handleSubmit}
      >
        Edit
      </Button>
    </div>
  );
};

export default EditAdmin;
