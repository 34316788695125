import React from "react";
import "./ConfirmationDelete.scss";

const ConfirmationDelete = (props) => {
  return (
    <div className="confirm-delete-container">
      <br />
      <p>Are you sure you want to delete?</p>

      <button className="btn btn-secondary" onClick={props.closeModal}>
        Cancel
      </button>
      <button className="btn btn-danger" onClick={props.deleteItem}>
        Delete
      </button>
    </div>
  );
};

export default ConfirmationDelete;
