import React, { useState, useEffect } from "react";
import "./ShopPaginate.scss";
import ReactPaginate from "react-paginate";
import { Row, Col } from "react-bootstrap";
import AddToCartButton from "../AddToCartButton/AddToCartButton";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { cartActions } from "../../slices/cart";
import { AiFillStar } from "react-icons/ai";
import { useNavigate } from "react-router";
import { getImage } from "../../services/image-service";

export const Items = (props) => {
  const [images, setImages] = useState([]);
  const handleAddToCart = (item) => {
    props.addCartItem(item);
  };

  return (
    <>
      {props.currentItems &&
        props.currentItems.map((item, i) => (
          <CardInner
            key={item.productId}
            quantity={0}
            availableQuantity={item.availableQuantity}
            brandId={item.brandId}
            img={item.images}
            tag={item.tag}
            name={item.productName}
            rate={item.rate}
            price={parseFloat(item.productPrice)}
            id={item.productId}
            addToCart={handleAddToCart}
            className="pages-animate shop-content-card"
            redirect={props.redirectDetails}
            handleModalCart={props.handleModalCart}
            discount={item.productDiscount}
          />
        ))}
    </>
  );
};

const Paginate = ({ products, itemsPerPage, setNumbers, handleModalCart }) => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const navigate = useNavigate();
  const redirectDetails = (id) => {
    navigate(`/shop-details/${id}`);
  };

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);
  const items = products;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
    setNumbers(
      itemOffset,
      endOffset > items.length ? items.length : endOffset,
      items.length
    );
  }, [itemOffset, itemsPerPage, items]);

  const handleAddToCart = (item) => {
    if (!cart.items.find((i) => i.id === item.id)) {
      dispatch(cartActions.addCartItem(item));
    } else {
      alert("Item already added to cart");
    }
  };
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;

    setItemOffset(newOffset);
  };

  if (products !== undefined && products.length !== 0)
    return (
      <>
        <Items
          redirectDetails={redirectDetails}
          currentItems={currentItems}
          addCartItem={handleAddToCart}
          handleModalCart={handleModalCart}
        />
        <ReactPaginate
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="< "
          renderOnZeroPageCount={null}
          className="react-paginate"
          activeLinkClassName="active-page"
        />
      </>
    );
  else if (products.length === 0) {
    return <p>No products found</p>;
  } else return <>Loading</>;
};

export default Paginate;

const CardInner = (props) => {
  return (
    <>
      <Col lg={4} md={6} className={props.className}>
        <img
          style={{ cursor: "pointer" }}
          src={
            props.img[0] === undefined
              ? ""
              : `https://dev.api.shopio.me/controller/image?imageid=${props.img[0].imageId}&productid=${props.id}`
          }
          alt="item"
          onClick={() => props.redirect(props.id)}
        />
        {props.tag !== "" && <p className="tag">{props.tag}</p>}
        <button
          className="add-to-cart-hover"
          onClick={() => props.handleModalCart(props.id)}
        >
          + Add to cart
        </button>
        <h5>{props.name}</h5>
        <section className="stars">{createStars(props.rate)}</section>
        <h4>${props.price}</h4>
        {/* <AddToCartButton onClick={createCartItem} /> */}
      </Col>
    </>
  );
};

function createStars(num) {
  if (num === 5)
    return (
      <>
        <AiFillStar fill="gold" size={16} />
        <AiFillStar fill="gold" size={16} />
        <AiFillStar fill="gold" size={16} />
        <AiFillStar fill="gold" size={16} />
        <AiFillStar fill="gold" size={16} />
      </>
    );

  if (num === 0) {
    return (
      <>
        <AiFillStar fill="grey" size={16} />
        <AiFillStar fill="grey" size={16} />
        <AiFillStar fill="grey" size={16} />
        <AiFillStar fill="grey" size={16} />
        <AiFillStar fill="grey" size={16} />
      </>
    );
  }

  let arr = [];
  for (let i = 0; i < num; i++) {
    arr.push(<AiFillStar fill="gold" size={16} />);
  }
  for (let j = 0; j < 5 - num; j++) {
    arr.push(<AiFillStar fill="grey" size={16} />);
  }

  return arr.map((item) => {
    return <>{item}</>;
  });
}
