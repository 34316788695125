import React from "react";
import "./Profile.scss";
import { useSelector } from "react-redux";
import { Button, Table } from "react-bootstrap";
import { useState } from "react";
import EditAdmin from "../AdminComponents/EditAdmin";
import { updateUser } from "../../services/user-service";
import { useDispatch } from "react-redux";
import { userActions } from "../../slices/user";
import { getOrderByUserId } from "../../services/order-service";
import { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Profile = () => {
  const user = useSelector((state) => state.user);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    getOrderByUserId(user.userId).then((data) => {
      setOrders(data.data.data.orders);
    });
  }, []);

  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const handleEdit = (e) => {
    setEdit(true);
  };

  const closeModal = () => {
    setEdit(false);
  };

  const editItem = (updatedItem) => {
    updateUser(user.userId, updatedItem).then((data) => {
      console.log(data);
      dispatch(userActions.updateUserData(data.data.data));
      setEdit(false);
    });
  };

  return (
    <div className="profile-container container-padding">
      {edit && (
        <EditAdmin
          item={[user]}
          title="Edit"
          closeModal={closeModal}
          handleEdit={editItem}
          className="edit-user-profile"
        />
      )}
      <Row className="wrap-profile">
        <Col lg={12} className="profile-col">
          <h3>My profile</h3>
          <section className="box-profile">
            <h5>First name</h5>
            <p>{user.firstname ? user.firstname : "Unknown"}</p>
          </section>
          <section className="box-profile">
            <h5>Last name</h5>
            <p>{user.lastname ? user.lastname : "Unknown"}</p>
          </section>
          <section className="box-profile">
            <h5>City</h5>
            <p>{user.city ? user.city : "Unknown"}</p>
          </section>
          <section className="box-profile">
            <h5>Country</h5>
            <p>{user.country ? user.country : "Unknown"}</p>
          </section>
          <section className="box-profile">
            <h5>Street</h5>
            <p>{user.street ? user.street : "Unknown"}</p>
          </section>
          <section className="box-profile">
            <h5>Email</h5>
            <p>{user.email ? user.email : "Unknown"}</p>
          </section>
          <section className="box-profile">
            <h5>Phone</h5>
            <p>{user.phone ? user.phone : "Unknown"}</p>
          </section>
          <section className="box-profile">
            <h5>Postal</h5>
            <p>{user.postal ? user.postal : "Unknown"}</p>
          </section>
          <section className="box-profile">
            <h5>Street Number</h5>
            <p>{user.streetNumber ? user.streetNumber : "Unknown"}</p>
          </section>
          <Button
            value={user.userId}
            onClick={handleEdit}
            className="mb-3 mt-3"
          >
            Change data
          </Button>
        </Col>
        <Col lg={12} className="profile-orders">
          <section className="display-flex">
            <h3>Recent orders</h3>
            <Link className="btn btn-primary" to="/orders">
              View all
            </Link>
          </section>
          <Table responsive="xl">
            <thead>
              <tr>
                <th>id</th>
                <th>Order Status</th>

                <th>Products</th>
              </tr>
            </thead>
            <tbody>
              {orders && orders.length < 5
                ? orders.map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>{item.orderId}</td>
                        <td>{item.orderStatus}</td>

                        <td>
                          {item.productList.map((item, key) => {
                            return (
                              <React.Fragment key={key}>
                                <section className="box-profile-order">
                                  <h6 id="bold-text">Product {key + 1}</h6>
                                </section>
                                <section className="box-profile-order">
                                  <h6 className="me-3">Product name </h6>
                                  <p>{item.productName}</p>
                                </section>
                                <section className="box-profile-order">
                                  <h6 className="me-3">Product quantity</h6>
                                  <p>{item.quantity}</p>
                                </section>
                                <section className="box-profile-order">
                                  <h6>Product price</h6>
                                  <p>${item.soldPrice}</p>
                                </section>
                              </React.Fragment>
                            );
                          })}
                        </td>
                      </tr>
                    );
                  })
                : orders.slice(0, 5).map((item, key) => {
                    return (
                      <tr key={key}>
                        <td>{item.orderId}</td>
                        <td>{item.orderStatus}</td>
                        <td>
                          {item.productList.map((item, key) => {
                            return (
                              <React.Fragment key={key}>
                                <section className="box-profile-order">
                                  <h6 id="bold-text">Product {key + 1}</h6>
                                </section>
                                <section className="box-profile-order">
                                  <h6 className="me-3">Product name </h6>
                                  <p>{item.productName}</p>
                                </section>
                                <section className="box-profile-order">
                                  <h6 className="me-3">Product quantity</h6>
                                  <p>{item.quantity}</p>
                                </section>
                                <section className="box-profile-order">
                                  <h6>Product price</h6>
                                  <p>${item.soldPrice}</p>
                                </section>
                              </React.Fragment>
                            );
                          })}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};

export default Profile;
