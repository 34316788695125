import React from "react";
import "./Loader.scss";
import { TailSpin } from "react-loader-spinner";

const Loader = () => {
  return (
    <div id="loader">
      <TailSpin color="black" />
    </div>
  );
};

export default Loader;
