import React from "react";
import "./Carousel.scss";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import hero1 from "../../../assets/hero-1.jpg";
import hero2 from "../../../assets/hero-2.jpg";
import {
  FaFacebookF,
  FaTwitter,
  FaPinterestP,
  FaInstagram,
} from "react-icons/fa";
import { BsArrowRightShort } from "react-icons/bs";

const CarouselComponent = () => {
  return (
    <Carousel data-touch="true" fade className="home-carousel">
      <Carousel.Item className="home-carousel-item">
        <img src={hero1} alt="First slide" />
        <Carousel.Caption>
          <section className="home-carousel-content">
            <p className="red-text">WOMEN</p>

            <section>
              <h1>Spring’s hottest accessory edit is here</h1>
              <p>
                The ultimate new-season accessories guide has landed, starring
                the artful bag, the supersized slides and Y2K-inspired details.
                Find your next dream piece now
              </p>
            </section>
            <Link className="shop-btn" to="/shop">
              Shop now <BsArrowRightShort size={24} />
            </Link>
            <section className="home-carosuel-socials">
              <a target="_blank" rel="noreferrer" href={"https://facebook.com"}>
                <FaFacebookF size={16} fill="#111" />
              </a>
              <a target="_blank" rel="noreferrer" href={"https://twitter.com"}>
                <FaTwitter size={16} fill="#111" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={"https://pinterest.com"}
              >
                <FaPinterestP size={16} fill="#111" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={"https://instagram.com"}
              >
                <FaInstagram size={16} fill="#111" />
              </a>
            </section>
          </section>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className="home-carousel-item">
        <img src={hero2} alt="Second slide" id="home-carousel-lower-image" />
        <Carousel.Caption>
          <section className="home-carousel-content">
            <p className="red-text">MEN</p>

            <section>
              <h1>The best new accessories: all here</h1>
              <p>
                The ultimate new-season accessories guide has landed, starring
                the artful bag, the supersized slides and Y2K-inspired details.
                Find your next dream piece now
              </p>
            </section>
            <Link className="shop-btn" to="/shop">
              Shop now <BsArrowRightShort size={24} />
            </Link>
            <section className="home-carosuel-socials">
              <a target="_blank" rel="noreferrer" href={"https://facebook.com"}>
                <FaFacebookF size={16} fill="#111" />
              </a>
              <a target="_blank" rel="noreferrer" href={"https://twitter.com"}>
                <FaTwitter size={16} fill="#111" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={"https://pinterest.com"}
              >
                <FaPinterestP size={16} fill="#111" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={"https://instagram.com"}
              >
                <FaInstagram size={16} fill="#111" />
              </a>
            </section>
          </section>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default CarouselComponent;
