import React, { useState } from "react";
import { useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { createImage } from "../../services/image-service";
import { getAllProducts } from "../../services/product-service";
import { getCategoryByStatus } from "../../services/category-service";
import { getAllSlides } from "../../services/slide-service";

const NewImage = () => {
  const [property, setProperty] = useState("category");
  const [category, setCategory] = useState([]);
  const [product, setProduct] = useState([]);
  const [slide, setSlide] = useState([]);

  useEffect(() => {
    if (property === "category") {
      getCategoryByStatus("Y").then((data) => {
        setCategory(data.data.data.categories);
      });
    } else if (property === "product") {
      getAllProducts().then((data) => {
        setProduct(data.data.data.products);
      });
    } else if (property === "slide") {
      getAllSlides().then((data) => {
        setSlide(data.data.data.slides);
      });
    }
  }, [property]);

  const handleProperty = (e) => {
    setProperty(e.target.value);
  };

  const handleSubmit = (e) => {
    var form = new FormData(document.getElementById("add-image-form"));
    var idValue = form.get("add-image-select");
    let images = document.querySelector("#image-files-value");
    e.preventDefault();
    let imagesList = images.files;
    let formDataImg = new FormData();
    let numOfImages = imagesList.length;

    for (let i = 0; i < numOfImages; i++) {
      formDataImg.append(`imagesList[]`, imagesList[i]);
    }

    createImage(formDataImg, property, idValue);
  };

  return (
    <div className="add-new">
      <h5>Add new image</h5>
      <Form
        encType="multipart/form-data"
        onSubmit={handleSubmit}
        className="new-image"
        id="add-image-form"
      >
        <Form.Group className="mb-3">
          <Form.Label>Choose Image</Form.Label>
          <Form.Control
            type="file"
            id="image-files-value"
            name="imagesList[]"
            multiple
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Choose For what</Form.Label>
          <Form.Select onChange={handleProperty}>
            <option value="category">Category</option>
            <option value="product">Product</option>
            <option value="slide">Slide</option>
          </Form.Select>
        </Form.Group>
        {property !== "" && (
          <Form.Group className="mb-3">
            <Form.Label>Choose {property}</Form.Label>
            <Form.Select name="add-image-select">
              {property === "category" &&
                category.map((item) => {
                  return (
                    <option key={item.categoryId} value={item.categoryId}>
                      {item.categoryName}
                    </option>
                  );
                })}
              {property === "product" &&
                product.map((item) => {
                  return (
                    <option key={item.productId} value={item.productId}>
                      {item.productName}
                    </option>
                  );
                })}
              {property === "slide" &&
                slide.map((item) => {
                  return (
                    <option key={item.slideId} value={item.slideId}>
                      {item.slideTitle}
                    </option>
                  );
                })}
            </Form.Select>
          </Form.Group>
        )}
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default NewImage;
