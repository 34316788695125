import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import ConfirmationDelete from "../ConfirmationDelete/ConfirmationDelete";
import ReactPaginate from "react-paginate";
import EditSlides from "./EditSlides";
import {
  getAllSlides,
  updatedSlide,
  deletedSlide,
} from "../../services/slide-service";
import Loader from "../Loader/Loader";

const AllSlides = (props) => {
  const [confirm, setConfirm] = useState(false);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);
  const [slides, setSlides] = useState([]);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);
  let items = slides;
  let itemForEdit;

  useEffect(() => {
    getAllSlides().then((data) => {
      setSlides(data.data.data.slides);
    });
  }, []);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + props.itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / props.itemsPerPage));
  }, [itemOffset, props.itemsPerPage, items]);

  useEffect(() => {
    if (edit === true) {
      const target = document.querySelector(".edit-product-container");
      if (target !== null) target.scrollIntoView({ behavior: "smooth" });
    } else {
      const target = document.querySelector(".wrapper-admin-table");
      if (target !== null) target.scrollIntoView({ behavior: "smooth" });
    }
  }, [edit, id]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * props.itemsPerPage) % items.length;

    setItemOffset(newOffset);
  };

  const closeModal = () => {
    setConfirm(false);
    setEdit(false);
    setId(null);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setId(e.target.id);
    setConfirm(true);
  };
  const deleteItem = () => {
    deletedSlide(id, itemForEdit).then((data) => {
      getAllSlides().then((data) => {
        setSlides(data.data.data.slides);
      });
      setConfirm(false);
      setId(null);
    });
  };
  const handleEdit = (e) => {
    setId(e.target.id);
    setEdit(true);
  };
  const editItem = (editedItem) => {
    updatedSlide(id, editedItem).then((data) => {
      getAllSlides().then((data) => {
        setSlides(data.data.data.slides);
      });
      setId(null);
      setEdit(false);
    });
  };

  if (id) itemForEdit = items.filter((x) => `${x.slideId}` === `${id}`);

  if (slides.length !== 0)
    return (
      <div style={{ position: "relative" }} className="wrapper-admin-table">
        {confirm && (
          <ConfirmationDelete closeModal={closeModal} deleteItem={deleteItem} />
        )}
        <h4>All Slides</h4>
        <Table responsive="xl" className="table-all-products">
          <thead>
            <tr>
              <th>id</th>
              <th>Title</th>
              <th>Text</th>
              <th>Category Id</th>
              <th>Image Id</th>
              <th>Slide status</th>
            </tr>
          </thead>
          <Items
            currentItems={currentItems}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        </Table>
        <ReactPaginate
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="< "
          renderOnZeroPageCount={null}
          className="react-paginate"
          activeLinkClassName="active-page"
        />
        {edit && (
          <EditSlides
            title="Edit slide #"
            closeModal={closeModal}
            handleEdit={editItem}
            item={itemForEdit}
            link={props.link}
          />
        )}
      </div>
    );
  else
    return (
      <div
        style={{ position: "relative", minHeight: "200px" }}
        className="wrapper-admin-table"
      >
        <Loader />
      </div>
    );
};

export default AllSlides;

const Items = (props) => {
  return (
    <tbody>
      {props.currentItems &&
        props.currentItems.map((item) => {
          return (
            <tr key={item.slideId}>
              <td>{item.slideId}</td>
              <td>{item.slideTitle}</td>
              <td>{item.slideText}</td>
              <td>{item.category.categoryId}</td>
              <td>{item.image.imageId}</td>
              <td>{item.slideStatus}</td>
              <td>
                <Button onClick={props.handleEdit} id={item.slideId}>
                  Edit
                </Button>
              </td>
              {item.slideStatus === "Y" && (
                <td>
                  <Button
                    variant="danger"
                    onClick={props.handleDelete}
                    id={item.slideId}
                  >
                    Delete
                  </Button>
                </td>
              )}
            </tr>
          );
        })}
    </tbody>
  );
};
