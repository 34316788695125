import React from "react";
import { Row, Col } from "react-bootstrap";
import "./Footer.scss";
import logo from "../../../assets/footer-logo.png";
import { Link } from "react-router-dom";
import { GoMail } from "react-icons/go";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCcVisa,
  faCcMastercard,
  faCcPaypal,
  faCcAmex,
  faBtc,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div className="footer-container container-padding">
      <Row>
        <Col md={6} lg={3} className="footer-first-col">
          <img src={logo} alt="logo" />
          <p className="footer-text">
            The customer is at the heart of our unique busines model, which
            includes design
          </p>
          <section className="footer-credit-cards">
            <Link to="/">
              <FontAwesomeIcon icon={faBtc} color="#fff" className="fa-xl" />
            </Link>
            <Link to="/">
              <FontAwesomeIcon icon={faCcAmex} color="#fff" className="fa-xl" />
            </Link>
            <Link to="/">
              <FontAwesomeIcon
                icon={faCcPaypal}
                color="#fff"
                className="fa-xl"
              />
            </Link>
            <Link to="/">
              <FontAwesomeIcon
                icon={faCcMastercard}
                color="#fff"
                className="fa-xl"
              />
            </Link>
            <Link to="/">
              <FontAwesomeIcon icon={faCcVisa} color="#fff" className="fa-xl" />
            </Link>
          </section>
        </Col>
        <Col md={6} lg={3} className="footer-second-col">
          <h4 className="footer-title">Shopping</h4>
          <ul className="footer-list">
            <li className="footer-list-item">
              <Link to="/">Clothing Store</Link>
            </li>
            <li className="footer-list-item">
              <Link to="/">Trending shoes</Link>
            </li>
            <li className="footer-list-item">
              <Link to="/">Accessories</Link>
            </li>
            <li className="footer-list-item">
              <Link to="/">Sale</Link>
            </li>
          </ul>
        </Col>
        <Col md={6} lg={3} className="footer-third-col">
          <h4 className="footer-title">Shopping</h4>
          <ul className="footer-list">
            <li className="footer-list-item">
              <Link to="/">Contact us</Link>
            </li>
            <li className="footer-list-item">
              <Link to="/">Payment methods</Link>
            </li>
            <li className="footer-list-item">
              <Link to="/">Delivery</Link>
            </li>
            <li className="footer-list-item">
              <Link to="/">Return &#38; exchanges</Link>
            </li>
          </ul>
        </Col>
        <Col md={6} lg={3} className="footer-newsletter">
          <h4 className="footer-title">Newsletter</h4>
          <p className="footer-text">
            Be the first to know about new arrivals, look books, sales &#38;
            promos!
          </p>
          <section className="footer-input-section">
            <input type="text" className="footer-input" />
            <button className="footer-btn">
              <GoMail size={20} fill="#fff" />
            </button>
          </section>
        </Col>
      </Row>
      <p className="footer-copyright">
        Copyright &#169; 2022 All rights reserved | This temlate is made with
        &#10084; by <span className="red-text">Colorlib</span>
      </p>
    </div>
  );
};

export default Footer;
