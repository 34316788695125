import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./CheckOut.scss";
import { RiCoupon3Line } from "react-icons/ri";
import useValidate from "../../hooks/useValidate";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createNewOrder } from "../../services/order-service";
import { cartActions } from "../../slices/cart";
import { registerUser } from "../../services/user-service";

const CheckOut = () => {
  const [inputCoupon, setInputCoupon] = useState(false);
  const [account, setAccount] = useState(false);

  const cart = useSelector((state) => state.cart);
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCoupon = (e) => {
    e.preventDefault();
    setInputCoupon(true);
  };

  const isLongerThanTwoChars = (value) => value.trim().length > 2;
  let re = /\S+@\S+\.\S+/;
  const isEmail = (value) => re.test(value);
  const isPasswordRequired = (value) => {
    if (account) {
      return value.length > 0;
    } else {
      return true;
    }
  };

  const isTrue = (value) => {
    if (value) return true;
  };

  const handleCreateAccount = () => {
    setAccount(!account);
  };

  const {
    value: firstName,
    isValid: firstNameValid,
    hasError: firstNameError,
    valueBlurHandler: firstNameBlurHandler,
    valueChangedHandler: firstNameChangeHandler,
    reset: firstNameReset,
  } = useValidate(isLongerThanTwoChars);
  const {
    value: lastName,
    isValid: lastNameValid,
    hasError: lastNameError,
    valueBlurHandler: lastNameBlurHandler,
    valueChangedHandler: lastNameChangeHandler,
    reset: lastNameReset,
  } = useValidate(isLongerThanTwoChars);
  const {
    value: address,
    isValid: addressValid,
    hasError: addressError,
    valueBlurHandler: addressBlurHandler,
    valueChangedHandler: addressChangeHandler,
    reset: addressReset,
  } = useValidate(isLongerThanTwoChars);
  const {
    value: city,
    isValid: cityValid,
    hasError: cityError,
    valueBlurHandler: cityBlurHandler,
    valueChangedHandler: cityChangeHandler,
    reset: cityReset,
  } = useValidate(isLongerThanTwoChars);
  const {
    value: country,
    isValid: countryValid,
    hasError: countryError,
    valueBlurHandler: countryBlurHandler,
    valueChangedHandler: countryChangeHandler,
    reset: countryReset,
  } = useValidate(isLongerThanTwoChars);
  const {
    value: postcode,
    isValid: postcodeValid,
    hasError: postcodeError,
    valueBlurHandler: postcodeBlurHandler,
    valueChangedHandler: postcodeChangeHandler,
    reset: postcodeReset,
  } = useValidate(isLongerThanTwoChars);
  const {
    value: phone,
    isValid: phoneValid,
    hasError: phoneError,
    valueBlurHandler: phoneBlurHandler,
    valueChangedHandler: phoneChangeHandler,
    reset: phoneReset,
  } = useValidate(isLongerThanTwoChars);
  const {
    value: email,
    isValid: emailValid,
    hasError: emailError,
    valueBlurHandler: emailBlurHandler,
    valueChangedHandler: emailChangeHandler,
    reset: emailReset,
  } = useValidate(isEmail);
  const {
    value: password,
    isValid: passwordValid,
    hasError: passwordError,
    valueBlurHandler: passwordBlurHandler,
    valueChangedHandler: passwordChangeHandler,
    reset: passwordReset,
  } = useValidate(isPasswordRequired);
  const {
    value: orderNotes,
    isValid: orderNotesValid,
    hasError: orderNotesError,
    valueBlurHandler: orderNotesBlurHandler,
    valueChangedHandler: orderNotesChangeHandler,
    reset: orderNotesReset,
  } = useValidate(isTrue);
  const {
    value: streetNumber,
    isValid: streetNumberValid,
    hasError: streetNumberError,
    valueBlurHandler: streetNumberBlurHandler,
    valueChangedHandler: streetNumberChangeHandler,
    reset: streetNumberReset,
  } = useValidate(isLongerThanTwoChars);

  let formValid = false;

  if (auth.role === "") {
    if (
      firstNameValid &&
      lastNameValid &&
      addressValid &&
      cityValid &&
      countryValid &&
      postcodeValid &&
      phoneValid &&
      emailValid &&
      streetNumber &&
      orderNotesValid &&
      streetNumberValid &&
      passwordValid
    ) {
      formValid = true;
    }
  } else {
    if (orderNotesValid) formValid = true;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formValid) {
      alert("Please provide correct information");
      return;
    }

    //if the coupon code is used insert it here
    //if the create an account checkbox is checked then password is required otherwise it's not

    if (auth.role !== "") {
      createNewOrder({
        orderNotes: orderNotes,
        productList: cart.items.map((i) => {
          return {
            productId: i.id,
            quantity: i.quantity,
            // sizeId: i.sizeId,
          };
        }),
      }).then((data) => {
        dispatch(cartActions.resetCart());
      });
    } else if (auth.role === "" && !account) {
      createNewOrder({
        firstname: firstName,
        lastname: lastName,
        country: country,
        city: city,
        street: address,
        streetNumber: streetNumber,
        email: email,
        phone: phone,
        postal: postcode,
        orderNotes: orderNotes,
        productList: cart.items.map((i) => {
          return {
            productId: i.id,
            quantity: i.quantity,
            // sizeId: i.sizeId,
          };
        }),
      }).then((data) => {
        dispatch(cartActions.resetCart());
      });
    } else if (auth.role === "" && account) {
      createNewOrder({
        firstname: firstName,
        lastname: lastName,
        country: country,
        city: city,
        street: address,
        streetNumber: streetNumber,
        email: email,
        phone: phone,
        postal: postcode,
        orderNotes: orderNotes,
        productList: cart.items.map((i) => {
          return {
            productId: i.id,
            quantity: i.quantity,
            // sizeId: i.sizeId,
          };
        }),
      }).then((data) => {
        // odradi registraciju
        registerUser({
          firstname: firstName,
          lastname: lastName,
          country: country,
          city: city,
          street: address,
          streetNumber: streetNumber,
          postal: postcode,
          password: password,
          email: email,
          phone: phone,
        }).then((data) => {
          dispatch(cartActions.resetCart());
        });
      });
    }

    console.log(
      firstName,
      lastName,
      address,
      city,
      streetNumber,
      country,
      postcode,
      phone,
      email,
      password,
      orderNotes
    );

    firstNameReset();
    lastNameReset();
    addressReset();
    cityReset();
    countryReset();
    postcodeReset();
    phoneReset();
    emailReset();
    streetNumberReset();
    orderNotesReset();
    passwordReset();
  };

  return (
    <div className="checkout-component-container container-padding">
      {cart.items.length !== 0 && (
        <Row>
          <Col lg={8}>
            <section className="checkout-coupon">
              {!inputCoupon && (
                <p>
                  <RiCoupon3Line /> Have a coupon? &nbsp;
                  <button onClick={handleCoupon}> Click here</button> to enter
                  your code.
                </p>
              )}
              {inputCoupon && (
                <section className="checkout-form-input-coupon">
                  <input
                    type="text"
                    required
                    placeholder="Type your code here"
                  />
                </section>
              )}
            </section>
            <h5>Billing Details</h5>
            <hr />
            <form
              action=""
              className="checkout-form"
              id="checkout-form"
              onSubmit={handleSubmit}
            >
              {auth.role === "" && (
                <>
                  <section className="checkout-form-double">
                    <section className="checkout-form-item">
                      <label htmlFor="firstName">
                        First Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        id="firstName"
                        required
                        value={firstName}
                        onChange={firstNameChangeHandler}
                        onBlur={firstNameBlurHandler}
                      />
                      <div className="error-txt register">
                        {firstNameError ? "First name is required." : ""}
                      </div>
                    </section>
                    <section className="checkout-form-item">
                      <label htmlFor="lastName">
                        Last Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        id="lastName"
                        required
                        value={lastName}
                        onChange={lastNameChangeHandler}
                        onBlur={lastNameBlurHandler}
                      />
                      <div className="error-txt register">
                        {lastNameError ? "Last name is required." : ""}
                      </div>
                    </section>
                  </section>

                  <section className="checkout-form-item">
                    <label htmlFor="address">
                      Address <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="address"
                      required
                      placeholder="Street Address"
                      value={address}
                      onChange={addressChangeHandler}
                      onBlur={addressBlurHandler}
                    />
                    <div className="error-txt register">
                      {addressError ? "Address is required. " : ""}
                    </div>
                    <input
                      type="text"
                      placeholder="Street Number"
                      value={streetNumber}
                      onChange={streetNumberChangeHandler}
                      onBlur={streetNumberBlurHandler}
                    />
                    <div className="error-txt register">
                      {streetNumberError ? " Street number is required." : ""}
                    </div>
                  </section>
                  <section className="checkout-form-item">
                    <label htmlFor="town">
                      Town/City <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="town"
                      required
                      value={city}
                      onChange={cityChangeHandler}
                      onBlur={cityBlurHandler}
                    />
                    <div className="error-txt register">
                      {cityError ? "City is required." : ""}
                    </div>
                  </section>
                  <section className="checkout-form-item">
                    <label htmlFor="country">
                      Country/State <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="country"
                      required
                      value={country}
                      onChange={countryChangeHandler}
                      onBlur={countryBlurHandler}
                    />
                    <div className="error-txt register">
                      {countryError ? "Country is required." : ""}
                    </div>
                  </section>
                  <section className="checkout-form-item">
                    <label htmlFor="postcode">
                      Postcode/ZIP <span>*</span>
                    </label>
                    <input
                      type="text"
                      id="postcode"
                      required
                      value={postcode}
                      onChange={postcodeChangeHandler}
                      onBlur={postcodeBlurHandler}
                    />
                    <div className="error-txt register">
                      {postcodeError ? "Postcode is required." : ""}
                    </div>
                  </section>
                  <section className="checkout-form-double">
                    <section className="checkout-form-item">
                      <label htmlFor="phone">
                        Phone <span>*</span>
                      </label>
                      <input
                        type="text"
                        id="phone"
                        required
                        value={phone}
                        onChange={phoneChangeHandler}
                        onBlur={phoneBlurHandler}
                      />
                      <div className="error-txt register">
                        {phoneError ? "Phone is required." : ""}
                      </div>
                    </section>
                    <section className="checkout-form-item">
                      <label htmlFor="email">
                        Email <span>*</span>
                      </label>
                      <input
                        type="text"
                        id="email"
                        required
                        value={email}
                        onChange={emailChangeHandler}
                        onBlur={emailBlurHandler}
                      />
                      <div className="error-txt register">
                        {emailError ? "Email is required." : ""}
                      </div>
                    </section>
                  </section>

                  <>
                    <section className="checkout-form-checkbox">
                      <input
                        type="checkbox"
                        name="create-account"
                        id="create-account"
                        onClick={handleCreateAccount}
                      />
                      <label htmlFor="create-account">Create an account?</label>
                    </section>
                    <p>
                      Create an account by entering the information below. If
                      you are a returning customer please login at the top of
                      the page
                    </p>
                    <section className="checkout-form-item">
                      <label htmlFor="account-password">
                        Account Password <span>*</span>
                      </label>
                      <input
                        type="password"
                        name="account-password"
                        id="account-password"
                        value={password}
                        onChange={passwordChangeHandler}
                        onBlur={passwordBlurHandler}
                      />
                      <div className="error-txt register">
                        {passwordError ? "Password is required." : ""}
                      </div>
                    </section>
                  </>
                </>
              )}
              <section className="checkout-form-item">
                <label htmlFor="order-notes">Order Notes</label>
                <input
                  type="text"
                  id="order-notes"
                  placeholder="Note about your order, e.g, special noe for delivery"
                  value={orderNotes}
                  onChange={orderNotesChangeHandler}
                  onBlur={orderNotesBlurHandler}
                />
                <div className="error-txt register">
                  {orderNotesError ? "Order notes is required." : ""}
                </div>
              </section>
            </form>
          </Col>
          <Col lg={4} className="checkout-form-order-segment">
            <h3>Your order</h3>
            <hr />
            <section className="checkout-order-item">
              <h5>Product</h5>
              <h5>Price</h5>
            </section>
            <ul>
              {cart.items.map((item) => {
                return (
                  <li>
                    <section className="checkout-order-item">
                      <p>{item.name}</p>
                      <p>
                        {item.price} x {item.quantity}
                      </p>
                    </section>
                  </li>
                );
              })}
            </ul>
            <hr />
            <section className="checkout-order-item">
              <p>Subtotal</p>
              <p className="red-text">{cart.totalAmount.toFixed(2)}</p>
            </section>
            <section className="checkout-order-item">
              <p>Total</p>
              <p className="red-text">{cart.totalAmount.toFixed(2)}</p>
            </section>
            <hr />
            <section className="checkout-form-checkbox">
              <input
                type="checkbox"
                name="create-account-order"
                id="create-account-order"
              />
              <label htmlFor="create-account-order">Create an account?</label>
            </section>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident
              dolorum corrupti a!
            </p>
            <section className="checkout-form-checkbox">
              <input type="checkbox" name="check-payment" id="check-payment" />
              <label htmlFor="check-payment">Check payment</label>
            </section>
            <section className="checkout-form-checkbox">
              <input type="checkbox" name="paypal" id="paypal" />
              <label htmlFor="paypal">Paypal</label>
            </section>
            <button className="shop-btn checkout-form-btn" form="checkout-form">
              Place order
            </button>
          </Col>
        </Row>
      )}
      {cart.items.length === 0 && (
        <div className="empty-cart">
          <p>Your shopping cart is empty!</p>
          <Link to="/shop" className="shop-btn">
            Go shopping
          </Link>
        </div>
      )}
    </div>
  );
};

export default CheckOut;
