import * as api from "../api/order";

export const getOrder = async () => {
  try {
    const data = await api.getOrder();
    return data;
  } catch (error) {
    console.log(error, "order-service getorder error");
    return error;
  }
};

export const getOrderById = async (orderId) => {
  try {
    const data = await api.getOrderById(orderId);
    return data;
  } catch (error) {
    console.log(error, "order-service getorderbyid error");
    return error;
  }
};

export const getOrderByUserId = async (userId) => {
  try {
    const data = await api.getOrderByUserId(userId);
    return data;
  } catch (error) {
    console.log(error, "order-service getorderuserid error");
    return error;
  }
};

export const getOrderBySellerId = async (sellerId) => {
  try {
    const data = await api.getOrderBySellerId(sellerId);
    return data;
  } catch (error) {
    console.log(error, "order-service getordersellerid error");
    return error;
  }
};

export const getFilteredOrder = async (byWhatFilter, filterValue) => {
  try {
    const data = await api.getFilteredOrder(byWhatFilter, filterValue);
    return data;
  } catch (error) {
    console.log(error, "order-service getfiltered error");
    return error;
  }
};

export const updateOrderStatus = async (id, orderStatus) => {
  try {
    const data = await api.updateOrderStatus(id, orderStatus);
    return data;
  } catch (error) {
    console.log(error, "order-service updatestatus error");
    return error;
  }
};

export const createNewOrder = async (order) => {
  try {
    const data = await api.createNewOrder(order);
    return data;
  } catch (error) {
    console.log(error, "order-service createorder error");
    return error;
  }
};
