import instance from "./instance";

const url = "https://dev.api.shopio.me/controller/size";

export const getAllSizes = () => instance.get(url);

export const getSizeById = (sizeId) => instance.get(`${url}?sizeid=${sizeId}`);

export const getSizeBySubcategoryId = (subcategoryId) =>
  instance.get(`${url}?subcategoryid=${subcategoryId}`);

export const createNewSize = (newSize) =>
  instance.post(url, {
    sizeName: newSize.sizeName,
    subcategoryId: newSize.subcategoryId,
  });

export const updateSize = (sizeId, updatedSize) =>
  instance.patch(`${url}?sizeid=${sizeId}`, {
    sizeName: updatedSize.name,
    sizeStatus: updatedSize.status,
  });

export const deleteSize = (sizeId, deletedSize) =>
  instance.patch(`${url}?sizeid=${sizeId}`, {
    sizeName: deletedSize.name,
    sizeStatus: "N",
  });
