import React from "react";

const CartQuantity = (props) => {
  return (
    <div className="cart-added-quantity">
      You can't add more of this product because chosen quantity matches
      available quantity.
    </div>
  );
};

export default CartQuantity;
