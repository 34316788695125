import * as api from "../api/seller";

export const getAllSellers = async () => {
  try {
    const data = await api.getAllSellers();
    return data;
  } catch (error) {
    console.log(error, "seller-service getall error");
    return error;
  }
};

export const getSellerById = async (sellerId) => {
  try {
    const data = await api.getSellerById(sellerId);
    return data;
  } catch (error) {
    console.log(error, "seller-service getbyid error");
    return error;
  }
};

//register seller ili create new seller
export const createNewSeller = async (newSeller) => {
  try {
    const data = await api.createNewSeller(newSeller);
    return data;
  } catch (error) {
    console.log(error, "seller-service create error");
    return error;
  }
};

export const updateSeller = async (sellerid, updatedSeller) => {
  try {
    const data = await api.updateSeller(sellerid, updatedSeller);
    return data;
  } catch (error) {
    console.log(error, "seller-service update error");
    return error;
  }
};

export const deleteSeller = async (sellerid, deletedSeller) => {
  try {
    const data = await api.deleteSeller(sellerid, deletedSeller);
    return data;
  } catch (error) {
    console.log(error, "seller-service delete error");
    return error;
  }
};

export const loginSeller = async (existingSeller) => {
  try {
    const data = await api.loginSeller(existingSeller);
    return data;
  } catch (error) {
    console.log(error, "seller-service update error");
    return error;
  }
};
