import * as api from "../api/image";

export const getImage = async (imageId, byWhatCondition, conditionId) => {
  try {
    const data = await api.getImage(imageId, byWhatCondition, conditionId);
    return data;
  } catch (error) {
    console.log(error, "image-service getimage error");
    return error;
  }
};

export const createImage = async (newImage, byWhatCondition, conditionId) => {
  try {
    const data = await api.createImage(newImage, byWhatCondition, conditionId);
    return data;
  } catch (error) {
    console.log(error, "image-service createimage error");
    return error;
  }
};

export const deleteImage = async (imageId, deletedImage) => {
  try {
    const data = await api.deleteImage(imageId, deletedImage);
    return data;
  } catch (error) {
    console.log(error, "image-service delete error");
    return error;
  }
};
