import React from "react";
import PagesNavList from "../components/PagesNavList/PagesNavList";
import ShoppingCartComponent from "../components/ShoppingCart/ShoppingCart";
const ShoppingCart = () => {
  return (
    <div className="shopping-cart">
      <PagesNavList
        title="Shopping Cart"
        num={3}
        items={["home", "shop", "shopping cart"]}
      />
      <ShoppingCartComponent />
    </div>
  );
};

export default ShoppingCart;
