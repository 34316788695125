import React, { useEffect, useState } from "react";
import "./ShopContent.scss";
import { Col, Row } from "react-bootstrap";
import { VscSearch } from "react-icons/vsc";
import { IoIosArrowDown } from "react-icons/io";
import Paginate from "../ShopPaginate/ShopPaginate";
import { NavDropdown } from "react-bootstrap";
import {
  getAvailableProducts,
  getSearchOfProducts,
  getMultiFilteredAvailableProducts,
} from "../../services/product-service";
import { useDispatch, useSelector } from "react-redux";
import { productActions } from "../../slices/product";
import { Link } from "react-router-dom";
import Modal from "../Modal/Modal";
import CartModalContent from "../CartModalContent/CartModalContent";
import { getCategoryByStatus } from "../../services/category-service";
import { getAllBrends } from "../../services/brend-service";
import { getAllSizes } from "../../services/size-service";
import { getSubcategoryByStatus } from "../../services/subcategory-service";
import { getAllColors } from "../../services/color-service";
import Loader from "../Loader/Loader";
import { searchActions } from "../../slices/search";
import CartAddedMessage from "../CartModalContent/CartAddedMessage";

const ShopContent = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.product);
  const searchRedux = useSelector((state) => state.search);
  const [offset, setOffset] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [modalCart, setModalCart] = useState(false);
  const [productCart, setProductCart] = useState(null);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [colors, setColors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState([]);

  //podesava kategorija,brend
  const [filter, setFilter] = useState(null);
  //podesava parametre za url
  const [parameters, setParameters] = useState([]);

  const setNumbers = (offset, endOffset, total) => {
    setOffset([offset, endOffset, total]);
  };

  useEffect(() => {
    getAvailableProducts().then((data) => {
      dispatch(productActions.getAllProducts(data.data.data.products));
    });

    getCategoryByStatus("Y").then((data) => {
      setCategories(data.data.data.categories);
    });
    getAllBrends().then((data) => {
      setBrands(data.data.data.brands);
    });
    getAllSizes().then((data) => {
      setSizes(data.data.data.sizes);
    });
    getSubcategoryByStatus("Y").then((data) => {
      setSubcategories(data.data.data.subcategories);
    });
    getAllColors().then((data) => {
      setColors(data.data.data.colors);
    });
    setSearchValue(searchRedux.value);

    setLoading(false);
  }, []);

  useEffect(() => {
    filterFunction();
  }, [parameters]);

  const handleFilter = (e) => {
    if (filter === e.target.value) {
      setFilter(null);
      return;
    }
    setFilter(`${e.target.value}`);
  };

  const handleWhatProductsToShow = (e) => {
    e.preventDefault();
    console.log(e.target.innerHTML);
    let obj = parameters.findIndex((i) => i.filter === e.target.value);
    let tag = filters.findIndex((i) => i.filter === e.target.value);

    if (tag !== -1) {
      setFilters((prevState) =>
        prevState.map((item, i) =>
          i === tag
            ? {
                filter: item.filter,
                value: e.target.innerHTML,
                id: e.target.id,
              }
            : item
        )
      );
    } else {
      setFilters((prevState) => [
        ...prevState,
        { filter: e.target.value, value: e.target.innerHTML, id: e.target.id },
      ]);
    }

    if (obj !== -1) {
      setParameters((prevState) =>
        prevState.map((item, i) =>
          i === obj ? { filter: item.filter, value: e.target.id } : item
        )
      );
    } else {
      setParameters((prevState) => [
        ...prevState,
        { filter: e.target.value, value: e.target.id },
      ]);
    }
  };

  const handlePriceFilter = (e) => {
    e.preventDefault();

    let obj = parameters.findIndex((i) => i.filter === "priceup");
    let tag = filters.findIndex((i) => i.filter === "priceup");
    console.log(tag);
    if (tag !== -1) {
      setFilters((prevState) =>
        prevState.map((item, i) =>
          i === tag
            ? {
                filter: "priceup",
                value: e.target.innerHTML,
                string: `${e.target.id}${
                  e.target.value !== "" ? `&${e.target.value}` : ""
                }`,
              }
            : item
        )
      );
    } else {
      setFilters((prevState) => [
        ...prevState,
        {
          filter: "priceup",
          value: e.target.innerHTML,
          string: `${e.target.id}${
            e.target.value !== "" ? `&${e.target.value}` : ""
          }`,
        },
      ]);
    }

    if (obj !== -1) {
      setParameters((prevState) =>
        prevState.map((item, i) =>
          i === obj
            ? {
                filter: "priceup",
                value: `${e.target.id}${
                  e.target.value !== "" ? `&${e.target.value}` : ""
                }`,
              }
            : item
        )
      );
    } else {
      setParameters((prevState) => [
        ...prevState,
        {
          filter: "priceup",
          value: `${e.target.id}${
            e.target.value !== "" ? `&${e.target.value}` : ""
          }`,
        },
      ]);
    }
  };

  const removeParameter = (e) => {
    //samo filter po cijeni ce imati ovu id vrijednost, ostali ce imati svoj id kao broj
    if (e.target.id === "priceup") {
      setParameters((prevState) =>
        prevState.filter((item) => item.value !== e.target.dataset.string)
      );

      setFilters((prevState) =>
        prevState.filter((item) => item.value !== e.target.value)
      );
      return;
    }
    setParameters((prevState) =>
      prevState.filter((item) => item.value !== e.target.id)
    );

    setFilters((prevState) =>
      prevState.filter((item) => item.id !== e.target.id)
    );
  };

  const filterFunction = () => {
    setLoading(true);
    getMultiFilteredAvailableProducts(parameters).then((data) => {
      dispatch(productActions.getAllProducts(data.data.data.products));
      setLoading(false);
    });
  };

  const sortHighToLow = () => {
    //function for sorting by price high to low
  };

  const sortLowToHigh = () => {
    //function for sorting by price low to high
  };

  const onSearchChange = (e) => {
    dispatch(searchActions.setValue(e.target.value));
    setSearchValue(e.target.value);

    if (e.target.value !== "") {
      getSearchOfProducts(e.target.value).then((data) => {
        setSearchedProducts(data.data.data.products);
      });
    } else {
      setSearchedProducts(null);
    }
  };

  const onSearchFocus = (e) => {
    dispatch(searchActions.setDropdown());
    if (searchRedux.value !== "")
      getSearchOfProducts(e.target.value).then((data) => {
        setSearchedProducts(data.data.data.products);
      });
  };

  const onSearchBlur = (e) => {
    dispatch(searchActions.resetDropdown());
  };

  const handleSearch = () => {
    setSearchValue(searchRedux.value);
    dispatch(searchActions.setValue(searchValue));
  };

  const handleModalCart = (id) => {
    setModalCart(true);
    setProductCart(id);
  };

  const handleCloseModal = () => {
    setModalCart(false);
    setProductCart(null);
  };

  const handleMessage = () => {
    document.querySelector(".cart-added-message").classList.add("show-message");

    setTimeout(() => {
      document
        .querySelector(".cart-added-message")
        .classList.remove("show-message");
    }, "3000");
  };
  //tags su valjda karakteristike pa cemo njih po characteristicId

  return (
    <div className="shop-content-container container-padding">
      {modalCart && (
        <Modal closeModal={handleCloseModal}>
          <CartModalContent
            productId={productCart}
            closeModal={handleCloseModal}
            showMessage={handleMessage}
          />
        </Modal>
      )}
      <CartAddedMessage />
      <Row>
        <Col md={4} className="shop-content-height-col">
          <section className="shop-content-search-input">
            <input
              type="text"
              value={searchRedux.value}
              placeholder={searchValue}
              onChange={onSearchChange}
              onFocus={onSearchFocus}
              onBlur={onSearchBlur}
            />
            <button onClick={handleSearch}>
              <VscSearch />
            </button>
          </section>
          {searchRedux.dropdown && searchValue !== "" && (
            <div className="search-suggestions-wrap">
              {searchedProducts &&
                searchedProducts.length !== 0 &&
                searchedProducts.length < 5 &&
                searchedProducts.map((item) => {
                  return (
                    <Link
                      to={`/shop-details/${item.productId}`}
                      className="search-suggestions"
                      key={item.productId}
                    >
                      {item.productName}
                    </Link>
                  );
                })}

              {searchedProducts &&
                searchedProducts.length > 5 &&
                searchedProducts.slice(0, 5).map((item) => {
                  return (
                    <Link
                      to={`/shop-details/${item.productId}`}
                      className="search-suggestions"
                      key={item.productId}
                    >
                      {item.productName}
                    </Link>
                  );
                })}
              {searchedProducts && searchedProducts.length === 0 && (
                <p className="search-suggestions">Not found</p>
              )}
            </div>
          )}
          {filters.length !== 0 && (
            <div className="shop-content-filter-tags">
              {filters.map((i, key) => (
                <section key={key}>
                  <span>{i.value}</span>
                  <button
                    id={i.id ? i.id : "priceup"}
                    value={i.value}
                    data-string={i.string}
                    onClick={removeParameter}
                  >
                    x
                  </button>
                </section>
              ))}
            </div>
          )}
          <section className="shop-content-filtering">
            <section className="shop-content-filtering-item">
              <button value="categories" readOnly onClick={handleFilter}>
                Categories <IoIosArrowDown />
              </button>
              {filter === "categories" && (
                <ul className="shop-categories-ul">
                  {categories &&
                    categories.map((item) => {
                      return (
                        <li key={item.categoryId}>
                          <button
                            onClick={handleWhatProductsToShow}
                            value={"category"}
                            id={item.categoryId}
                          >
                            {item.categoryName}
                          </button>
                        </li>
                      );
                    })}
                </ul>
              )}
            </section>
            <section className="shop-content-filtering-item">
              <button value="branding" readOnly onClick={handleFilter}>
                Branding <IoIosArrowDown />
              </button>
              {filter === "branding" && (
                <ul className="shop-categories-ul">
                  {brands &&
                    brands.map((item, key) => {
                      if (item.brandStatus === "Y")
                        return (
                          <li key={key}>
                            <button
                              onClick={handleWhatProductsToShow}
                              value="brand"
                              id={item.brandId}
                            >
                              {item.brandName}
                            </button>
                          </li>
                        );
                    })}
                </ul>
              )}
            </section>
            <section className="shop-content-filtering-item">
              <button value="price" readOnly onClick={handleFilter}>
                Filter price <IoIosArrowDown />
              </button>
              {filter === "price" && (
                <ul className="shop-categories-ul">
                  <li>
                    <button
                      onClick={handlePriceFilter}
                      value="priceup=50"
                      id="pricedown=0"
                    >
                      $0.00 - $50.00
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={handlePriceFilter}
                      value="priceup=100"
                      id="pricedown=50"
                    >
                      $50.00 - $100.00
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={handlePriceFilter}
                      value="priceup=150"
                      id="pricedown=100"
                    >
                      $100.00 - $150.00
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={handlePriceFilter}
                      value="priceup=200"
                      id="pricedown=150"
                    >
                      $150.00 - $200.00
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={handlePriceFilter}
                      value="priceup=250"
                      id="pricedown=200"
                    >
                      $200.00 - $250.00
                    </button>
                  </li>
                  <li>
                    {/* ovaj mora da preda ovo 250 kao minimalnu vrijednost a ne kao maksimalnu jer maksimum ne postoji */}
                    <button
                      onClick={handlePriceFilter}
                      value=""
                      id="pricedown=250"
                    >
                      $250.00 +
                    </button>
                  </li>
                </ul>
              )}
            </section>
            <section className="shop-content-filtering-item size">
              <button value="filter-size" readOnly onClick={handleFilter}>
                Size <IoIosArrowDown />
              </button>
              {filter === "filter-size" && (
                <ul className="shop-categories-ul">
                  {sizes &&
                    sizes.map((item, key) => {
                      if (item.status === "Y")
                        return (
                          <li key={key}>
                            <button
                              onClick={handleWhatProductsToShow}
                              value="size"
                              id={item.sizeId}
                            >
                              {item.sizeName}
                            </button>
                          </li>
                        );
                    })}
                </ul>
              )}
            </section>
            <section className="shop-content-filtering-item colors">
              <button value="colors" readOnly onClick={handleFilter}>
                Colors <IoIosArrowDown />
              </button>
              {filter === "colors" && (
                <ul className="shop-categories-ul">
                  {colors &&
                    colors.map((item, key) => {
                      return (
                        <li key={key}>
                          <button
                            id={item.colorId}
                            onClick={handleWhatProductsToShow}
                            value="color"
                            style={{ backgroundColor: `${item.colorValue}` }}
                          ></button>
                        </li>
                      );
                    })}
                </ul>
              )}
            </section>
            <section className="shop-content-filtering-item tags">
              <button value="tags" readOnly onClick={handleFilter}>
                Tags <IoIosArrowDown />
              </button>
              {filter === "tags" && (
                <ul className="shop-categories-ul">
                  {subcategories &&
                    subcategories.map((item, key) => {
                      return (
                        <li key={key}>
                          <button
                            onClick={handleWhatProductsToShow}
                            value="subcategory"
                            id={item.subcategoryId}
                          >
                            {item.subcategoryName}
                          </button>
                        </li>
                      );
                    })}
                </ul>
              )}
            </section>
          </section>
        </Col>
        <Col md={8}>
          <Row className="margin-bottom-2rem shop-details-text">
            <Col className="shop-content-top-left" md={6}>
              Showing {offset[0] + 1}-{offset[1]} of {offset[2]} results
            </Col>
            <Col className="shop-content-top-right" md={6}>
              <NavDropdown
                className="shop-dropdown"
                title="Sort by Price"
                // id={`offcanvasNavbarDropdown-expand-${expand}`}
              >
                <NavDropdown.Item>
                  <button onClick={sortLowToHigh}>Low to High</button>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <button onClick={sortHighToLow}>High to Low</button>
                </NavDropdown.Item>
              </NavDropdown>
            </Col>
          </Row>
          <Row style={{ position: "relative", minHeight: "200px" }}>
            {loading && <Loader />}
            <Row>
              <Paginate
                products={products.products}
                itemsPerPage={12}
                setNumbers={setNumbers}
                handleModalCart={handleModalCart}
              />
            </Row>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ShopContent;
