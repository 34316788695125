import { createSlice } from "@reduxjs/toolkit";

export const searchSlice = createSlice({
  name: "search",
  initialState: {
    value: "",
    dropdown: false,
    headerDropdown: true,
  },
  reducers: {
    setValue: (state, action) => {
      state.value = action.payload;
    },
    resetValue: (state, action) => {
      state.value = "";
    },

    setDropdown: (state, action) => {
      state.dropdown = true;
    },
    resetDropdown: (state, action) => {
      state.dropdown = false;
    },
    setHeaderDropdown: (state, action) => {
      state.headerDropdown = true;
    },
    resetHeaderDropdown: (state, action) => {
      state.headerDropdown = false;
    },
  },
});

export const searchActions = searchSlice.actions;
export default searchSlice.reducer;
