import instance from "./instance";
const url = "https://dev.api.shopio.me/controller/order";

export const getOrder = () => instance.get(url);

export const getOrderById = (orderId) =>
  instance.get(`${url}?orderid=${orderId}`);

export const getOrderByUserId = (userId) =>
  instance.get(`${url}?userid=${userId}`);

export const getOrderBySellerId = (sellerId) =>
  instance.get(`${url}?sellerid=${sellerId}`);

export const getFilteredOrder = (byWhatFilter, filterValue) =>
  instance.get(`${url}?filter=Y?${byWhatFilter}=${filterValue}`);

export const updateOrderStatus = (orderId, orderStatus) =>
  instance.patch(`${url}?orderid=${orderId}`, { orderStatus: orderStatus });

export const createNewOrder = (order) => {
  if (localStorage.getItem("accessToken")) {
    return instance.post(url, {
      orderNotes: order.orderNotes,
      productList: order.productList,
    });
  } else {
    return instance.post(url, {
      firstname: order.firstname,
      lastname: order.lastname,
      country: order.country,
      city: order.city,
      street: order.street,
      streetNumber: order.streetNumber,
      email: order.email,
      phone: order.phone,
      postal: order.postal,
      orderNotes: order.orderNotes,
      productList: order.productList,
    });
  }
};
