import React from "react";
import useValidate from "../../hooks/useValidate";
import { Form, Button } from "react-bootstrap";
import { getCategoryByStatus } from "../../services/category-service";
import { useState } from "react";
import { useEffect } from "react";
import { createNewSubcategory } from "../../services/subcategory-service";

const NewSubcategory = (props) => {
  const isNotEmpty = (value) => value.trim().length > 0;
  const {
    value: name,
    isValid: nameValid,
    hasError: nameError,
    valueBlurHandler: nameBlurHandler,
    valueChangedHandler: nameChangeHandler,
    reset: nameReset,
  } = useValidate(isNotEmpty);

  let formValid = false;

  if (nameValid) {
    formValid = true;
  }

  const [category, setCategory] = useState([]);

  useEffect(() => {
    getCategoryByStatus("Y").then((data) => {
      setCategory(data.data.data.categories);
    });
  }, []);

  const handleSubmit = (e) => {
    var form = new FormData(
      document.getElementById("add-new-subcategory-form")
    );
    var categoryValue = form.get("add-new-subcategory-categoryId");
    e.preventDefault();

    if (!formValid) {
      alert("Please provide correct information");
      return;
    }

    createNewSubcategory({
      subcategoryName: name,
      categoryId: categoryValue,
    });

    nameReset();
  };

  return (
    <div className="add-new">
      <h5>Add new Subcategory</h5>
      <Form onSubmit={handleSubmit} id="add-new-subcategory-form">
        <Form.Group className="mb-3">
          <Form.Label htmlFor={`add-new-subcategory-label-name`}>
            Name
          </Form.Label>
          <Form.Control
            id={`add-new-subcategory-label-name`}
            type="text"
            placeholder="Name"
            value={name}
            onChange={nameChangeHandler}
            onBlur={nameBlurHandler}
            name="add-new-subcategory-name"
          />
          {nameError && <p className="red-text">Name can't be empty</p>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor={`add-new-subcategory-label-categoryId`}>
            Category
          </Form.Label>
          <Form.Select
            id="add-new-subcategory-label-categoryId"
            name="add-new-subcategory-categoryId"
          >
            {category &&
              category.map((item) => {
                return (
                  <option key={item.categoryId} value={item.categoryId}>
                    {item.categoryName}
                  </option>
                );
              })}
          </Form.Select>
        </Form.Group>

        <Button variant="primary" type="submit" className="mt-3">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default NewSubcategory;
