import instance from "./instance";

const url = "https://dev.api.shopio.me/controller/category";

export const getAllCategories = () => instance.get(url);

export const getCategoryByStatus = (categoryStatus) =>
  instance.get(`${url}?categorystatus=${categoryStatus}`);

export const getAllFavoriteCategories = () => instance.get(`${url}?favorite=Y`);

export const getFavoriteCategoryById = (categoryId) =>
  instance.get(`${url}?favorite=${categoryId}`);

export const createCategory = (newCategory) =>
  instance.post(url, { categoryName: newCategory });

export const updateCategory = (categoryId, updatedCategory) =>
  instance.patch(`${url}?categoryid=${categoryId}`, {
    categoryName: updatedCategory.name,
    categoryStatus: updatedCategory.status,
  });

//this is supposed to toggle favorite property
export const updateFavoriteCategory = (categoryId) =>
  instance.patch(`${url}?favorite=${categoryId}`);

//deleteCategory function doesn't actually delete a Category. It's purpose is to change the availabilty of that Category from Y to N. That's why it receives deletedCategory argument, that is a Category which has a changed property and needs to replace the existing one :)

export const deleteCategory = (categoryId, deletedCategory) =>
  instance.patch(`${url}?categoryid=${categoryId}`, {
    categoryName: deletedCategory.categoryName,
    categoryStatus: "N",
  });
