import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import EditSeller from "./EditSeller";
import { getSellerById, updateSeller } from "../../services/seller-service";
import { useSelector } from "react-redux";
import EditSellerDetails from "./EditSellerDetails";
import Loader from "../Loader/Loader";

const SellerDetails = (props) => {
  const auth = useSelector((state) => state.auth);
  const [item, setItem] = useState([]);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);

  useEffect(() => {
    getSellerById(auth.id).then((data) => {
      setItem(data.data.data.sellers[0]);
    });
  }, []);

  useEffect(() => {
    if (edit === true) {
      const target = document.querySelector(".edit-product-container");
      if (target !== null) target.scrollIntoView({ behavior: "smooth" });
    } else {
      const target = document.querySelector(".wrapper-admin-table");
      if (target !== null) target.scrollIntoView({ behavior: "smooth" });
    }
  }, [edit, id]);

  const closeModal = () => {
    setEdit(false);
    setId(null);
  };

  const handleEdit = (e) => {
    setId(e.target.id);
    setEdit(true);
  };

  const editItem = (updatedItem) => {
    updateSeller(id, updatedItem).then((data) => {
      setId(null);
      setEdit(false);
    });
  };
  if (item.length !== 0)
    return (
      <div
        style={{ position: "relative", minHeight: "200px" }}
        className="wrapper-admin-table"
      >
        <h4>My Details</h4>
        {item.length !== 0 && (
          <Table responsive="xl" className="table-all-products">
            <thead>
              <tr>
                <th>id</th>
                <th>Name</th>
                <th>Description</th>
                <th>Country</th>
                <th>City</th>
                <th>Street</th>
                <th>Street Number</th>
                <th>Postal</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr key={item.sellerId}>
                <td>{item.sellerId}</td>
                <td>{item.sellerName}</td>
                <td>{item.sellerDescription}</td>
                <td>{item.country}</td>
                <td>{item.city}</td>
                <td>{item.street}</td>
                <td>{item.streetNumber}</td>
                <td>{item.postal}</td>
                <td>{item.email}</td>
                <td>{item.phone}</td>
                <td>{item.status}</td>
                {auth.id === item.sellerId && auth.role === "seller" && (
                  <td>
                    <Button onClick={handleEdit} id={item.sellerId}>
                      Edit
                    </Button>
                  </td>
                )}
              </tr>
            </tbody>
          </Table>
        )}
        {edit && (
          <EditSellerDetails
            closeModal={closeModal}
            item={item}
            handleEdit={editItem}
          />
        )}
      </div>
    );
  else
    return (
      <div
        style={{ position: "relative", minHeight: "200px" }}
        className="wrapper-admin-table"
      >
        <Loader />
      </div>
    );
};

export default SellerDetails;
