import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
    totalAmount: 0.0,
  },
  reducers: {
    addCartItem: (state, action) => {
      const updatedTotalAmount =
        state.totalAmount + action.payload.price * action.payload.quantity;

      const existingCartItemIndex = state.items.findIndex(
        (item) => item.id === action.payload.id
      );

      const existingCartItem = state.items[existingCartItemIndex];
      let updatedItems;

      if (existingCartItem) {
        const updatedItem = {
          ...existingCartItem,
          quantity: existingCartItem.quantity + action.payload.quantity,
        };
        updatedItems = [...state.items];
        updatedItems[existingCartItemIndex] = updatedItem;
      } else {
        updatedItems = state.items.concat(action.payload);
      }

      state.items = updatedItems;
      state.totalAmount = parseFloat(updatedTotalAmount.toFixed(2));
    },
    removeCartItem: (state, action) => {
      const existingCartItemIndex = state.items.findIndex(
        (item) => item.id === action.payload
      );

      const existingCartItem = state.items[existingCartItemIndex];
      const updatedTotalAmount = state.totalAmount - existingCartItem.price;
      let updatedItems;

      if (existingCartItem.quantity === 1) {
        updatedItems = state.items.filter((item) => item.id !== action.payload);
      } else {
        const updatedItem = {
          ...existingCartItem,
          quantity: existingCartItem.quantity - 1,
        };
        updatedItems = [...state.items];
        updatedItems[existingCartItemIndex] = updatedItem;
      }

      state.items = updatedItems;
      state.totalAmount = parseFloat(updatedTotalAmount.toFixed(2));
    },
    deleteCartItem: (state, action) => {
      const index = state.items.findIndex((item) => item.id === action.payload);

      const amount = state.items[index].quantity;
      const price = state.items[index].price;

      const updatedTotalAmount = state.totalAmount - price * amount;

      state.totalAmount = parseFloat(updatedTotalAmount.toFixed(2));
      state.items.splice(index, 1);
    },
    resetTotalAmount: (state, action) => {
      state.totalAmount = 0;
    },
    resetCart: (state, action) => {
      state.items = [];
      state.totalAmount = 0;
    },
  },
  extraReducers: {},
});

// export const { addCartItem, removeCartItem } = cartSlice.actions;
export const cartActions = cartSlice.actions;
export default cartSlice.reducer;
