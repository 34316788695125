import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import {
  getAllProducts,
  updateProduct,
  deleteProduct,
  getProductsBySellerId,
} from "../../services/product-service";
import ConfirmationDelete from "../ConfirmationDelete/ConfirmationDelete";
import EditProduct from "./EditProduct";
import { useSelector } from "react-redux";
import Loader from "../Loader/Loader";

const AllProducts = (props) => {
  const auth = useSelector((state) => state.auth);
  const [confirm, setConfirm] = useState(false);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);
  const [allProducts, setAllProducts] = useState([]);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);
  let items = allProducts;
  let itemForEdit;

  const getAppropriateProducts = () => {
    if (auth.role === "seller") {
      getProductsBySellerId(auth.id).then((data) => {
        setAllProducts(data.data.data.products);
      });
    } else {
      getAllProducts().then((data) => {
        setAllProducts(data.data.data.products);
      });
    }
  };

  useEffect(() => {
    getAppropriateProducts();
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + props.itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / props.itemsPerPage));
  }, [itemOffset, props.itemsPerPage, items]);

  const closeModal = () => {
    setConfirm(false);
    setEdit(false);
    setId(null);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setId(e.target.id);
    setConfirm(true);
  };

  const deleteItem = (deletedProduct) => {
    //odradi delete i to sto treba i onda zatvori modal
    deleteProduct(id, deletedProduct).then((data) => {
      setConfirm(false);
      setId(null);
      getAppropriateProducts();
    });
    // window.location.reload(false);
  };

  useEffect(() => {
    if (edit === true) {
      const target = document.querySelector(".edit-product-container");
      if (target !== null) target.scrollIntoView({ behavior: "smooth" });
    } else {
      const target = document.querySelector(".wrapper-admin-table");
      if (target !== null) target.scrollIntoView({ behavior: "smooth" });
    }
  }, [edit, id]);
  const handleEdit = (e) => {
    setId(e.target.id);
    setEdit(true);
  };

  const editItem = (updatedProduct) => {
    updateProduct(id, updatedProduct).then((data) => {
      setId(null);
      setEdit(false);
      getAppropriateProducts();
    });
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * props.itemsPerPage) % items.length;

    setItemOffset(newOffset);
  };
  if (id) {
    itemForEdit = items.filter((x) => `${x.productId}` === `${id}`);
  }

  if (allProducts.length !== 0)
    return (
      <div
        style={{ position: "relative", minHeight: "200px" }}
        className="wrapper-admin-table"
      >
        <h4>All products</h4>
        <Table responsive="xl" className="table-all-products">
          {confirm && (
            <ConfirmationDelete
              closeModal={closeModal}
              deleteItem={deleteItem}
            />
          )}

          <thead>
            <tr>
              <th>id</th>
              <th>Name</th>
              <th>Price</th>
              <th>Brand</th>
              <th>Subcategory</th>
              <th>Materials</th>
              <th>Care</th>
              <th>Color</th>
              <th>Size</th>
              <th>Code</th>
              <th>Status</th>
              <th>Discount</th>
              <th>Available</th>
            </tr>
          </thead>
          <Items
            currentItems={currentItems}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        </Table>
        <ReactPaginate
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="< "
          renderOnZeroPageCount={null}
          className="react-paginate"
          activeLinkClassName="active-page"
        />
        {edit && (
          <EditProduct
            id={id}
            item={itemForEdit}
            editItem={editItem}
            closeModal={closeModal}
          />
        )}
      </div>
    );
  else
    return (
      <div
        style={{ position: "relative", minHeight: "200px" }}
        className="wrapper-admin-table"
      >
        <Loader />
      </div>
    );
};

export default AllProducts;

const Items = (props) => {
  return (
    <tbody>
      {props.currentItems &&
        props.currentItems.map((item) => {
          return (
            <tr key={item.productId}>
              <td>{item.productId}</td>
              <td>{item.productName}</td>
              <td>{item.productPrice}</td>
              <td>{item.brandId}</td>
              <td>{item.subcategoryId}</td>
              <td>{item.productMaterials}</td>
              <td>{item.productCare}</td>
              <td>{item.productColor.map((color) => `${color.colorName}`)}</td>
              <td>{item.productSize.map((size) => `${size.sizeName} `)}</td>
              <td>{item.productCode}</td>
              <td>{item.productStatus}</td>
              <td>{item.productDiscount}</td>
              <td>{item.availableQuantity}</td>

              <td>
                <Button onClick={props.handleEdit} id={item.productId}>
                  Edit
                </Button>
              </td>
              {item.productStatus === "Y" && (
                <td>
                  <Button
                    variant="danger"
                    onClick={props.handleDelete}
                    id={item.productId}
                  >
                    Delete
                  </Button>
                </td>
              )}
            </tr>
          );
        })}
    </tbody>
  );
};
