import React from "react";
import { Form, Button } from "react-bootstrap";

const EditSellerDetails = (props) => {
  const item = props.item;

  let formValid = false;

  const handleSubmit = (e) => {
    var form = new FormData(
      document.getElementById("edit-seller-details-form")
    );

    var nameValue = form.get("edit-seller-details-sellerName");
    var descriptionValue = form.get("edit-seller-details-sellerDescription");
    var countryValue = form.get("edit-seller-details-country");
    var cityValue = form.get("edit-seller-details-city");
    var streetValue = form.get("edit-seller-details-street");
    var streetNumberValue = form.get("edit-seller-details-streetNumber");
    var postalValue = form.get("edit-seller-details-postal");
    var emailValue = form.get("edit-seller-details-email");
    var phoneValue = form.get("edit-seller-details-phone");
    var statusValue = form.get("edit-seller-details-status");

    e.preventDefault();
    if (statusValue !== "") formValid = true;

    if (!formValid) {
      alert("Please fill out all of the fields");
      return;
    }

    //ovdje saljemo editovani objekat
    props.handleEdit({
      sellerName: nameValue,
      sellerDescription: descriptionValue,
      country: countryValue,
      sellerStatus: statusValue,
      street: streetValue,
      streetNumber: streetNumberValue,
      postal: postalValue,
      password: item.password,
      email: emailValue,
      phone: phoneValue,
      city: cityValue,
    });
  };

  return (
    <div className="edit-product-container">
      <h5>Edit seller #{item.sellerId} status</h5>
      <Form onSubmit={handleSubmit} id="edit-seller-details-form">
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-seller-details-label-sellerName">
            Name
          </Form.Label>
          <Form.Control
            id="edit-seller-details-label-sellerName"
            type="text"
            name="edit-seller-details-sellerName"
            placeholder={item.sellerName}
            defaultValue={item.sellerName}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-seller-details-label-sellerDescription">
            Description
          </Form.Label>
          <Form.Control
            id="edit-seller-details-label-sellerDescription"
            type="text"
            name="edit-seller-details-sellerDescription"
            placeholder={item.sellerDescription}
            defaultValue={item.sellerDescription}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-seller-details-label-country">
            Country
          </Form.Label>
          <Form.Control
            id="edit-seller-details-label-country"
            type="text"
            name="edit-seller-details-country"
            placeholder={item.country}
            defaultValue={item.country}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-seller-details-label-city">city</Form.Label>
          <Form.Control
            id="edit-seller-details-label-city"
            type="text"
            name="edit-seller-details-city"
            placeholder={item.city}
            defaultValue={item.city}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-seller-details-label-street">
            street
          </Form.Label>
          <Form.Control
            id="edit-seller-details-label-street"
            type="text"
            name="edit-seller-details-street"
            placeholder={item.street}
            defaultValue={item.street}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-seller-details-label-streetNumber">
            streetNumber
          </Form.Label>
          <Form.Control
            id="edit-seller-details-label-streetNumber"
            type="text"
            name="edit-seller-details-streetNumber"
            placeholder={item.streetNumber}
            defaultValue={item.streetNumber}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-seller-details-label-postal">
            postal
          </Form.Label>
          <Form.Control
            id="edit-seller-details-label-postal"
            type="text"
            name="edit-seller-details-postal"
            placeholder={item.postal}
            defaultValue={item.postal}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-seller-details-label-email">
            email
          </Form.Label>
          <Form.Control
            id="edit-seller-details-label-email"
            type="text"
            name="edit-seller-details-email"
            placeholder={item.email}
            defaultValue={item.email}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-seller-details-label-phone">
            phone
          </Form.Label>
          <Form.Control
            id="edit-seller-details-label-phone"
            type="text"
            name="edit-seller-details-phone"
            placeholder={item.phone}
            defaultValue={item.phone}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-seller-details-label-status">
            Status
          </Form.Label>
          <Form.Control
            id="edit-seller-details-label-status"
            type="text"
            name="edit-seller-details-status"
            placeholder={item.status}
            defaultValue={item.status}
          />
        </Form.Group>
      </Form>
      <Button variant="secondary" onClick={props.closeModal}>
        Cancel
      </Button>
      <Button
        form="edit-seller-details-form"
        variant="primary"
        type="submit"
        onClick={handleSubmit}
      >
        Edit
      </Button>
    </div>
  );
};

export default EditSellerDetails;
