import React from "react";

import { Form, Button } from "react-bootstrap";

const EditOrderStatus = (props) => {
  const item = props.item[0];
  let formValid = false;

  const handleSubmit = (e) => {
    var form = new FormData(document.getElementById("edit-order-form"));

    var statusValue = form.get("edit-order-status");
    e.preventDefault();

    if (statusValue !== "") formValid = true;

    if (!formValid) {
      alert("Please fill out all the fields");
      return;
    }
    props.handleEdit(statusValue);
  };

  return (
    <div className="edit-product-container">
      <h5>
        {props.title} order &nbsp;#{item.orderId}
      </h5>
      <Form onSubmit={handleSubmit} id="edit-order-form">
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-order-label-status">Status</Form.Label>
          <Form.Select id="edit-order-label-status" name="edit-order-status">
            <option value="pending">Pending</option>
            <option value="accepted">Accepted</option>
            <option value="rejected">Rejected</option>
          </Form.Select>
        </Form.Group>
      </Form>
      <Button variant="secondary" onClick={props.closeModal}>
        Cancel
      </Button>
      <Button
        form="edit-order-form"
        variant="primary"
        type="submit"
        onClick={handleSubmit}
      >
        Edit
      </Button>
    </div>
  );
};

export default EditOrderStatus;
