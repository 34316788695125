import * as api from "../api/category";

export const getAllCategories = async () => {
  try {
    const data = await api.getAllCategories();
    return data;
  } catch (error) {
    console.log(error, "category-service getallcategories error");
    return error;
  }
};

export const getCategoryByStatus = async (categoryStatus) => {
  try {
    const data = await api.getCategoryByStatus(categoryStatus);
    return data;
  } catch (error) {
    console.log(error, "category-service getbystatus error");
    return error;
  }
};

export const getAllFavoriteCategories = async () => {
  try {
    const data = await api.getAllFavoriteCategories();
    return data;
  } catch (error) {
    console.log(error, "category-service getallfavorite error");
    return error;
  }
};

export const getFavoriteCategoryById = async (id) => {
  try {
    const data = await api.getFavoriteCategoryById(id);
    return data;
  } catch (error) {
    console.log(error, "category-service getfavoritebyid error");
    return error;
  }
};

export const createCategory = async (newCategory) => {
  try {
    const data = await api.createCategory(newCategory);
    return data;
  } catch (error) {
    console.log(error, "category-service createcategory error");
    return error;
  }
};

export const updateCategory = async (categoryId, updatedCategory) => {
  try {
    const data = await api.updateCategory(categoryId, updatedCategory);
    return data;
  } catch (error) {
    console.log(error, "category-service updateCategory error");
    return error;
  }
};

export const updateFavoriteCategory = async (categoryId) => {
  try {
    const data = await api.updateFavoriteCategory(categoryId);
    return data;
  } catch (error) {
    console.log(error, "category-service updatefavorite error");
    return error;
  }
};

export const deleteCategory = async (categoryId, deletedCategory) => {
  try {
    const data = await api.deleteCategory(categoryId, deletedCategory);
    return data;
  } catch (error) {
    console.log(error, "category-service deleteCategory error");
    return error;
  }
};
