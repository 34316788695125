import React from "react";

import { Form, Button } from "react-bootstrap";

const EditAllInCommon = (props) => {
  const item = props.item;
  let formValid = false;

  const handleSubmit = (e) => {
    var form = new FormData(document.getElementById("edit-form"));
    var nameValue = form.get("edit-common-name");
    var statusValue = form.get("edit-common-status");
    e.preventDefault();

    if (nameValue !== "" && statusValue !== "") formValid = true;

    if (!formValid) {
      alert("Please provide correct information");
      return;
    }
    props.handleEdit({ name: nameValue, status: statusValue });
  };

  return (
    <div className="edit-product-container">
      <h5>
        {props.title} {props.type} &nbsp;#{item.id}
      </h5>
      <Form onSubmit={handleSubmit} id="edit-form">
        <Form.Group className="mb-3">
          <Form.Label htmlFor={`edit-form-label-${props.type}-name`}>
            Name
          </Form.Label>
          <Form.Control
            id={`edit-form-label-${props.type}-name`}
            type="text"
            defaultValue={item.name}
            placeholder={item.name}
            name="edit-common-name"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor={`edit-form-label-${props.type}-status`}>
            Status
          </Form.Label>
          <Form.Select
            id={`edit-form-label-${props.type}-status`}
            name="edit-common-status"
          >
            <option value="Y">Y</option>
            <option value="N">N</option>
          </Form.Select>
        </Form.Group>
      </Form>
      <Button variant="secondary" onClick={props.closeModal}>
        Cancel
      </Button>
      <Button
        form="edit-form"
        variant="primary"
        type="submit"
        onClick={handleSubmit}
      >
        Edit
      </Button>
    </div>
  );
};

export default EditAllInCommon;
