import React, { useState, useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import EditOrderStatus from "./EditOrderStatus";
import ConfirmationDelete from "../ConfirmationDelete/ConfirmationDelete";

import {
  getAllSubcategories,
  updateSubcategory,
  deleteSubcategory,
} from "../../services/subcategory-service";
import EditSubcategories from "./EditSubcategories";
import Loader from "../Loader/Loader";

const AllSubcategories = (props) => {
  const [confirm, setConfirm] = useState(false);
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);
  const [subcategories, setSubcategories] = useState([]);
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);
  let items = subcategories;

  let itemForEdit;

  useEffect(() => {
    getAllSubcategories().then((data) => {
      setSubcategories(data.data.data.subcategories);
    });
  }, []);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + props.itemsPerPage;

    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / props.itemsPerPage));
  }, [itemOffset, props.itemsPerPage, items]);

  useEffect(() => {
    if (edit === true) {
      const target = document.querySelector(".edit-product-container");
      if (target !== null) target.scrollIntoView({ behavior: "smooth" });
    } else {
      const target = document.querySelector(".wrapper-admin-table");
      if (target !== null) target.scrollIntoView({ behavior: "smooth" });
    }
  }, [edit, id]);

  const closeModal = () => {
    setEdit(false);
    setId(null);
  };

  const handleEdit = (e) => {
    setId(e.target.id);
    setEdit(true);
  };

  const editItem = (editedItem) => {
    updateSubcategory(id, editedItem).then((data) => {
      getAllSubcategories().then((data) => {
        setSubcategories(data.data.data.subcategories);
      });
      setId(null);
      setEdit(false);
    });
  };

  const handleDelete = (e) => {
    e.preventDefault();
    setId(e.target.id);
    setConfirm(true);
  };

  const deleteItem = () => {
    deleteSubcategory(id, itemForEdit).then((data) => {
      getAllSubcategories().then((data) => {
        setSubcategories(data.data.data.subcategories);
      });
      setConfirm(false);
      setId(null);
    });
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * props.itemsPerPage) % items.length;

    setItemOffset(newOffset);
  };
  if (id) {
    itemForEdit = items.filter((x) => `${x.subcategoryId}` === `${id}`);
  }

  if (subcategories.length !== 0)
    return (
      <div
        style={{ position: "relative", minHeight: "200px" }}
        className="wrapper-admin-table"
      >
        <h4>All Subcategories</h4>
        <Table responsive="xl" className="table-all-products">
          {confirm && (
            <ConfirmationDelete
              closeModal={closeModal}
              deleteItem={deleteItem}
            />
          )}

          <thead>
            <tr>
              <th>id</th>
              <th>Name</th>
              <th>Category Id</th>
              <th>Status</th>
            </tr>
          </thead>
          <Items
            currentItems={currentItems}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        </Table>
        <ReactPaginate
          breakLabel="..."
          nextLabel=" >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="< "
          renderOnZeroPageCount={null}
          className="react-paginate"
          activeLinkClassName="active-page"
        />
        {edit && (
          <EditSubcategories
            title="Edit"
            closeModal={closeModal}
            handleEdit={editItem}
            item={itemForEdit}
          />
        )}
      </div>
    );
  else
    return (
      <div
        style={{ position: "relative", minHeight: "200px" }}
        className="wrapper-admin-table"
      >
        <Loader />
      </div>
    );
};

export default AllSubcategories;

const Items = (props) => {
  //fetch user by id from props
  return (
    <tbody>
      {props.currentItems &&
        props.currentItems.map((item) => {
          return (
            <tr key={item.subcategoryId}>
              <td>{item.subcategoryId}</td>
              <td>{item.subcategoryName}</td>
              <td>{item.categoryId}</td>
              <td>{item.subcategoryStatus}</td>
              <td>
                <Button onClick={props.handleEdit} id={item.subcategoryId}>
                  Edit
                </Button>
              </td>
              {item.subcategoryStatus === "Y" && (
                <td>
                  <Button
                    variant="danger"
                    onClick={props.handleDelete}
                    id={item.subcategoryId}
                  >
                    Delete
                  </Button>
                </td>
              )}
            </tr>
          );
        })}
    </tbody>
  );
};
