import React from "react";
import { Form, Button } from "react-bootstrap";
import useValidate from "../../hooks/useValidate";

import { createColor } from "../../services/color-service";

const NewColor = (props) => {
  const isNotEmpty = (value) => value.trim().length > 0;
  //brend, kategorija, boja
  const {
    value: name,
    isValid: nameValid,
    hasError: nameError,
    valueBlurHandler: nameBlurHandler,
    valueChangedHandler: nameChangeHandler,
    reset: nameReset,
  } = useValidate(isNotEmpty);
  const {
    value: value,
    isValid: valueValid,
    hasError: valueError,
    valueBlurHandler: valueBlurHandler,
    valueChangedHandler: valueChangeHandler,
    reset: valueReset,
  } = useValidate(isNotEmpty);

  let formValid = false;

  if (nameValid && valueValid) {
    formValid = true;
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formValid) {
      alert("Please provide correct information");
      return;
    }

    createColor({ colorName: name, colorValue: value });

    nameReset();
    valueReset();
  };

  return (
    <div className="add-new">
      <h5>Add new {props.title}</h5>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Label htmlFor={`add-new-color-name`}>Name</Form.Label>
          <Form.Control
            id={`add-new-color-name`}
            type="text"
            placeholder="Name"
            value={name}
            onChange={nameChangeHandler}
            onBlur={nameBlurHandler}
          />
          {nameError && <p className="red-text">Name can't be empty</p>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor={`add-new-color-value`}>value</Form.Label>
          <Form.Control
            id={`add-new-color-value`}
            type="text"
            placeholder="value"
            value={value}
            onChange={valueChangeHandler}
            onBlur={valueBlurHandler}
          />
          {valueError && <p className="red-text">value can't be empty</p>}
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default NewColor;
