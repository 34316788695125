import { useLocation, Navigate, Outlet } from "react-router";
import { useState } from "react";
import { useSelector } from "react-redux";
import Unauthorized from "../Unauthorized/Unauthorized";

const RequireAuthAdmin = () => {
  const auth = useSelector((state) => state.auth);
  const [authorized, setAuthorized] = useState(
    auth.role === "seller" ? true : false
  );
  const location = useLocation();

  if (authorized) {
    return <Outlet />;
  } else if (!authorized && auth.role !== "") {
    return <Unauthorized />;
  } else {
    return <Navigate to={"/sign-in"} state={{ from: location }} replace />;
  }
};

export default RequireAuthAdmin;
