import { useEffect } from "react";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import useValidate from "../../hooks/useValidate";
import { getSubcategoryByStatus } from "../../services/subcategory-service";
import { getAllBrends } from "../../services/brend-service";
import { getAllColors } from "../../services/color-service";
import { getSizeBySubcategoryId } from "../../services/size-service";
import { createProduct } from "../../services/product-service";

const NewProduct = (props) => {
  const isNotEmpty = (value) => value.trim().length > 0;

  const {
    value: name,
    isValid: nameValid,
    hasError: nameError,
    valueBlurHandler: nameBlurHandler,
    valueChangedHandler: nameChangeHandler,
    reset: nameReset,
  } = useValidate(isNotEmpty);
  const {
    value: price,
    isValid: priceValid,
    hasError: priceError,
    valueBlurHandler: priceBlurHandler,
    valueChangedHandler: priceChangeHandler,
    reset: priceReset,
  } = useValidate(isNotEmpty);

  const {
    value: materials,
    isValid: materialsValid,
    hasError: materialsError,
    valueBlurHandler: materialsBlurHandler,
    valueChangedHandler: materialsChangeHandler,
    reset: materialsReset,
  } = useValidate(isNotEmpty);
  const {
    value: care,
    isValid: careValid,
    hasError: careError,
    valueBlurHandler: careBlurHandler,
    valueChangedHandler: careChangeHandler,
    reset: careReset,
  } = useValidate(isNotEmpty);

  const {
    value: code,
    isValid: codeValid,
    hasError: codeError,
    valueBlurHandler: codeBlurHandler,
    valueChangedHandler: codeChangeHandler,
    reset: codeReset,
  } = useValidate(isNotEmpty);
  const {
    value: discount,
    isValid: discountValid,
    hasError: discountError,
    valueBlurHandler: discountBlurHandler,
    valueChangedHandler: discountChangeHandler,
    reset: discountReset,
  } = useValidate(isNotEmpty);
  const {
    value: quantity,
    isValid: quantityValid,
    hasError: quantityError,
    valueBlurHandler: quantityBlurHandler,
    valueChangedHandler: quantityChangeHandler,
    reset: quantityReset,
  } = useValidate(isNotEmpty);

  let formValid = false;

  if (
    nameValid &&
    priceValid &&
    materialsValid &&
    careValid &&
    codeValid &&
    quantityValid &&
    discountValid
  ) {
    formValid = true;
  }

  const [subcategories, setSubcategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [colors, setColors] = useState([]);
  const [size, setSize] = useState([]);
  const [allSize, setAllSize] = useState([]);

  useEffect(() => {
    getSubcategoryByStatus("Y").then((data) => {
      setSubcategories(data.data.data.subcategories);
      getSizeBySubcategoryId(
        data.data.data.subcategories[0].subcategoryId
      ).then((data) => {
        setSize(data.data.data.sizes);
      });
    });
    getAllBrends().then((data) => {
      setBrands(data.data.data.brands);
    });
    getAllColors().then((data) => {
      setColors(data.data.data.colors);
    });
  }, []);

  const handleSizeState = (e) => {
    setAllSize((sizes) =>
      sizes.includes(parseInt(e.target.id))
        ? sizes.filter((i) => i !== parseInt(e.target.id))
        : [parseInt(e.target.id), ...sizes]
    );
  };

  useEffect(() => {}, [allSize]);

  const handleSubmit = (e) => {
    var form = new FormData(document.getElementById("newProductForm"));
    var subcategoryValue = form.get("add-product-subcategory");
    var brandValue = form.get("add-product-brand");
    var colorValue = form.get("add-product-color");
    e.preventDefault();

    if (!formValid) {
      alert("Please fill out all of the fields");
      return;
    }
    createProduct({
      productName: name,
      subcategoryId: subcategoryValue,
      productPrice: price,
      brandId: brandValue,
      productMaterials: materials,
      productCare: care,
      productColor: colorValue,
      productSize: allSize,
      productCode: code,
      productDiscount: discount,
      availableQuantity: quantity,
    });

    nameReset();
    priceReset();
    materialsReset();
    careReset();
    codeReset();
    quantityReset();
    discountReset();
  };

  const handleSubcategorySize = (e) => {
    getSizeBySubcategoryId(e.target.value).then((data) => {
      setSize(data.data.data.sizes);
    });
  };

  return (
    <div md={6} className="add-new">
      <h5>{props.title} product</h5>
      <Form onSubmit={handleSubmit} id="newProductForm">
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-product-label-name">Name</Form.Label>
          <Form.Control
            id="add-product-label-name"
            type="text"
            placeholder="Name"
            value={name}
            onChange={nameChangeHandler}
            onBlur={nameBlurHandler}
          />
          {nameError && <p className="red-text">Name can't be empty</p>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-product-label-price">Price</Form.Label>
          <Form.Control
            id="add-product-label-price"
            type="number"
            min={0.1}
            step={0.1}
            placeholder="Price"
            value={price}
            onChange={priceChangeHandler}
            onBlur={priceBlurHandler}
          />
          {priceError && <p className="red-text">Price can't be empty</p>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-product-label-subcategory">
            Subcategory
          </Form.Label>
          <Form.Select
            id="add-product-label-subcategory"
            name="add-product-subcategory"
            onChange={handleSubcategorySize}
          >
            {subcategories &&
              subcategories.map((item) => {
                return (
                  <option key={item.subcategoryId} value={item.subcategoryId}>
                    {item.subcategoryName}
                  </option>
                );
              })}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-product-label-brand">Brand </Form.Label>
          <Form.Select id="add-product-label-brand" name="add-product-brand">
            {brands &&
              brands.map((item) => {
                if (item.brandStatus === "Y")
                  return (
                    <option key={item.brandId} value={item.brandId}>
                      {item.brandName}
                    </option>
                  );
              })}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-product-label-materials">
            Product Materials
          </Form.Label>
          <Form.Control
            id="add-product-label-materials"
            type="text"
            placeholder="Product Materials"
            value={materials}
            onChange={materialsChangeHandler}
            onBlur={materialsBlurHandler}
          />
          {materialsError && (
            <p className="red-text">Materials can't be empty</p>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-product-label-care">Product Care</Form.Label>
          <Form.Control
            id="add-product-label-care"
            type="text"
            placeholder="Product Care"
            value={care}
            onChange={careChangeHandler}
            onBlur={careBlurHandler}
          />
          {careError && <p className="red-text">Product care can't be empty</p>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-product-label-color">
            Product Color
          </Form.Label>
          <Form.Select id="add-product-label-color" name="add-product-color">
            {colors &&
              colors.map((item) => {
                if (item.status === "Y")
                  return (
                    <option key={item.colorId} value={item.colorId}>
                      {item.colorName}
                    </option>
                  );
              })}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3 product-form-sizes">
          <Form.Label>Product Sizes</Form.Label>
          {size &&
            size.length !== 0 &&
            size.map((item) => {
              return (
                <Form.Check
                  type="checkbox"
                  label={item.sizeName}
                  id={item.sizeId}
                  name="add-product-size"
                  // onChange={handleSize}
                  onClick={handleSizeState}
                />
              );
            })}
          {size && size.length === 0 && (
            <p>No available sizes for this subcategory</p>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-product-label-code">Product Code</Form.Label>
          <Form.Control
            id="add-product-label-code"
            type="text"
            placeholder="Product Code"
            value={code}
            onChange={codeChangeHandler}
            onBlur={codeBlurHandler}
          />
          {codeError && <p className="red-text">Code can't be empty</p>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-product-label-discount">
            Product Discount
          </Form.Label>
          <Form.Control
            id="add-product-label-discount"
            type="text"
            placeholder="Product Discount"
            value={discount}
            onChange={discountChangeHandler}
            onBlur={discountBlurHandler}
          />
          {discountError && <p className="red-text">Discount can't be empty</p>}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="add-product-label-available">
            Available Quantity
          </Form.Label>
          <Form.Control
            id="add-product-label-available"
            type="text"
            placeholder="Available Quantity"
            value={quantity}
            onChange={quantityChangeHandler}
            onBlur={quantityBlurHandler}
          />
          {quantityError && <p className="red-text">Quantity can't be empty</p>}
        </Form.Group>

        {props.children}
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default NewProduct;
