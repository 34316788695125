import instance from "./instance";

const url = "https://dev.api.shopio.me/controller/product";

export const getAllProducts = () => instance.get(url);

export const getPageProducts = (pageNum) =>
  instance.get(`${url}?page=${pageNum}`);

export const createProduct = (newProduct) =>
  instance.post(url, {
    productName: newProduct.productName,
    subcategoryId: newProduct.subcategoryId,
    productPrice: newProduct.productPrice,
    brandId: newProduct.brandId,
    productMaterials: newProduct.productMaterials,
    productCare: newProduct.productCare,
    productColor: newProduct.productColor,
    productSize: newProduct.productSize,
    productCode: newProduct.productCode,
    productDiscount: newProduct.productDiscount,
    availableQuantity: newProduct.availableQuantity,
  });

export const updateProduct = (productId, updatedProduct) =>
  instance.patch(`${url}?productid=${productId}`, {
    productName: updatedProduct.productName,
    productPrice: updatedProduct.productPrice,
    brandId: updatedProduct.brandId,
    productMaterials: updatedProduct.productMaterials,
    productCare: updatedProduct.productCare,
    productColor: updatedProduct.productColor,
    productSize: updatedProduct.productSize,
    productCode: updatedProduct.productCode,
    productStatus: updatedProduct.productStatus,
    productDiscount: updatedProduct.productDiscount,
    availableQuantity: updatedProduct.availableQuantity,
  });

//deleteProduct function doesn't actually delete a product. It's purpose is to change the availabilty of that product from Y to N. That's why it receives deletedProduct argument, that is a product which has a changed property and needs to replace the existing one :)

export const deleteProduct = (productId, deletedProduct) =>
  instance.patch(`${url}?productid=${productId}`, {
    productName: deletedProduct.productName,
    productPrice: deletedProduct.productPrice,
    brandId: deletedProduct.brandId,
    productMaterials: deletedProduct.productMaterials,
    productCare: deletedProduct.productCare,
    productColor: deletedProduct.productColor,
    productSize: deletedProduct.productSize,
    productCode: deletedProduct.productCode,
    productStatus: "N",
    productDiscount: deletedProduct.productDiscount,
    availableQuantity: deletedProduct.availableQuantity,
  });

//FUNCTIONS FOR GETTING FILTERED RESULTS AND SIMILAR

export const getSingleProduct = (productId) =>
  instance.get(`${url}?productid=${productId}`);

/**values for filtering are: productStatus= Y|N && categoryid=VALUE && brandid=VALUE && subcategoryid=VALUE && sizeid=VALUE && colorid && pricedown=VALUE && priceup=VALUE */

export const getFilteredProducts = (productFilter, productFilterValue) =>
  instance.get(`${url}?filter=Y&${productFilter}id=${productFilterValue}`);

export const getFilteredAvailableProducts = (
  productFilter,
  productFilterValue
) =>
  instance.get(
    `${url}?filter=Y&productStatus=Y&${productFilter}id=${productFilterValue}`
  );

export const getMultiFilteredAvailableProducts = (productFilters) =>
  instance.get(
    `${url}?filter=Y&productStatus=Y${productFilters
      .map(
        (item) =>
          `&${
            item.filter === "priceup" || item.filter === "pricedown"
              ? item.value
              : item.filter + `id=${item.value}`
          }`
      )
      .join("")}`
  );

export const getAvailableProducts = () =>
  instance.get(`${url}?filter=Y&productStatus=Y`);

/**http://127.0.0.1:80/shop/controller/product?cartproducts=VALUE,VALUE */
export const getMultipleProductsById = (idArray) => {
  let urlForMultiple = `${url}?cartproducts=${idArray.map((id) => `${id},`)}`;
  //removing the last , (comma sign) from a string
  urlForMultiple = urlForMultiple.slice(0, urlForMultiple.length - 1);
  return instance.get(urlForMultiple);
};

export const getRelatedProducts = (productId) =>
  instance.get(`${url}?related=${productId}`);

export const getMonthProduct = () => instance.get(`${url}?monthproduct=Y`);

export const getNewProducts = () => instance.get(`${url}?new=Y`);

export const getSaleProducts = () => instance.get(`${url}?onsale=Y`);

export const getSearchOfProducts = (searchValue) =>
  instance.get(`${url}?search=${searchValue}`);

export const getBestsellerProducts = () => instance.get(`${url}?bestsellers=Y`);

//seller moze da ima vise prodavnica koje prodavaju vise brendova
export const getProductsBySellerId = (sellerId) =>
  instance.get(`${url}?sellerid=${sellerId}`);

export const getProductsByCategoryId = (categoryId) =>
  instance.get(`${url}?categoryid=${categoryId}`);

export const getProductsBySubCategoryId = (subCategoryId) =>
  instance.get(`${url}?subcategoryid=${subCategoryId}`);

export const getProductsByBrendId = (brendId) =>
  instance.get(`${url}?brendid=${brendId}`);

//ako je proizvod narucen ovo ce samo da se pokrene valjda
export const getProductsBySoldCount = () => instance.get(`${url}?soldcount=Y`);

//kad neko doda proizvod u korpu odmah se salje ovaj request asinhrono i ne mora da se ceka samo se provjerava try i catch i ako faila iz nekog razloga da probamo resubmit
export const getNumberOfProductsInCart = () =>
  instance.get(`${url}?cartaddcount=Y`);
