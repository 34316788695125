import instance from "./instance";

const url = "https://dev.api.shopio.me/controller/characteristic";

export const getAllCharacteristic = () => instance.get(url);

export const getCharacteristicById = (characteristicId) =>
  instance.get(`${url}?characteristicid=${characteristicId}`);

export const createCharacteristic = (newCharacteristic) =>
  instance.post(url, {
    characteristicName: newCharacteristic.characteristicName,
    characteristicStatus: newCharacteristic.characteristicStatus,
    subcategoryId: newCharacteristic.subcategoryId,
  });

export const updateCharacteristic = (characteristicId, updatedCharacteristic) =>
  instance.patch(`${url}?characteristicid=${characteristicId}`, {
    characteristicName: updatedCharacteristic.characteristicName,
    characteristicStatus: updatedCharacteristic.characteristicStatus,
  });

export const deleteCharacteristic = (characteristicId, deletedCharacteristic) =>
  instance.patch(`${url}?characteristicid=${characteristicId}`, {
    characteristicName: deletedCharacteristic,
    characteristicStatus: "N",
  });

export const getCharacteristicBySubcategory = (subcategoryId) =>
  instance.get(`${url}?subcategoryid=${subcategoryId}`);
