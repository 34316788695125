import React from "react";
import { Form, Button } from "react-bootstrap";

const EditColor = (props) => {
  const item = props.item[0];
  let formValid = false;

  const handleSubmit = (e) => {
    var form = new FormData(document.getElementById("edit-color-form"));
    var NameValue = form.get("edit-color-Name");
    var ValueValue = form.get("edit-color-Value");

    var statusValue = form.get("edit-color-status");
    e.preventDefault();

    if (NameValue !== "" && ValueValue !== "" && statusValue !== "")
      formValid = true;

    if (!formValid) {
      alert("Please provide correct information");
      return;
    }

    props.handleEdit({
      colorValue: ValueValue,
      colorName: NameValue,
      colorStatus: statusValue,
    });
  };
  return (
    <div className="edit-product-container">
      <h5>
        {props.title}
        {item.colorId}
      </h5>
      <Form onSubmit={handleSubmit} id="edit-color-form">
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-color-label-Name">Name</Form.Label>
          <Form.Control
            id="edit-color-label-Name"
            type="text"
            defaultValue={item.colorName}
            placeholder={item.colorName}
            name="edit-color-Name"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-color-label-Value">Value</Form.Label>
          <Form.Control
            id="edit-color-label-Value"
            type="text"
            defaultValue={item.colorValue}
            placeholder={item.colorValue}
            name="edit-color-Value"
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label htmlFor="edit-color-label-status">Status</Form.Label>
          <Form.Select id="edit-color-label-status" name="edit-color-status">
            <option value="Y">Y</option>
            <option value="N">N</option>
          </Form.Select>
        </Form.Group>
      </Form>
      <Button variant="secondary" onClick={props.closeModal}>
        Cancel
      </Button>
      <Button
        form="edit-color-form"
        variant="primary"
        type="submit"
        onClick={handleSubmit}
      >
        Edit
      </Button>
    </div>
  );
};

export default EditColor;
